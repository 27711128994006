import React, { memo, FC } from "react";
import { Link } from "react-router-dom";
import { ChallengeTableRowProp } from "@/features/challenge/types/props/SingleChallengeProps";
import LogoPlaceholder from "../../../../assets/placeholders/placeholder.png";
import BaseLogo from "@/components/BaseLogo/BaseLogo";

const LeaderboardTableRows: FC<ChallengeTableRowProp> = (
  props
): JSX.Element => {
  return (
    <div className="leaderboard-table">
      <div
        className={`${
          props.isPlaceholder && "placeholder placeholder-wave"
        } leaderboard-table__rank`}
      >
        {props.rank}
        {props.isPlaceholder && (
          <span className="placeholder placeholder-wave">placehol</span>
        )}
      </div>
      <div className="leaderboard-table__user">
        <div className="leaderboard-table__image">
          <picture className="adaptive">
            <figure className="adaptive-photo">
              <BaseLogo
                logoSrc={props.logo}
                name={props.name[0] || ""}
                isPlaceHolder={undefined}
              />
            </figure>
          </picture>
        </div>

        <div
          className={
            props.isPlaceholder
              ? "placeholder placeholder-wave cursor-default "
              : "leaderboard-table__name"
          }
        >
          {" "}
          <Link
            to={
              props.isPlaceholder
                ? `/challenges/${props.slug}`
                : `/profile/${props.slug}`
            }
          >
            <p>{props.name[0] || ""}</p>
            <span>{props.name[1] || ""}</span>
          </Link>
        </div>
      </div>
      <div
        className={`leaderboard-table__score ${
          props.isPlaceholder ? "placeholder placeholder-wave" : ""
        }`}
      >
        {" "}
        {props.points}
      </div>
    </div>
  );
};
export default memo(LeaderboardTableRows);
