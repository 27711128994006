import { useNavigate, useParams } from "react-router-dom";
import React, { FC, useEffect } from "react";
import { createToast } from "@/utils/toaster/createToast";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SinglePostProps } from "../../types/singlePostsProps";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { SinglePostComments } from "../SinglePost/SinglePostComments.fetcher";
import { SinglePostContentType } from "../SinglePost/SinglePostContentType";
import { Button, ErrorFallback } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const SinglePostPage: FC<{}> = ({}): JSX.Element => {
  const { slug } = useParams();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();

  const [post, loading, data_error, fetch_data] =
    useCloudFunctionCall<SinglePostProps>("getPostBasedOnSlug", slug);

  useEffect(() => {
    const getPostData = async () => {
      await fetch_data({
        postId: slug,
        currUserId: currentUser.uid,
      }).catch(() => {
        createToast({
          type: "error",
          message: "Something went wrong trying to load post",
        });
        navigate(siteRoutes["dashboard"]);
      });
    };
    if (slug) {
      getPostData();
    }
  }, []);

  return (
    <>
      {data_error && (
        <ErrorFallback>
          <h2>Failed to load post</h2>
          <Button
            variant="primary"
            onClickAction={() => {
              navigate(siteRoutes["dashboard"]);
            }}
          >
            Back to feed
          </Button>
        </ErrorFallback>
      )}
      <div className="single-post">
        {post && !loading && (
          <>
            <SinglePostContentType
              type={post.type || "userPost"}
              postPhoto={post.postPhoto}
              postVideo={post.postVideo}
              data={post}
            />
          </>
        )}
        <div className="single-post__comments">
          {post && !loading && (
            <SinglePostComments
              showComments={true}
              postId={slug as string}
              postAuthorUid={""}
            />
          )}
        </div>
      </div>
    </>
  );
};
