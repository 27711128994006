import React, { memo, useState } from "react";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ShareIcon } from "../../assets/share-challenge.svg";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "../../../../api/imgix/index";
import { SinglePostProps } from "../../types/singlePostsProps";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

const SharedChallenge: React.FunctionComponent<{
  isFromFeed?: boolean;
  data: SinglePostProps;
}> = ({ data, isFromFeed }) => {
  const navigate = useNavigate();
  const [logoError, setLogoError] = useState<boolean>(false);
  if (data && data.additionalData) {
    try {
      return (
        <div className="shared-challenge">
          <div
            className={`shared-challenge__card ${isFromFeed ? "feed" : ""}`}
            aria-hidden
            onClick={() =>
              navigate(
                `${siteRoutes["challengesAndSlug"]}${data.additionalData!.slug}`
              )
            }
          >
            <div className="shared-challenge__image">
              <Image
                onError={() => setLogoError(true)}
                alt={`User-uploaded image for ${name} challenge`}
                src={Query_shared.imageErrorHandler(
                  data.additionalData.logoUrl.includes("wog.imgix")
                    ? `${
                        data.additionalData.logoUrl
                      }?${Query_Imgix.getImgixUrlProps({
                        w: "248",
                        h: "165",
                      })}`
                    : data.additionalData.logoUrl,
                  logoError,
                  undefined
                )}
              />
            </div>

            <div className="shared-challenge__card--content">
              <h3>{data.additionalData.name}</h3>
              <small>{data.additionalData.game}</small>
              <p>{data.additionalData.goal}</p>
            </div>

            <div className="share-challenges-icon ">
              <ShareIcon width={57} height={57} />
            </div>
          </div>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default memo(SharedChallenge);
