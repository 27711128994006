import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { VscCircleFilled as UnreadIcon } from "@react-icons/all-files/vsc/VscCircleFilled";
import { Dropdown } from "react-bootstrap";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { archiveOrReadNotification } from "./../helperFunctions/readNotification";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "./../../../utils/toaster/createToast";
import SingleNotificationActions from "./SingleNotificationActions";
import {
  notificationRoutes,
  showActionTypes,
} from "@/utils/constants/notificationVariables";
import { Button, ErrorFallback } from "@/components";

const SingleNotificationItem: React.FunctionComponent<{
  data: any;
  docId: string;
  whenWasSent: any;
}> = ({ data, docId, whenWasSent }) => {
  const [read, setRead] = useState<boolean>(data.read);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [toasted, hasToasted] = useState<boolean>(false);

  // Get the data of the current related type, challenge, teams etc, needed for the name
  const dataObj = QueryDB.getSingleDoc(
    showActionTypes[data.type]?.collection,
    data.contentId
  );

  useEffect(() => {
    setRead(data.read);
  }, [data.read]);

  const readNotification = useCallback(async () => {
    if (!read) {
      if (
        data.type === "friends-requests" ||
        data.type === "teams-requests" ||
        data.type === "challenges-requests" ||
        data.type === "gather-requests"
      ) {
        return;
      }
      await archiveOrReadNotification(docId, true, false);
      setRead(true);
    }
  }, [read, data.type, data.notificationId]);

  if (
    !data.archived &&
    !data.read &&
    data.type === "message-recieved" &&
    window.location.pathname === "/messages"
  ) {
    readNotification();
  }

  if (
    !data.read &&
    !read &&
    !data.archived &&
    !toasted &&
    data.type === "message-recieved" &&
    window.location.pathname !== "/messages"
  ) {
    readNotification();
    hasToasted(true);
    createToast({
      type: "success",
      message: (
        <div
          onClick={() => {
            navigate(data.link);
          }}
        >
          {name} {data.content}
        </div>
      ),
    });
  }
  try {
    return (
      <>
        <Dropdown.Item
          className="notification"
          onClick={() => {
            if (!read) {
              readNotification();
            }
          }}
        >
          {!read ? (
            <div className="notification__status">
              <UnreadIcon color="#9b00ff" />
            </div>
          ) : (
            ""
          )}
          <div className="notification__wrapper">
            <Button
              onClickAction={() => {
                navigate(
                  notificationRoutes[data.type]
                    ? `/${notificationRoutes[data.type]}/${data.contentId}`
                    : ""
                );
              }}
              variant=""
            >
              {data.type.includes("challenge") ||
              data.type.includes("linked") ? (
                <div className="notification__image">
                  <figure className="adaptive">
                    <picture className="adaptive-photo">
                      {/* <Image
                      src={defaultLogo}
                      width={20}
                      alt={`${brand.name} logo`}
                    /> */}
                    </picture>
                  </figure>
                </div>
              ) : (
                <div className="notification__image">
                  <figure className="adaptive">
                    <picture className="adaptive-photo">
                      <BaseLogo
                        logoSrc={data.senderProfileLogoUrl}
                        name={data.senderUsername}
                        // styleProps="avatar--small"
                        isPlaceHolder={undefined}
                      />
                    </picture>
                  </figure>
                </div>
              )}
            </Button>
            <div>
              <div className="notification__content">
                <Button
                  onClickAction={() => {
                    navigate(
                      notificationRoutes[data.type]
                        ? `/${notificationRoutes[data.type]}/${data.contentId}`
                        : ""
                    );
                  }}
                  variant=""
                >
                  <div className="notification__message">
                    <p>
                      <span>{data.senderUsername} </span>
                      {data.content}
                    </p>
                  </div>
                </Button>
                <div className="notification__meta">
                  <p>{whenWasSent}</p>
                </div>
              </div>
            </div>
          </div>
          {showActionTypes[data.type] && (
            <SingleNotificationActions
              read={read}
              setRead={setRead}
              data={data}
              docId={docId}
              dataObj={dataObj}
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </Dropdown.Item>
      </>
    );
  } catch {
    <ErrorFallback> Error loading notification</ErrorFallback>;
  }
};

export default SingleNotificationItem;
