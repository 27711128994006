import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { useMemo, useEffect, useRef } from "react";
import SinglePost from "./FeedCards/ProfileSinglePost.ui";
import { database_1 } from "@/api/firebase/firebase.config";
import { useAuthPersistState } from "@/store/auth/auth.store";
import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";

export default function FeedFetcher() {
  const currUser = useAuthPersistState((s) => s.user);
  const dbRef = database_1.ref("/cache").child(currUser.uid).child("valid");

  const [
    feed, // this feed will be either posts/challenges/gathers/teams,etc based on feedType
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchFeed,
    ref,
    inView,
    _filter, // ignore, as Filters are unUsed here
  ] = useCloudFunctionCallForListsAndInfiniteScroll<any[] | undefined, any>(
    "getFeedPostsFromCache",
    "general_feed",
    "general_feed_posts" //SHOULD BE SAME AS TYPE RETURNING FROM CLOUD FUNCTION
  );

  const isCacheValid = useRef(true);
  useEffect(() => {
    dbRef.on("value", (snapshot) => {
      const data = snapshot.val();

      if (data === false) {
        isCacheValid.current = false;
        // one of the user's community member posted new post, so data is inValid
        CloudFunctions.callCloudFunction("runCacheFunction"); // re-cache the data
      }
      if (!data) {
        CloudFunctions.callCloudFunction("runCacheFunction"); // re-cache the data
      }
      if (data === true && isCacheValid.current === false) {
        isCacheValid.current = true;

        // re-fetch the cached data in about 15 seconds
        fetchFeed({ lastFeedDateInSeconds: 0 });
      }
    });
    return () => {
      dbRef.off();
    };
  }, []);

  //only run at first load!
  useEffect(() => {
    fetchFeed({ lastFeedDateInSeconds: 0 });
  }, []);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  // call this function when inView is true
  async function fetchMore() {
    await fetchFeed(
      {
        lastFeedDateInSeconds,
      },
      true
    );
  }

  // always set the inview reference to the 7th last element.
  //Why? When user starts to scroll down, we can start fetching the next 10 posts in advanced.
  const inViewRefElementIndex = useMemo(() => {
    return feed ? feed?.length - 7 : -1;
  }, [feed, hasMore]);

  return (
    <>
      {" "}
      {!!feed &&
        feed.map((_feed: any, ind: number) => {
          const key = `${_feed.id}_${ind}`;
          return (
            <div key={key} ref={ind === inViewRefElementIndex ? ref : null}>
              {/* this element will always be based on Feed type */}
              <SinglePost props={{ ..._feed, error }} />;
            </div>
          );
        })}
    </>
  );
}
