import { ChallengeCard } from "@/features/challenge";

export default function ProfileSingleChallenge({ props }: any) {
  const {
    created,
    createdBy,
    creatorType,
    endDate,
    fieldToCompare,
    game,
    goal,
    logoUrl,
    name,
    slug,
    startDate,
  } = props;
  return (
    <div className="grid-container-challenges-overview">
      <ChallengeCard
        created={created}
        createdBy={createdBy}
        creatorType={creatorType}
        endDate={endDate}
        fieldToCompare={fieldToCompare}
        game={game}
        goal={goal}
        logoUrl={logoUrl}
        name={name}
        slug={slug}
        startDate={startDate}
      />
    </div>
  );
}
