export const getChallengeDate = (
  startDateSeconds: any,
  endDateSeconds: any
) => {
  if (!startDateSeconds || !endDateSeconds)
    return ["", "The date was not specified", ""];

  const now = Date.now() / 1000;
  const hasStarted = startDateSeconds < now;
  const hasEnded = now > endDateSeconds;

  const start = new Date(startDateSeconds * 1000);
  const end = new Date(endDateSeconds * 1000);

  let str = "";
  let timeleft = 0;
  const curr = new Date().getTime();

  if (hasStarted) {
    str = "Ends in: ";
    timeleft = endDateSeconds * 1000 - curr;
  } else {
    str = "Starts in: ";
    timeleft = startDateSeconds * 1000 - curr;
  }

  const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));

  if (days >= 1) {
    str += `${days} day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    str += `${hours} hour${hours > 1 ? "s" : ""}`;
  } else if (minutes > 0) {
    str += `${minutes} minute${minutes > 1 ? "s" : ""} `;
  } else {
    str += " soon";
  }

  if (hasEnded) {
    str = `Ended on ${end.getDate()}/${end.getMonth() + 1}`;
  }

  return [
    `${start.getDate()}/${start.getMonth() + 1}/${start.getFullYear()}`,
    str,
    `${end.getDate()}/${end.getMonth() + 1}/${end.getFullYear()}`,
  ];
};
