import MajesticPanda from "../assets/avatars/majestic-panda-256.jpg";
import MajesticMonkey from "../assets/avatars/majestic-monkey-256.jpg";
import MajesticGiraffe from "../assets/avatars/majestic-giraffe-256.jpg";
import MajesticEagle from "../assets/avatars/majestic-eagle-256.jpg";
import MajesticBear from "../assets/avatars/majestic-bear-256.jpg";
import FuturisticV1 from "../assets/avatars/futuristic-v1-256.jpg";
import FuturisticV2 from "../assets/avatars/futuristic-v2-256.jpg";
import FuturisticV3 from "../assets/avatars/futuristic-v3-256.jpg";
import CyberpunkV1 from "../assets/avatars/cyberpunk-v1-256.jpg";
import CyberpunkV2 from "../assets/avatars/cyberpunk-v2-256.jpg";
import CyberpunkV3 from "../assets/avatars/cyberpunk-v3-256.jpg";
import CyberpunkV4 from "../assets/avatars/cyberpunk-v4-256.jpg";
import CyberpunkV5 from "../assets/avatars/cyberpunk-v5-256.jpg";
import FluffballsBlack from "../assets/avatars/fluffballs-black-256.jpg";
import FluffballsBlue from "../assets/avatars/fluffballs-blue-256.jpg";
import FluffballsGreen from "../assets/avatars/fluffballs-green-256.jpg";
import FluffballsPurple from "../assets/avatars/fluffballs-purple-256.jpg";
import FluffballsRed from "../assets/avatars/fluffballs-red-256.jpg";
import FluffballsYellow from "../assets/avatars/fluffballs-yellow-256.jpg";
import FoodsEgg from "../assets/avatars/foods-egg-256.jpg";
import FoodsFries from "../assets/avatars/foods-fries-256.jpg";
import FoodsOrange from "../assets/avatars/foods-orange-256.jpg";
import FoodsPancakes from "../assets/avatars/foods-pancakes-256.jpg";
import FoodsPepper from "../assets/avatars/foods-pepper-256.jpg";

export const Avatars = [
  {
    category: "Cute foods",
    values: [
      { img: FoodsEgg },
      { img: FoodsFries },
      { img: FoodsOrange },
      { img: FoodsPancakes },
      { img: FoodsPepper },
    ],
  },
  {
    category: "Fluffballs",
    values: [
      { img: FluffballsBlack },
      { img: FluffballsRed },
      { img: FluffballsPurple },
      { img: FluffballsBlue },
      { img: FluffballsGreen },
      { img: FluffballsYellow },
    ],
  },
  {
    category: "Majestic animals",
    values: [
      { img: MajesticBear },
      { img: MajesticEagle },
      { img: MajesticGiraffe },
      { img: MajesticMonkey },
      { img: MajesticPanda },
    ],
  },
  {
    category: "Cyberpunk",
    values: [
      { img: CyberpunkV1 },
      { img: CyberpunkV2 },
      { img: CyberpunkV3 },
      { img: CyberpunkV4 },
      { img: CyberpunkV5 },
    ],
  },
  {
    category: "Futuristic soldiers",
    values: [
      { img: FuturisticV1 },
      { img: FuturisticV2 },
      { img: FuturisticV3 },
    ],
  },
];

export const avatarsRaw = [
  FoodsEgg,
  FoodsFries,
  FoodsOrange,
  FoodsPancakes,
  FoodsPepper,
  FluffballsBlack,
  FluffballsRed,
  FluffballsPurple,
  FluffballsBlue,
  FluffballsGreen,
  FluffballsYellow,
  MajesticBear,
  MajesticEagle,
  MajesticGiraffe,
  MajesticMonkey,
  MajesticPanda,
  CyberpunkV1,
  CyberpunkV2,
  CyberpunkV3,
  CyberpunkV4,
  CyberpunkV5,
  FuturisticV1,
  FuturisticV2,
  FuturisticV3,
];
