import ProfileSinglePost from "../components/FeedCards/ProfileSinglePost.ui";
import ProfileSingleChallenge from "../components/FeedCards/ProfileSingleChallenge.ui";

import ProfileSingleGather from "../components/FeedCards/ProfileSingleGather.ui";
import ProfileSingleTeam from "../components/FeedCards/ProfileSingleTeam.ui";
import ProfileSingleAchievements from "../components/FeedCards/ProfileSingleAchievements.ui";

export const FeedTypeAndComponent: Record<
  FeedType,
  React.FC<{ props: any }>
> = {
  posts: ProfileSinglePost,
  achievements: ProfileSingleAchievements,
  challenges: ProfileSingleChallenge,
  gathers: ProfileSingleGather,
  teams: ProfileSingleTeam,
};
