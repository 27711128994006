export const notificationsTextMapping: { [key: string]: string } = {
  "tournament-check-in": "Time to check-in in the tournament",
  "tournament-start": "The tournament started:",
  "challenges-placement-lost-nr-1": "Oh, no... You are no longer #1 on ",
  "challenges-placement-got-nr-1": "Congratulations! You are #1 on ",
  "challenges-requests": "wants you to join the ",
  "challenges-requests-accepted": "accepted your invitation to the ",
  "member-joined-challenge": "has joined challenge",
  "request-to-join-challenge": "requested to join the challenge",
  "teams-requests": "wants you to join the team",
  "teams-following": "Started following",
  "teams-remove": "You are no longer a member of",
  "teams-promote": "You are now the owner of",
  "member-joined-team": "has joined team",
  "request-to-join-team": "requested to join the team",
  "gather-requests": "wants you to join the gather",
  "gather-requests-accepted": "has joined gather",
  "request-to-join-gather": "requested to join the gather",
  "member-joined-gather": "has joined gather",
  "friends-following": "started following you.",
  "friends-requests": "sent you a friend request.",
  "friends-requests-accepted": "accepted your friend's request.",
  "teams-requests-accepted": "has joined team",
  "linked-account-lol-success":
    "You have successfully linked your League of Legends account",
  "linked-account-pubg-success":
    "You have successfully linked your PUBG account",
  "linked-account-battlenet-fail":
    "An error occurred when linking your Battlenet account",
  "linked-account-battlenet-success":
    "You have successfully linked your Battlenet account",
  "linked-account-warzone-fail":
    "An error occurred when linking your Warzone account",
  "linked-account-warzone-success":
    "You have successfully linked your Warzone account",
  "post-commented": "commented your post: ",
  "post-mention": "mentioned you",
  "comment-mention": "mentioned you in a comment",
  "message-recieved": "", // "message" || "photo" || "file" || "gif" added in code
};

export const notificationsTextEndMapping = {
  "challenges-placement-lost-nr-1": " leaderboard. Wanna get back in the game?",
  "challenges-placement-got-nr-1": " leaderboard!",
};
