import { FC, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { Query_shared } from "@/api/shared";

const BaseLogo: FC<{
  logoSrc?: string;
  name: string;
  isPlaceHolder?: boolean;
  customStyles?: string;
}> = ({ logoSrc, name, isPlaceHolder = false, customStyles }): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);

  useEffect(() => {
    setLogoError(false);
  }, [logoSrc]);

  return (
    <>
      {logoSrc && logoSrc !== undefined && (
        <Image
          src={Query_shared.imageErrorHandler(
            logoSrc,
            logoError,
            isPlaceHolder
          )}
          alt=""
          onError={(err) => {
            setLogoError(true);
          }}
          className={customStyles}
        />
      )}
      {(logoSrc === null || logoSrc === undefined) && name !== null ? (
        <div className="avatar__letter">
          <span>{name && name.slice(0, 1)}</span>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default BaseLogo;
