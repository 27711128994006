import { firestore } from "@/api/firebase/firebase.config";

export async function _updateFirebaseDoc(
  collectionName: string,
  docId: string,
  data: Record<string, any>
) {
  await firestore
    .collection(collectionName)
    .doc(docId)
    .update({
      ...data,
    });
}
