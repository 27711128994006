import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { ReactComponent as IconTeam } from "../../assets/teams-shared.svg";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";
import { SinglePostProps } from "../../types/singlePostsProps";

const SharedTeam: React.FunctionComponent<{
  data: SinglePostProps;
  isFromFeed: boolean;
}> = ({ data, isFromFeed }) => {
  const [logoError, setLogoError] = useState<boolean>(false);

  if (data && data.additionalData) {
    try {
      return (
        <div className="shared-team">
          {!isFromFeed && <div className="vertical-line" />}

          <div
            className={`shared-team__container share-post--content ${
              isFromFeed ? "feed" : ""
            }`}
          >
            <Link to={`/teams/${data.additionalData.slug}`}>
              <div className="shared-team__image">
                <Image
                  onError={() => setLogoError(true)}
                  alt={`User-uploaded image for ${name} team`}
                  src={Query_shared.imageErrorHandler(
                    data.additionalData.teamLogo.includes("wog.imgix")
                      ? `${
                          data.additionalData.teamLogo
                        }?${Query_Imgix.getImgixUrlProps({
                          w: "248",
                          h: "165",
                        })}`
                      : data.additionalData.teamLogo,
                    logoError,
                    undefined
                  )}
                />
              </div>
              <div className="shared-team__container--content">
                <h3>{data.additionalData.name}</h3>
                <p>{data.additionalData.description}</p>
              </div>
              <div className="shared-team__icon">
                <IconTeam width={62} height={45} />
              </div>
            </Link>
          </div>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default SharedTeam;
