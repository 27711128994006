import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { FC } from "react";
import { Link } from "react-router-dom";
import { SinglePostDropDownActions } from "./SinglePostDropdownAction";

export const SinglePostInfo: FC<{
  authorUsername: string;
  authorLogo: string;
  authorSlug: string;
  type: string;
  whenWasPosted: any;
  wasEdited: boolean;
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthorized: boolean;
  postId: any;
  currUserSlug: string;
}> = ({
  authorUsername,
  authorLogo,
  authorSlug,
  type,
  whenWasPosted,
  wasEdited,
  setIsDeleted,
  setIsEditPost,
  isAuthorized,
  postId,
  currUserSlug,
}) => {
  const textMapping: {
    [key: string]: string;
  } = {
    userPost: "",
    sharedPost: "shared a post",
    sharedChallenge: "shared a challenge",
    sharedGather: "shared a gather",
    sharedTeam: "shared a team",
    sharedNews: "shared a news",
  };

  return (
    <div className="post__info">
      <div className="post__user">
        <div className="post__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo
                name={authorUsername}
                logoSrc={authorLogo}
                isPlaceHolder={undefined}
              />
            </div>
          </div>
        </div>
        <div className="post__author">
          <Link className="author__username" to={`/profile/${authorSlug}`}>
            <strong>{authorUsername}</strong> {textMapping[type]}
          </Link>
          <span className="author__timestamp">
            <time dateTime={whenWasPosted?.stringDate}>
              {whenWasPosted?.howLongAgo}
            </time>{" "}
            {wasEdited ? <span>&#9679; Edited</span> : ""}
          </span>
        </div>
      </div>

      <SinglePostDropDownActions
        setIsEditPost={setIsEditPost}
        postId={postId}
        belongsToCurrentUser={authorSlug === currUserSlug || isAuthorized}
        currUserSlug={currUserSlug}
        setIsDeleted={setIsDeleted}
      />
    </div>
  );
};
