export default function EmojiWrapper({
  children,
  show,
  setShow,
}: {
  children: React.ReactNode;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      {show && (
        // Just a pseudo element which will appear in the background of emoji table, clicking on it will hide the emoji table again
        <div
          role="none"
          onClick={() => setShow((prevState) => !prevState)}
          style={{
            position: "fixed",
            left: "0px",
            top: "0px",
            width: "100vw",
            height: "100vh",
            zIndex: "99",
          }}
        />
      )}
      {show && children}
    </>
  );
}
