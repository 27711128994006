import React, { useEffect } from "react";
// import { searchClient } from "@/utils/algolia/algoliaUtils";
// import { InstantSearch } from "react-instantsearch-dom";
import NavigationBar from "./topNavigation/components/NavigationBar.fetcher";
import { ToastContainer } from "react-toastify";
import { SideMenu } from "./sideNavigation/components/SideMenu.fetcher.ui";
import { useLocation } from "react-router-dom";
import UnAuthorizedLayout from "./UnAuthorizedLayout";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import brand from "./../utils/brandVariables/brandVariables";
import { LayoutFooter } from "./layoutFooter";

export default function Layout({ children }: { children: React.ReactNode }) {
  const noSideMenu = false;
  const location = useLocation();
  const currentUser = useCurrentUserProfile((s) => s.user);
  // IN CASE OF SIGN UP 3 OR OTHER SIGN UP PAGES, ADD HERE | WILL REMOVE SIDEBAR AND FIX SCSS
  if (location.pathname === "/signup-success") {
    return <UnAuthorizedLayout>{children}</UnAuthorizedLayout>;
  }

  // Set the theme of the current user on page load/refresh
  useEffect(() => {
    if (brand.hasFeature.themes) {
      document.documentElement.className = `theme-${currentUser.theme}`;
    } else {
      document.documentElement.className = `theme-${brand.brandTheme}`;
    }

    if (!document.body.classList.contains("landing-bg-legion")) {
      document.body.classList.add("landing-bg-legion");
    }

    if (document.querySelector(".settings__group--language")) {
      document.querySelector(".settings__group--language")?.remove();
    }
  }, [currentUser]);

  return (
    <>
      {!noSideMenu && (
        <>
          <SideMenu currentViewName="home" />
          {/* <InstantSearch searchClient={searchClient} indexName="profiles"> */}
          <NavigationBar />
          <main>
            <div className="container">
              {children}
              <LayoutFooter />
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
              theme="dark"
              limit={3}
            />
          </main>
          {/* </InstantSearch> */}
        </>
      )}
    </>
  );
}
