import { Link } from "react-router-dom";
import React from "react";
export const highlightAtMentions = (
  text: string | undefined | null,
  existingUsers: Array<{ userName: string; slug: string; uid: string }>
): React.ReactNode[] | null => {
  if (!text) return null;

  const words = text.split(" ");
  return words.map((word: string) => {
    if (word.startsWith("@")) {
      const existingUser = existingUsers.find((user) => user.userName === word);
      if (existingUser) {
        return (
          <Link to={`/profile/${existingUser.slug}`} key={`${word}`}>
            <span className="post-highlight__tag">{word}</span>
          </Link>
        );
      }
      return <React.Fragment key={`${word}`}>{word} </React.Fragment>;
    }
    return <React.Fragment key={`${word}`}>{word} </React.Fragment>;
  });
};
