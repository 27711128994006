import React, { useState } from "react";
import { v4 } from "uuid";
import LeaderboardTableRows from "./LeaderboardTableRows";
import { Participants } from "../../types/cloudFunctions/LeaderBoard";
import LeaderboardTableLoader from "@/components/skeletonLoaders/singleChallengeLoader/LeaderboardTableLoader";

const LeaderboardParticipantsTable: React.FunctionComponent<{
  items: Participants[] | undefined;
  color: string;
  observerRef: (node?: Element | null | undefined) => void;
  children: React.ReactNode;
  inViewIndex: number;
  loading: boolean;
}> = ({ items, children, observerRef, inViewIndex, loading }) => {
  const findQueryMatch = window.matchMedia("(max-width:1550px)");
  const [isMatch, setIsMatch] = useState(findQueryMatch.matches);

  findQueryMatch.addEventListener("change", (event) => {
    if (event.matches) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  });

  return (
    <>
      <div className="leaderboard__main">
        {(
          items.filter((item) => item.userChallengeData.points !== 0) || []
        ).map((item, index) => (
          <div
            ref={index === inViewIndex ? observerRef : null}
            className="leaderboard__main-item"
            key={item?.profile.uid}
          >
            <LeaderboardTableRows
              bgStyle=""
              rank={item?.userChallengeData.rank}
              logo={item?.profile.profileLogoUrl}
              name={[item?.profile.userName, item?.profile.fullName]}
              points={item?.userChallengeData.points}
              slug={item?.profile.slug}
            />
          </div>
        ))}
        {loading && <LeaderboardTableLoader />}
        {!items?.length && (
          <>
            {!loading && (
              <div className="leaderboard__main-empty--text">
                <div>{children}</div>
              </div>
            )}

            {Array.from(Array(isMatch ? 2 : 4), () => {
              return (
                <div className="leaderboard__main-row row--less" key={v4()}>
                  _
                </div>
              );
            })}
          </>
        )}

        {items && items.length < (isMatch ? 6 : 8) && items.length > 0 && (
          <>
            {Array.from(Array((isMatch ? 6 : 8) - items.length), () => {
              return (
                <div className=" leaderboard__main-row row--more" key={v4()}>
                  _
                </div>
              );
            })}
          </>
        )}

        {/* <div className="leaderboard__table-observer" ref={observerRef} /> */}
      </div>
    </>
  );
};

export default LeaderboardParticipantsTable;
