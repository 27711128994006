import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import firebase from "firebase/app";
import SideMenuCreateButton from "./SideMenuCreateButton";
import { SideMenuLinks } from "./SideMenuLinks";
import SideMenuAvatar from "./SideMenuAvatar.mobile.tsx";
import { Button } from "@/components/index.ts";
import { siteRoutes } from "../../../routes/siteRoutesList.ts";
import brand from "../../../utils/brandVariables/brandVariables.tsx";
import { Challenge } from "@/models/database/challenges/challenge.model.ts";
import { firestore } from "@/api/firebase/firebase.config.ts";

export const SideMenuMobileUi: React.FunctionComponent<
  any & { isAuth: boolean }
> = ({ show, closeWindow, isAuth, profile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Offcanvas className="offcanvas" show={show}>
      <Offcanvas.Header className="offcanvas-header">
        {isAuth ? (
          <SideMenuAvatar
            profile={profile}
            closeWindow={closeWindow}
            styleProps="offcanvas__avatar"
          />
        ) : (
          ""
        )}
        <Button variant="link" onClickAction={closeWindow}>
          <CloseIcon width={30} height={30} />
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <SideMenuLinks
          currentViewName={location.pathname.slice(1)}
          closeWindow={closeWindow}
        />
      </Offcanvas.Body>
      <div className="offcanvas__footer">
        <div className="footer__actions">
          <Button
            variant="link"
            onClickAction={async () => {
              await firebase.auth().signOut();
              localStorage.removeItem("userProfile");
              navigate(siteRoutes["landing"]);
            }}
          >
            <span>
              <LogoutIcon className="me-2" width={45} height={30} />
            </span>
          </Button>
          {!brand.isEvent && (
            <SideMenuCreateButton isSideMenuSm closeWindow={closeWindow} />
          )}
        </div>
      </div>
    </Offcanvas>
  );
};
