import firebase from "firebase/app";
import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import { ReactComponent as PostIconActive } from "../../../../assets/posts/post-active.svg";
import { ReactComponent as PostIconInActive } from "../../../../assets/posts/post-inactive.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import EmojiWrapper from "@/components/EmojiWrapper/EmojiWrapper";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";

type CommentProps = {
  author?: string;
  commentText: string;
};
const CreateCommentForm: React.FunctionComponent<{
  postId?: string;
  postAuthorUid: string;
  fetchComments: () => Promise<void>;
}> = ({ postId, postAuthorUid, fetchComments }) => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  const initialValue: CommentProps = {
    commentText: "",
    author: currentUser.uid,
  };

  const [show, setShow] = useState(false);

  const [commentValues, setCommentValues] =
    useState<CommentProps>(initialValue);
  const setFieldValue = (targetName: string, value: any) => {
    return setCommentValues((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const submit = async () => {
    if (!postId || !commentValues.author) return;
    if (commentValues.commentText.trim().length <= 0) {
      createToast({
        type: "error",
        message: "Comment cannot be empty",
      });
      return;
    }
    await QueryDB.createNewDoc(`posts/${postId}/comments`, {
      created: firebase.firestore.FieldValue.serverTimestamp(),
      commentText: commentValues.commentText,
      authorUid: commentValues.author,
      isVisible: true,
      parentId: postId,
    })
      .then(() => {
        if (postAuthorUid !== currentUser.uid) {
          createToast({
            type: "success",
            message: "Comment posted",
          });
        }
        setCommentValues(initialValue);
        fetchComments();
      })
      .catch(() => {
        createToast({
          type: "error",
          message:
            "Something went wrong while posting comment, please try again later.",
        });
      });

    // TODO: ALGOLIA
    // .then(async () => {
    //   const existingUsers = Array.isArray(friendsList)
    //     ? friendsList.map((friend: any) => ({
    //         username: `@${friend.userName}`,
    //         slug: friend.slug,
    //         uid: friend.uid,
    //       }))
    //     : [];

    //   const mentionedUsers = values.commentText
    //     .split(" ")
    //     .filter((word: string) => word.startsWith("@"));

    //   const uniqueMentionedUsers = new Set(mentionedUsers);

    //   uniqueMentionedUsers.forEach((mentionedUsername) => {
    //     const mentionedUser = existingUsers.find(
    //       (user) => user.username === mentionedUsername
    //     );
    //     if (mentionedUser) {
    //       addNewNotification(
    //         currentUserId as string,
    //         mentionedUser.uid,
    //         currentUserId as string,
    //         "comment-mention",
    //         `${notificationsTextMapping["comment-mention"]}`
    //       );
    //     }
    //   });
    // });

    // TODO: FETCH COMMENTS after success
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
      autoComplete="off"
      className="comment-form"
    >
      <div className="comment-form__wrapper">
        <div className="comment-form__logo">
          <BaseLogo
            name={`${currentUser.userName}`}
            logoSrc={currentUser.profileLogoUrl}
            isPlaceHolder={undefined}
          />
        </div>
        <div className="comment-form__textarea">
          <BaseTextInput
            label=""
            textType="text"
            value={commentValues.commentText}
            placeholder="Comment..."
            setObjectState={setFieldValue}
            setObjectStateTargetName="commentText"
            error={undefined}
          />
          <EmojiWrapper setShow={setShow} show={show}>
            <div className="post-form__emoji-picker">
              <EmojiPicker
                emojiVersion="0.6"
                onEmojiClick={(emojiObject) => {
                  setFieldValue(
                    "commentText",
                    `${commentValues.commentText}${emojiObject.emoji}`
                  );
                  // onChange(emojiObject.emoji);
                }}
              />
            </div>
          </EmojiWrapper>
          {/* <Button
                  variant="link"
                  onClick={() => setShow((pre) => !pre)}
                  className="position-absolute emojy--icon-position-comment d-none d-md-block"
                >
                  <img src={icon_emoji} alt="" />
                </Button> */}
        </div>

        <Button
          variant="link"
          className="comment-form__post-btn"
          type="submit"
          disabled={commentValues.commentText.trim().length <= 0}
        >
          {commentValues.commentText.trim().length <= 0 ? (
            <PostIconInActive />
          ) : (
            <PostIconActive />
          )}
        </Button>
      </div>
    </form>
  );
};

export default CreateCommentForm;
