import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../assets/Menu.svg";
import { Image } from "react-bootstrap";
import { Button, ErrorFallback } from "@/components";
import { ReactComponent as IconArrowLeft } from "../assets/chevron-left.svg";
import { ReactComponent as IconArrowRight } from "../assets/chevron-right.svg";
//import NavbarSearchInputWrapper from "@/components/SearchInput/NavbarSearchInputWrapper";
import NavigationBarAvatar from "./NavigationBarAvatar";
import NotificationsDropdown from "@/features/notifications/components/bell/Notifications.fetcher";
// import { SideMenuMobile } from "@/layout/sideNavigation/components/SideMenu.mobile.fetcher";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
// import SideMenuSm from "./SideMenuSm";
// import NavigationBarAvatar from "./NavigationBarAvatar";
import { useMemo } from "react";
import { SideMenuMobile } from "../../sideNavigation/components/SideMenu.mobile.fetcher";
import brand from "../../../utils/brandVariables/brandVariables";
import { siteRoutes } from "../../../routes/siteRoutesList";

export default function NavigationBarUi(props: any) {
  const [fullSearch, setFullSearch] = useState(false);
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const NotificationRender = () =>
    useMemo(() => {
      try {
        return (
          <>
            {props.profile ? (
              <NotificationsDropdown currentUser={props.currentUser} />
            ) : (
              ""
            )}
          </>
        );
      } catch {
        return <ErrorFallback></ErrorFallback>;
      }
    }, [props.currentUser]);

  return (
    <>
      <SideMenuMobile
        show={openOffcanvas}
        closeWindow={() => setOpenOffcanvas(false)}
        isAuth={!!currentUser}
      />

      <header className="header">
        <div className="header__container container">
          <div className={`header__main ${fullSearch ? "search-open" : ""}`}>
            <div className="header__input">
              <Button
                variant="link"
                customStyles="btn-arrow btn-arrow--prev"
                onClickAction={() => navigate(-1)}
              >
                <IconArrowLeft />
              </Button>

              {/* {props.currentUser ? (
                <NavbarSearchInputWrapper setFullParentSearch={setFullSearch} />
              ) : (
                ""
              )} */}
            </div>

            {!fullSearch && (
              <div className="header__logo">
                <Link to="/">
                  <Image
                    className="header__logo--legion"
                    src={brand.logos.large}
                    width={140}
                    height={17}
                    alt={brand.logos.alt_text}
                  />
                </Link>
              </div>
            )}
            <div className="header__actions">
              {props.profile && (
                <NavigationBarAvatar profile={props.profile} styleProps="" />
              )}
              {brand.hasFeature.notifications && <NotificationRender />}

              <Button
                variant="link"
                onClickAction={() => navigate(siteRoutes["profile"])}
                customStyles="btn-menu header__menu"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </Button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
