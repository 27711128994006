import { FC } from "react";
import { ReactComponent as Facebook } from "../assets/Facebook.svg";
import { ReactComponent as YouTube } from "../assets/YouTube.svg";
import { ReactComponent as Instagram } from "../assets/Instagram.svg";
import { useNavigate } from "react-router-dom";

export const LayoutFooter: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const footerLinks = {
    "Terms of Use": "/terms",
    "Privacy Policy": "/privacy-policy",
  };

  const footerSocial = [
    {
      icon: <Facebook />,
      link: "https://www.facebook.com/JBL/",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/jblaudio/",
    },
    {
      icon: <YouTube />,
      link: "https://www.youtube.com/channel/UCced_wdSslkOf7xc_0qyT8w",
    },
  ];

  return (
    <>
      <footer className="footer">
        <ul className="footer__social">
          {footerSocial.map((social, index) => {
            return (
              <>
                <li key={index}>
                  <a href={social.link} target="_blank">
                    {social.icon}
                  </a>
                </li>
              </>
            );
          })}
        </ul>

        <ul className="footer__links">
          {Object.entries(footerLinks).map(([key, value]) => {
            return (
              <>
                <li key={key}>
                  <p
                    onClick={() => {
                      navigate(value);
                    }}
                  >
                    {key}
                  </p>
                </li>
              </>
            );
          })}
        </ul>

        <p>2023 iPlay ApS</p>
      </footer>
    </>
  );
};
