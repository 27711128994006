import React from "react";
import signupbg from "../assets/background/signup-bg.jpg";
import brand from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

const AuthProcessBackground = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="auth">
      <div className="auth__background">
        <img src={signupbg} />
      </div>
      <div className="auth__content">
        {/* Should be removed with custom image component */}
        {/* <Image
          src={brandConstant.logos.large}
          data-image={`${brandConstant.name}-logo`}
          className="sign-up__background-logo"
        /> */}
        <div className="auth__logo">
          <img src={brand.logos.large} data-image={`${brand.name}-logo`} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default AuthProcessBackground;
