import { v4 } from "uuid";

export const _convertImageToImgIX = async (image: File, root?: string) => {
  const response = await fetch(
    `https://api.imgix.com/api/v1/sources/655139b48ba51f0feefbcaf5/upload/${
      root ? `${root}/` : ""
    }${v4()}`,
    {
      method: "POST",
      body: image,
      headers: {
        Authorization:
          "Bearer ak_d8c4fded0545dcf3f285b0dd20152c48b4e7f6cce3aeb0250394b19a4a824f66",
      },
    }
  );

  return `https://iplay.imgix.net/${root ? `${root}/` : ""}/${
    response.url.split(root ? `/upload/${root}/` : "/upload/")[1]
  }`;
};
