import React, { memo, FC, useState } from "react";
import { Image } from "react-bootstrap";
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import { Query_Imgix } from "./../../../../api/imgix/index";
import { Query_shared } from "./../../../../api/shared/index";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { getChallengeDate } from "../../helperFunctions/getChallengeDate";
import { getProgressBarValue } from "../../helperFunctions/getProgressBarValue";
import { ParticipateButton } from "../challengeCard/SingleChallengeCardButtons";

const SingleChallengeGeneralInfo: FC<{
  data: singleChallengeProps;
  isEdit: boolean;
  isPreview: boolean;
  isPrivateForUser: boolean;
}> = ({ data, isEdit, isPreview, isPrivateForUser }): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);

  return (
    <div className="info">
      <div
        className={`info__meta ${
          data.challengeData.status !== "ongoing" ? "ongoing" : ""
        }`}
      >
        <div>
          <div className="adaptive">
            <div className="adaptive-photo">
              <Image
                alt={`User-uploaded image for ${data.challengeData.name} challenge`}
                src={Query_shared.imageErrorHandler(
                  data.challengeData.logoUrl?.includes("wog.imgix")
                    ? `${
                        data.challengeData.logoUrl
                      }?${Query_Imgix.getImgixUrlProps({
                        qty: "35",
                        w: "310",
                        h: "235",
                        fit: "crop",
                      })}`
                    : data.challengeData.logoUrl,
                  logoError,
                  false
                )}
                className="info__image"
                onError={() => setLogoError(true)}
              />
            </div>
            {!isPrivateForUser && data.challengeData.status === "ongoing" && (
              <div className="challenge__actions">
                <div className="challenge__participate">
                  <ParticipateButton
                    data={data}
                    isPreview={
                      isPreview || data.challengeData.status !== "ongoing"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <h1 className="info__name">{data.challengeData.name}</h1>
          <div className="info__game">
            <span>{data.challengeData.type}</span>
            <p>{data.challengeData.game}</p>
          </div>

          <p className="info__goal">{data.challengeData.goal}</p>
        </div>
      </div>
      {!isPrivateForUser && (
        <div>
          <ProgressBar
            id="challenge-time"
            label={getChallengeDate(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            value={getProgressBarValue(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            color=""
            onOverview={false}
            isPlaceholder={false}
          />
        </div>
      )}
    </div>
  );
};

export default memo(SingleChallengeGeneralInfo);
