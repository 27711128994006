import { Query_Imgix } from "@/api/imgix";
import { Query_shared } from "@/api/shared";
import { FC, useState } from "react";

export const Image: FC<{
  imageSrc: string;
  alt: string;
  customStyles?: any;
  imgIxProps?: [
    {
      [key: string]: any;
    },
  ];
}> = ({ imageSrc, alt, imgIxProps, customStyles }) => {
  const [logoError, setLogoError] = useState<boolean>(false);
  return (
    // <figure className="adaptive">
    //   <picture className="adaptive-photo">
    <img
      src={Query_shared.imageErrorHandler(
        imageSrc?.includes("wog.imgix")
          ? `${imageSrc}?${Query_Imgix.getImgixUrlProps({ ...imgIxProps })}`
          : imageSrc,
        logoError,
        false
      )}
      alt={alt}
      className={customStyles ? customStyles : ""}
      onError={() => {
        setLogoError(true);
      }}
    />
    //   </picture>
    // </figure>
  );
};
