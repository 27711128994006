import { useParams } from "react-router-dom";
import { SingleChallenge } from "../components/single/SingleChallenge.fetcher";
import React, { useEffect, useState } from "react";
import { Fn } from "../../../../src/features/challenge/utils/constants";
import firebase from "firebase/app";
import { createToast } from "@/utils/toaster/createToast";

export default function SingleChallengePage() {
  const { slug } = useParams();
  const [slugg, setSlug] = useState<string>("");

  const promiseArray: Fn[] = [];

  const getCurrChallenge = async () => {
    await firebase
      .firestore()
      .collection("challenges")
      .where("client", "==", "JBL")
      .where("status", "==", "ongoing")
      .where("gameType", "==", "fm8")
      .where("eventCode", "==", "jbl_dreamhack_winter_2023")
      .get()
      .then((res) => {
        setSlug(res.docs[0].data().slug);
      })
      .catch((err) => {
        createToast({
          type: "error",
          message: `Failed to load challenge: ${err}`,
        });
      });
  };

  useEffect(() => {
    if (!slug && !slugg) {
      getCurrChallenge();
    }
  }, [slug]);

  if (!slug && !slugg) {
    getCurrChallenge();
  }

  if (!slug && !slugg) {
    return <></>;
  }
  if (slug || slugg) {
    return <SingleChallenge slug={slug || slugg} />;
  }
}
