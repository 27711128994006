import React from "react";
import Form from "react-bootstrap/Form";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";

export const BaseTextInput: React.FunctionComponent<{
  label?: string;
  isDisabled?: boolean;
  textType: "text" | "textarea" | "file" | "password" | "tel";
  value?: string;
  setSingleState?: any;
  setObjectState?: any;
  setObjectStateTargetName?: string;
  placeholder?: string;
  error?: string | undefined;
}> = (props) => {
  return (
    <>
      {props.label ? (
        <label className="settings__label">{props.label}</label>
      ) : (
        ""
      )}
      {props.textType === "file" && (
        <input
          className={`settings__file base-input ${
            props.textType === "file" && "d-none"
          }`}
          placeholder={props.placeholder}
          value={props.value}
          disabled={props.isDisabled}
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return false;
          }}
          type={props.textType}
        />
      )}
      {props.textType === "text" ||
      props.textType === "password" ||
      props.textType === "tel" ? (
        <input
          className="base-input settings__input"
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          value={props.value}
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return false;
          }}
          type={props.textType}
        />
      ) : (
        <textarea
          placeholder={props.placeholder}
          disabled={props.isDisabled}
          className="base-input settings__textarea"
          onChange={(e) => {
            if (props.setSingleState) {
              return props.setSingleState(e.target.value);
            }
            if (props.setObjectState) {
              props.setObjectState(
                props.setObjectStateTargetName,
                e.target.value
              );
            }
            return null;
          }}
          name=""
          id=""
          value={props.value}
        >
          {props.value}
        </textarea>
      )}
      {props.error && <FormErrorMessage text={props.error} isVisible={true} />}
    </>
  );
};
