import React, { memo, FC } from "react";

const SingleChallengeFormula: FC<{
  coefficients: { [key: string]: number } | undefined;
}> = (props): JSX.Element => {
  const hasMultipleCriteria =
    props.coefficients && Object.keys(props.coefficients).length > 1;

  return (
    <div className="single-challenge-JBL__formula">
      <div className="formula">
        <h2 className="formula__heading" id="faq-1">
          What is my score based on?
        </h2>
        <div className="formula__criteria">
          <ul className="formula__list">
            {props.coefficients ? (
              Object.entries(props.coefficients).map(
                ([key, value], index, array) => (
                  <React.Fragment key={key}>
                    <li className="formula__item">
                      <span className="formula__key">{key}</span>
                      {hasMultipleCriteria && (
                        <span className="formula__value">
                          {Math.abs(value)} pts
                        </span>
                      )}
                      {hasMultipleCriteria && index < array.length - 1}
                    </li>
                  </React.Fragment>
                )
              )
            ) : (
              <li>The coefficients were not specified.</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(SingleChallengeFormula);
