import React from "react";
import { ReactComponent as CloseIcon } from "./assets/close.svg";
import { Button } from "@/components";
import Modal from "react-modal";

const BaseModalWindow: React.FunctionComponent<{
  show: boolean;
  closeModal: () => void;
  size?: string | undefined;
  title?: string;
  styles?: string;
  children: React.ReactNode;
}> = (props) => {
  if (!props.show) return null;

  return (
    <div>
      <Modal
        isOpen={props.show}
        onRequestClose={props.closeModal}
        className="modal"
        overlayClassName="Overlay"
        contentLabel="Example Modal"
      >
        <div className={`modal ${props.styles ? props.styles : ""}`}>
          <div className="modal__main">
            <div className="modal__close">
              <Button onClickAction={() => props.closeModal()} variant="link">
                <CloseIcon width={30} height={30} />
              </Button>
            </div>
            <div
              className={`${props.title !== undefined ? "modal__header" : ""}`}
            >
              <h3>{props.title}</h3>
            </div>
            <div className="modal__body">{props.children}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BaseModalWindow;
