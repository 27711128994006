import BaseLogo from "../../../../src/components/BaseLogo/BaseLogo";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

export const WinnerListItem: FC<{ data: any }> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div>
      <strong
        onClick={() => {
          navigate(`${siteRoutes["challengesAndSlug"]}${data.challenge.slug}`);
        }}
      >
        {data.challenge.name}
      </strong>
      <div
        className="item__user"
        onClick={() => {
          navigate(`${siteRoutes["profileAndSlug"]}${data.winner.userSlug}`);
        }}
      >
        <div className="user__avatar">
          <picture className="adaptive">
            <figure className="adaptive-photo">
              <BaseLogo
                name={data.winner.userName}
                logoSrc={data.winner.userProfileLogoUrl}
              />
            </figure>
          </picture>
        </div>
        <p>{data.winner.userName}</p>
      </div>
    </div>
  );
};
