import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { Button, Spinner } from "@/components";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { participateInChallenge } from "../../helperFunctions/participateUserIntoChallenge";
import { ReactComponent as ParticipateIcon } from "../../assets/actions/participate-inactive.svg";
import { ReactComponent as DoneIcon } from "../../assets/actions/participate-active.svg";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import brand from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { auth } from "@/api/firebase/firebase.config";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { Games } from "@/models/database/games/games.model";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const ParticipateButton: FC<{
  data: singleChallengeProps;
  isPreview?: boolean;
  isText?: boolean;
}> = ({ data, isPreview, isText }): JSX.Element => {
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [gameData, setGameData] = useState<Games>();
  const [isParticipant, setIsParticipant] = useState<boolean | undefined>(
    data.isCurrUserParticipant
  );

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const getGameInfo = async () => {
    const games = collectionRef.games;
    const gamesRes = await games
      .where("name", "==", data.challengeData.game)
      .limit(1)
      .get();

    if (gamesRes && gamesRes.docs[0] && gamesRes.docs[0].data()) {
      setLoading(false);
      return setGameData(gamesRes.docs[0].data());
    }
    setLoading(false);
    return false;
  };

  useEffect(() => {
    setIsParticipant(data.isCurrUserParticipant);
  }, [data]);

  useEffect(() => {
    getGameInfo();
  }, []);

  const participateCurrentUserIntoThatChallenge = async () => {
    if (!gameData) {
      return createToast({
        type: "error",
        message: "Could not find game info, please try again later",
      });
    }

    if (gameData.isManualSubmission) {
      return navigate(
        `${siteRoutes["submitScoreAndSlug"]}${data.challengeData.slug}`,
        {
          state: {
            orgSlug: data.challengeData.slug,
          },
        }
      );
    }

    setLoading(true);
    if (!currentUser.uid) {
      navigate(siteRoutes["signUp1"]);
      return;
    }

    if (isParticipant) {
      setLoading(false);
      createToast({
        type: "info",
        message: `Already joined \`${data.challengeData.name}\``,
      });
      return;
    }
    if (isPreview) return;

    const res = await participateInChallenge(
      navigate,
      data.challengeData.type,
      gameData,
      currentUser.uid,
      data.challengeData.slug
    );
    if (res) {
      setIsParticipant(true);
      // getUserChallData();
      setLoading(false);
    } else {
      setShow(true);
      setLoading(false);
    }
  };

  return (
    <>
      <BaseModalWindow
        show={show}
        closeModal={() => {
          setShow(false);
          setLoading(false);
        }}
        title={`Connect your ${brand.name_small} profile with your
          ${
            data.challengeData.game === "Call of Duty Warzone"
              ? "Battle.net"
              : "game"
          } account to
          get started.`}
      >
        <Button
          variant="link"
          customStyles="connect-account__button"
          onClickAction={() => {
            navigate(siteRoutes["profileSettings"], {
              state: { to: "Connections" },
            });
          }}
        >
          Connect
        </Button>
      </BaseModalWindow>

      <Button
        variant="link"
        data-challenges="card-icons"
        onClickAction={participateCurrentUserIntoThatChallenge}
        data-type="participate"
        disabled={isPreview}
        customStyles={`${isText && ""}`}
      >
        {!loading && (
          <>
            {!isParticipant && isText && "Join"}
            {!isParticipant && !isText && <ParticipateIcon />}

            {!gameData?.isManualSubmission && isParticipant && !isText && (
              <DoneIcon />
            )}

            {!gameData?.isManualSubmission &&
              isParticipant &&
              isText &&
              "Joined"}
            {gameData?.isManualSubmission && "Submit score"}
          </>
        )}

        {/* {(!loading && isParticipant) || data.isCurrUserParticipant ? (
          <>{isText ? "Joined" : <DoneIcon />}</>
        ) : (
          ""
        )} */}

        {loading && (
          <>
            <Spinner />
          </>
        )}
      </Button>
    </>
  );
};
