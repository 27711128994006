import React, { useEffect } from "react";
import { SideMenuMobileUi } from "./SideMenu.mobile.ui";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useScrollBlock } from "@/hooks/useScrollBlock";

export const SideMenuMobile: React.FunctionComponent<
  any & { isAuth: boolean }
> = ({ show, closeWindow, isAuth }) => {
  const profile = useCurrentUserProfile((s) => s.user);
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    if (show) {
      blockScroll();
    } else {
      allowScroll();
    }
    function handleResize() {
      if (window.innerWidth > 1024) {
        closeWindow();
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [show]);

  return (
    <SideMenuMobileUi
      show={show}
      closeWindow={() => closeWindow(false)}
      isAuth={!!isAuth}
      profile={profile}
    />
  );
};
