import { ReactComponent as Feed } from "../assets/icon_feed.svg";
import { ReactComponent as Friends } from "../assets/icon_friends.svg";
import { ReactComponent as Messages } from "../assets/icon_messages.svg";
import { ReactComponent as Zones } from "../assets/icon_zones.svg";
import { ReactComponent as Gathers } from "../assets/icon_gathers.svg";
import { ReactComponent as News } from "../assets/icon_news.svg";
import { ReactComponent as Teams } from "../assets/icon_teams.svg";
import { ReactComponent as LegionLogo } from "../assets/icon_legion.svg";
import { ReactComponent as Gamepass } from "../assets/icon_gamepass.svg";
import { ReactComponent as Winners } from "../assets/icon_tournaments.svg";

import { ReactComponent as ChallengesLeft } from "../assets/challenges.svg";
import brand from "../../../utils/brandVariables/brandVariables";
import { siteRoutes } from "../../../routes/siteRoutesList";

export const routes = {
  "Play challenge": [
    brand.hasFeature.feed && {
      title: "strings.feed",
      link: siteRoutes["dashboard"],
      logo: Feed,
    },
    // { title: "Friends", link: "friends", logo: Friends },

    brand.hasFeature.messages && {
      title: "Messages",
      link: siteRoutes["messages"],
      logo: Messages,
    },

    brand.hasFeature.teams && {
      title: "strings.teams",
      link: siteRoutes["teams"],
      logo: Teams,
    },
    // { title: "Zones", link: "zones", logo: Zones },
    brand.hasFeature.news && {
      title: "strings.news",
      link: siteRoutes["news"],
      logo: News,
    },
  ],
  Winners: [
    {
      title: "Winners",
      link: siteRoutes["winners"],
      logo: Winners,
    },
    //   brand.hasFeature.challenges && {
    //     title: "strings.challenges",
    //     link: siteRoutes["challenges"],
    //     logo: ChallengesLeft,
    //   },
    //   brand.hasFeature.gathers && {
    //     title: "strings.gathers",
    //     link: siteRoutes["gathers"],
    //     logo: Gathers,
    //   },
  ],
  // esports: [
  //   { title: "Event QR code", link: "event-qr-code", logo: TournamentsLeft },
  // ],
  // WOG: [
  // { title: "Partners", link: "partners", logo: Partners },
  // { title: "Event QR code", link: "about", logo: TournamentsLeft },
  // { title: "Feedback", link: "help", logo: Help },
  // ],
};

export const routesForCreateButton = [
  brand.hasFeature.challenges && {
    title: "Challenges",
    link: siteRoutes["createChallenge"],
    logo: ChallengesLeft,
  },
  brand.hasFeature.teams && {
    title: "Teams",
    link: siteRoutes["createTeam"],
    logo: Teams,
  },
  brand.hasFeature.gathers && {
    title: "Gathers",
    link: siteRoutes["createGather"],
    logo: Gathers,
  },
];
