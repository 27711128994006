import { archiveOrReadNotification } from "./readNotification";

export default async function readAllMessages(notifications: Notification[]) {
  if (notifications.length > 0) {
    const filteredDocs = notifications.filter(
      (doc: any) =>
        doc.data.archived === false &&
        doc.data.type !== "friends-requests" &&
        doc.data.type !== "teams-requests" &&
        doc.data.type !== "challenges-requests"
    );
    const promises = filteredDocs.map((doc: any) =>
      archiveOrReadNotification(doc.realtimeDocumentID, true, false)
    );

    await Promise.all(promises);
  }
}
