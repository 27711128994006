import React, { memo, useCallback, FC } from "react";
import DatePicker from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import FormErrorMessage from "../FormErrorMessage/FormErrorMessage";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker: FC<{
  name: string;
  value: any[];
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  errors: any;
  isDisabled: boolean;
}> = ({ name, value, setFieldValue, errors, isDisabled }): JSX.Element => {
  const handleChange = useCallback(
    (e: any) => {
      setFieldValue(name, e);
    },
    [name, setFieldValue]
  );

  return (
    <div className="date-range-picker">
      <DatePicker
        locale={enGB}
        className="base-input"
        monthsShown={2}
        dateFormat="dd/MM/yy"
        minDate={new Date()}
        placeholderText="Start date"
        onChange={(e: any) => {
          handleChange(e);
        }}
        selectsRange
        startDate={value[0]}
        endDate={value[1]}
        showPreviousMonths={false}
        isClearable={!isDisabled}
        disabled={isDisabled}
      />
      {errors && errors[name] && (
        <FormErrorMessage
          text={errors[name] as string}
          isVisible={!!errors[name]}
        />
      )}
    </div>
  );
};

export default memo(DateRangePicker);
