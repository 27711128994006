import React, { useState, useCallback, FC } from "react";
import { Link } from "react-router-dom";
import doesStringContainsOnlyEmoji from "@/features/profile/helperFunctions/doesStringContainsOnlyEmoji";
import { Button } from "@/components";
import { SinglePostContentType } from "./SinglePostContentType";
import { SinglePostProps } from "../../types/singlePostsProps";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SinglePostAction } from "./SinglePostActions";
import { SinglePostInfo } from "./SinglePostInfo";
import EditPostForm from "./SinglePostEditForm";
import { SinglePostComments } from "./SinglePostComments.fetcher";

const textMapping: {
  [key: string]: string;
} = {
  userPost: "",
  sharedPost: "shared a post",
  sharedChallenge: "shared a challenge",
  sharedGather: "shared a gather",
  sharedTeam: "shared a team",
  sharedNews: "shared a news",
};

export const SinglePostComponent: FC<SinglePostProps> = (
  props
): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [showMorePost, setShowMorePost] = useState(false);
  const [totalLikesNumberState, setTotalLikesNumberState] = useState(
    props.totalLikesNumber
  );
  const [postContentMessage, setPostContentMessage] = useState<string>(
    props.postText || ""
  );
  const [showComments, setShowComments] = useState(false);
  const [last, setLast] = useState<any>(undefined);
  const [isEditPost, setIsEditPost] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [sharePostText, setSharePostText] = useState("");
  const numberOfLineBreaks = (props.postText?.match(/\n/g) || []).length;
  const doesPostTextIsEmojiOnly = !!doesStringContainsOnlyEmoji(props.postText);

  const shareThisPost = useCallback(async () => {
    // await sharePost(id, currentUser.uid, sharePostText);
  }, [props.id, setLast, currentUser.uid, sharePostText]);

  const [existingUsers, setExistingUsers] = useState<
    Array<{ userName: string; slug: string; uid: string }>
  >([]);

  // useEffect(() => {
  //   (async () => {
  //     // const profiles = await getAllAlgoliaProfiles();
  //     // const users = profiles.map((profileData: Profile) => ({
  //     //   userName: `@${profileData.userName}`,
  //     //   slug: profileData.slug,
  //     //   uid: profileData.uid,
  //     // }));
  //     // setExistingUsers(users);
  //   })();
  // }, []);

  const highlightAtMentions = (
    text: string | null
  ): React.ReactNode[] | null => {
    if (!text) return null;

    const words = text.split(" ");

    return words.map((word: string, index) => {
      const key = `${word}_${index}`;
      if (word.startsWith("@")) {
        const existingUser = existingUsers.find(
          (user) => user.userName === word
        );

        if (existingUser) {
          return (
            <Link to={`/profile/${existingUser.slug}`} key={`${word}`}>
              <span className="post-highlight__tag">{word}</span>
            </Link>
          );
        }

        return <React.Fragment key={key}>{word} </React.Fragment>;
      }
      return <React.Fragment key={key}>{word} </React.Fragment>;
    });
  };

  return (
    <>
      {!isDeleted && (
        <>
          <div className={`post${showComments ? " post--open" : ""}`}>
            {/* Refactored */}
            <SinglePostInfo
              authorUsername={props.authorUsername}
              authorLogo={props.authorLogo || props.authorUsername}
              authorSlug={props.authorSlug}
              type={props.type || ""}
              whenWasPosted={props.whenWasPosted}
              wasEdited={props.wasEdited}
              setIsDeleted={setIsDeleted}
              setIsEditPost={setIsEditPost}
              isAuthorized={props.isAuthorized}
              postId={props.id}
              currUserSlug={currentUser.slug}
            />

            {isEditPost ? (
              <EditPostForm
                originalPostText={postContentMessage || ""}
                postId={props.id}
                setIsEditPost={setIsEditPost}
                setPostContentMessage={setPostContentMessage}
              />
            ) : (
              <div
                className={`post__content ${
                  doesPostTextIsEmojiOnly ? "large" : ""
                } ${showMorePost || numberOfLineBreaks > 3 ? "" : "more"}`}
              >
                <p
                  className={`${
                    props.isPlaceholder ? "placeholder placeholder-wave" : ""
                  }`}
                >
                  {highlightAtMentions(postContentMessage)}
                </p>
                {props.postText &&
                  (props.postText.length > 80 || numberOfLineBreaks > 3) && (
                    <Button
                      variant="link"
                      onClickAction={() => setShowMorePost((prev) => !prev)}
                    >
                      {showMorePost ? "Show less" : "Show more"}
                    </Button>
                  )}
              </div>
            )}

            {/* Refactored */}
            {props.type && (
              <SinglePostContentType
                type={props.type}
                postPhoto={props.postPhoto}
                postVideo={props.postVideo}
                data={props}
              />
            )}

            {/* Refactored */}
            <SinglePostAction
              isEditPost={isEditPost}
              hasUserLiked={props.hasUserLiked}
              totalCommentsNumber={props.totalCommentsNumber}
              totalSharesNumber={props.totalSharesNumber}
              setShowComments={setShowComments}
              setShareModalOpen={setShareModalOpen}
              setTotalLikesNumberState={setTotalLikesNumberState}
              totalLikesNumber={totalLikesNumberState}
              postId={props.id}
            />
          </div>
          {showComments && (
            <SinglePostComments
              showComments={showComments}
              postId={props.id as string}
              postAuthorUid={""}
            />
          )}
        </>
      )}
    </>
  );
};
