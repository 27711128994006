import React, { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { Spinner } from "@/components/Spinner";

export const EditPostForm: FC<{
  originalPostText: string;
  postId: string;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  setPostContentMessage: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  originalPostText,
  postId,
  setIsEditPost,
  setPostContentMessage,
}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [errors, setErrors] = useState<any>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [editPostVals, setEditPostVals] = useState<{
    postText: string;
  }>({
    postText: originalPostText || "",
  });
  const isStringValid =
    editPostVals.postText.length > 0 &&
    editPostVals.postText !== originalPostText;
  const setFieldValue = async (targetName: string, value: any) => {
    return setEditPostVals((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const submit = async () => {
    if (!currentUser.uid || !postId) return;
    // Check if text is not empty and same as before
    if (!isStringValid) {
      setErrors({
        postText: "Post message cannot be empty or the same as before",
      });
      return;
    }
    setSubmitting(true);
    await QueryDB.updateDoc("posts", postId, {
      postText: editPostVals.postText as string,
      wasEdited: true,
    }).then(() => {
      createToast({
        type: "success",
        message: "Post updated!",
      });
      setPostContentMessage(editPostVals.postText);
      setSubmitting(false);
      setIsEditPost(false);
    });
  };

  return (
    <>
      <form
        className="edit-post"
        action=""
        onSubmit={(e) => {
          e.preventDefault();
          if (!submitting) {
            submit();
          }
        }}
      >
        <BaseTextInput
          label="Update your post"
          textType="text"
          value={editPostVals.postText}
          placeholder="Name"
          setObjectState={setFieldValue}
          setObjectStateTargetName="postText"
          error={errors && errors["postText"]}
        />
        <div className="edit-post__buttons">
          <Button
            variant="secondary"
            onClick={() => setIsEditPost(false)}
            className="me-4"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting || !isStringValid}
          >
            {submitting ? <Spinner /> : "Save"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditPostForm;
