import { FC } from "react";
import placeholderImage from "@/features/gather/assets/placeholders/gather_placeholder.png";
import { v4 } from "uuid";
import { GatherOverviewTypes } from "@/features/gather/types/cloudFunctions/GatherOverview";
import { Link } from "react-router-dom";
import { Badge } from "@/components/Badge/index";
import { fullDays } from "@/utils/constants/weekDays";
import { FavoriteButton } from "@/components/FavoriteButton";

const GatherCardComponent: FC<GatherOverviewTypes> = (props): JSX.Element => {
  return (
    <>
      <div className="gather-card">
        <div className="gather-card__fav">
          <FavoriteButton
            slug={props.slug}
            isInFavoritesOfCurrentUser={props.isFavoriteByCurrentUser}
            featureType="gather"
            name={props.name}
          />
        </div>
        <Link className="gather-card__link" to={`/gathers/${props.slug}`}>
          <div className="gather-card__image">
            <picture className="adaptive">
              <figure className="adaptive-photo">
                <img
                  className={`${props.isPlaceholder && "placeholder-wave"} `}
                  width={176}
                  height={150}
                  src={props.isPlaceholder ? placeholderImage : props.logoUrl}
                />
              </figure>
            </picture>
          </div>
          <div className="gather-card__content">
            <div className="gather-card__heading">
              <h5
                className={` ${
                  props.isPlaceholder &&
                  "cursor-default placeholder placeholder-wave col-6"
                }`}
              >
                {props.name}
              </h5>
            </div>

            <div className="gather-card__criteria">
              <span>{props.meetType}</span>

              <ul>
                {props.games && !props.isPlaceholder
                  ? props.games.map((game) => {
                      return <li key={game}>{game}</li>;
                    })
                  : props.isPlaceholder &&
                    Array.from(Array(2), () => {
                      return (
                        <li key={v4()} className="placeholder-wave placeholder">
                          plchldr
                        </li>
                      );
                    })}
              </ul>
            </div>
            <div
              className={`gather-card__info ${
                props.isPlaceholder ? "placeholder-wave" : ""
              }`}
            >
              <div className="gather-card__date">
                {props.isPlaceholder ? (
                  <div className="placeholder-date">
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                  </div>
                ) : (
                  <div className="gather-card__date--date">
                    {props.startDate && props.startDate._seconds && (
                      <>
                        <time>
                          {
                            fullDays[
                              new Date(props.startDate._seconds * 1000)
                                .toDateString()
                                .split(" ")[0] as "Sun"
                            ]
                          }
                        </time>

                        <time>
                          {new Date(props.startDate._seconds * 1000)
                            .toLocaleDateString("en-US", {
                              month: "long",
                              year: "numeric",
                              day: "numeric",
                            })
                            .split(" ")
                            .join()
                            .replace(",", " ")}
                        </time>
                      </>
                    )}
                  </div>
                )}

                <div className="gather-card__date--time">
                  <time
                    className={`${
                      props.isPlaceholder ? "placeholder-wave" : ""
                    }`}
                  >
                    {!props.isPlaceholder &&
                      props.startDate &&
                      props.startDate._seconds && (
                        <div>
                          {new Date(
                            props.startDate._seconds * 1000
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: undefined,
                          })}
                        </div>
                      )}
                  </time>
                </div>
              </div>
            </div>
            <div
              className={`gather-card__users ${
                props.isPlaceholder ? "placeholder-wave" : ""
              }`}
            >
              <div className="gather-card__participants">
                <span className="badge-wrapper">
                  Participants{" "}
                  <Badge variant="primary">
                    {!props.isPlaceholder ? props.participantsCount : ""}
                  </Badge>
                </span>
              </div>

              {window.location.pathname.includes("gathers") && (
                <div className="gather-card__interested">
                  <span className="badge-wrapper">
                    Interested
                    <Badge variant="primary">
                      {!props.isPlaceholder ? props.interestedInCount : ""}
                    </Badge>
                  </span>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
export default GatherCardComponent;
