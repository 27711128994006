import { Query_shared } from "@/api/shared";
import { SinglePostComponent } from "../SinglePost/SinglePostComponent";

export default function ProfileSinglePost({ props }: any) {
  const post = props;

  return (
    <SinglePostComponent
      hasUserLiked={post.hasCurrentUserLikedThisPost}
      authorSlug={post.authorSlug}
      authorUsername={post.authorUsername}
      authorLogo={post.authorLogo}
      whenWasPosted={{
        stringDate: new Date(post.created._seconds * 1000).toDateString(),
        howLongAgo: Query_shared.getWhenWasPosted(post.created._seconds * 1000),
      }}
      postText={post.postText}
      postPhoto={post.postPhoto}
      postVideo={post.postVideo}
      totalCommentsNumber={post.totalCommentsNumber}
      totalLikesNumber={post.totalLikesNumber}
      totalSharesNumber={post.totalSharesNumber ?? 0}
      id={post.id}
      wasEdited={!!post.wasEdited}
      additionalData={post?.additionalData}
      type={post?.type || "userPost"}
      isAuthorized={post.isAuthorized}
      uid={""}
    />
  );
}
