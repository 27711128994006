import { memo, FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import i18next from "i18next";
import AuthProcessBackground from "../components/AuthProcessBackground";
import AuthConnectOptions from "../components/AuthConnectOptions";
import AuthProcessAgreementText from "../components/AuthProcessAgreementText";
import brand from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { SignUpAnonymously } from "../components/SignUpAnonymously";

const SignUp1: FC = (): JSX.Element => {
  // const location = useLocation();
  const navigate = useNavigate();
  if (localStorage.getItem("userProfile")) {
    localStorage.removeItem("userProfile");
  }
  const navigateBack = () => navigate(-1);

  return (
    <AuthProcessBackground>
      <div className="auth__step auth__step--one">
        <div className="auth__header">
          <span className="auth__breadcrumbs">
            {location.pathname === "/login"
              ? ""
              : `${i18next.t("strings.step")} ${
                  location.pathname[9]
                } ${i18next.t("strings.of")} 3`}
          </span>
          <h1 className="auth__title">
            {location.pathname === "/login"
              ? `${i18next.t("auth.log_into")} ${brand.name}`
              : i18next.t("auth.create_profile")}
          </h1>
          <p className="auth__description">
            {location.pathname === "/login"
              ? `${i18next.t("auth.log_in_how")} `
              : i18next.t("auth.sign_up_how")}
          </p>
        </div>

        {brand.hasFeature.quickSignUp && <SignUpAnonymously />}
        {brand.hasFeature.quickSignUp && <h4>Other sign up methods</h4>}

        <div className="auth__connections">
          <AuthConnectOptions from={location.pathname.slice(1)} />
        </div>

        <AuthProcessAgreementText />

        <div className="auth__actions">
          <Button variant="secondary" onClick={() => navigateBack()}>
            {i18next.t("buttons.go_back")}
          </Button>
        </div>
      </div>
    </AuthProcessBackground>
  );
};

export default memo(SignUp1);
