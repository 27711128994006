import Dummy from "@/dummy";
import SignUp3 from "@/features/authentication/pages/SignUp3";
import { CreateNewsForm, NewsOverview, SingleNews } from "@/features/news";
import { FeedPage, SinglePostPage } from "@/features/feed";
import {
  ProfileSettings,
  ProfileTabDataMobileFetcher,
} from "@/features/profile";
import {
  ChallengeOverviewPage,
  CreateChallengePage,
  SubmitScore,
} from "@/features/challenge";
import { SingleChallengePage } from "../features/challenge/index";
import {
  CreateTeamForm,
  SingleTeamFetcher,
  TeamsOverviewFetcher,
} from "@/features/team";
import { GatherOverview } from "@/features/gather";
import { Winners } from "../features/winners/pages/Winners";

import { PageDoesntExist } from "@/pageDoesntExist";
import { SingleGather } from "@/features/gather/components/single/SingleGather.fetcher";
import { NotificationPage } from "@/features/notifications/components/page/NotificationPage.fetcher";
import { siteRoutes } from "./siteRoutesList";

import brand from "./../utils/brandVariables/brandVariables";
import { EventQrCode } from "../features/eventQrSignUp/EventsQr";
import { ScannedQR } from "../features/eventQrSignUp/ScannedQR";
import ProfilePage from "../../branchAndBrandSpecific/features/profile/ProfilePage";
import { TermsOfUse } from "./../features/staticPages/TermsOfUse";
import { PrivacyPolicy } from "./../features/staticPages/PrivacyPolicy";

export const loggedInRoutes: {
  element: (() => JSX.Element) | React.FunctionComponent;
  path: string;
}[] = [
  // AUTH AFTER SIGN UP
  {
    element: SignUp3,
    path: siteRoutes["signUpSuccess"],
  },

  // PAGES
  {
    element: SingleChallengePage,
    path: "/", // route to home
  },
  {
    element: SingleChallengePage,
    path: "/home", // route to home
  },

  // PROFILE
  {
    element: () => ProfilePage({ belongToCurrentUser: true }),
    path: siteRoutes["profile"], // route to self profile
  },
  {
    element: ProfilePage,
    path: `${siteRoutes["profileAndSlug"]}:slug`, // route to other user's profile
  },
  {
    element: ProfileSettings,
    path: siteRoutes["profileSettings"], // route to other user's profile
  },
  {
    element: ProfileTabDataMobileFetcher,
    path: `${siteRoutes["profileTabAndSlug"]}:slug`, // route to other user's profile
  },

  // NEWS
  {
    element: !brand.hasFeature.news ? PageDoesntExist : NewsOverview,
    path: siteRoutes["news"], // route to news overview
  },
  {
    element: !brand.hasFeature.news ? PageDoesntExist : SingleNews,
    path: `${siteRoutes["newsAndSlug"]}:slug`, // route to news overview
  },
  {
    element: !brand.hasFeature.news
      ? PageDoesntExist
      : () => CreateNewsForm({ isEdit: false }),
    path: siteRoutes["createNews"], // route to news overview
  },

  // FEED/POSTS
  {
    element: FeedPage,
    path: siteRoutes["dashboard"],
  },
  {
    element: SinglePostPage,
    path: `${siteRoutes["singlePostAndSlug"]}:slug`,
  },

  // CHALLENGES
  {
    element: !brand.hasFeature.challenges
      ? PageDoesntExist
      : ChallengeOverviewPage,
    path: siteRoutes["challenges"],
  },
  {
    element: !brand.hasFeature.singleChallenge
      ? PageDoesntExist
      : SingleChallengePage,
    path: `${siteRoutes["challengesAndSlug"]}:slug`,
  },
  {
    element: !brand.hasFeature.challenges
      ? PageDoesntExist
      : () => CreateChallengePage({ isEdit: false }),
    path: siteRoutes["createChallenge"], // route to challenges overview
  },
  {
    element:
      !brand.hasFeature.manualSubmissions || !brand.hasFeature.challenges
        ? PageDoesntExist
        : SubmitScore,
    path: `${siteRoutes["submitScoreAndSlug"]}:slug`, // route to challenge submit score page
  },

  // GATHERs
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : GatherOverview,
    path: siteRoutes["gathers"],
  },
  {
    element: !brand.hasFeature.gathers ? PageDoesntExist : SingleGather,
    path: `${siteRoutes["GathersAndSlug"]}:slug`,
  },

  // TEAMS
  {
    element: !brand.hasFeature.teams ? PageDoesntExist : TeamsOverviewFetcher,
    path: siteRoutes["teams"],
  },
  {
    element: !brand.hasFeature.teams ? PageDoesntExist : SingleTeamFetcher,
    path: `${siteRoutes["teamsAndSlug"]}:slug`,
  },
  {
    element: !brand.hasFeature.teams
      ? PageDoesntExist
      : () => CreateTeamForm({ isEdit: false }),
    path: siteRoutes["createTeam"],
  },
  //Winners
  {
    element: Winners,
    path: siteRoutes["winners"],
  },
  // MESSAGES
  {
    element: !brand.hasFeature.messages ? PageDoesntExist : Dummy,
    path: siteRoutes["messages"],
  },
  {
    element: !brand.hasFeature.messages ? PageDoesntExist : Dummy,
    path: `${siteRoutes["messagesAndSlug"]}:slug`,
  },

  // QR SIGN UP AND EVENT TRACKET
  // {
  //   element: EventQrCode,
  //   path: siteRoutes["eventQrCode"],
  // },
  // {
  //   element: ScannedQR,
  //   path: `${siteRoutes["scanQrCodeAndSlug"]}:pcId`,
  // },

  // NOTIFICATIONS
  {
    element: brand.hasFeature.notifications
      ? NotificationPage
      : PageDoesntExist,
    path: siteRoutes["notifications"],
  },

  // OTHER | UNUSED
  {
    element: PageDoesntExist,
    path: siteRoutes["help"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["about"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["partners"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["zones"],
  },
  {
    element: PageDoesntExist,
    path: siteRoutes["friends"],
  },
  {
    element: TermsOfUse,
    path: siteRoutes["Terms"],
  },
  {
    element: PrivacyPolicy,
    path: siteRoutes["privacy-policy"],
  },
];
