import React, { useEffect, FC } from "react";
import SingleChallengeGeneralInfo from "./SingleChallengeGeneralInfo";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import SingleChallengeAdditionalInfoWithTooltip from "./SingleChallengeAdditionalInfoWithTooltip";
import SingleChallengeFormula from "./SingleChallengeFormula";
import { SingleChallengeActionButtons } from "./SingleChallengeActionsButtons";
import CreatorCard from "@/components/CreatorCard";

export const SingleChallengeUi: FC<{
  data: singleChallengeProps;
  isPreview: boolean;
  isPrivateForUser: boolean;
  additionalFields: {
    title: string;
    value: string;
  }[];
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}> = ({
  data,
  isPreview,
  isPrivateForUser,
  additionalFields,
  isEdit,
  setIsEdit,
  children,
}): JSX.Element => {
  useEffect(() => {
    if (!data.isCreatedByCurrUser && setIsEdit !== undefined) {
      setIsEdit(false);
    }
  }, [isEdit]);

  return (
    <div className="challenge">
      {/* General info (Image, time, desc, tags, title) */}
      <div className="challenge__info">
        <SingleChallengeGeneralInfo
          data={data}
          isEdit={false}
          isPreview={isPreview}
          isPrivateForUser={isPrivateForUser}
        />
      </div>

      {/* Buttons (Edit, Fav, Invite) */}
      <div className="challenge__logos">
        {!isPreview && !isPrivateForUser ? (
          <SingleChallengeActionButtons
            data={data}
            setIsEdit={setIsEdit}
            isPreview={isPreview}
          />
        ) : (
          ""
        )}
      </div>

      {/* Additional info fields */}
      {!isPrivateForUser &&
        additionalFields
          .filter((obj) => obj.value !== "")
          .map((additionalField) =>
            additionalField.title !== "Team size" &&
            data.challengeData.game !== "PUBG" ? (
              <SingleChallengeAdditionalInfoWithTooltip
                title={additionalField.title}
                value={additionalField.value}
                key={additionalField.title}
              />
            ) : (
              data.challengeData.game === "PUBG" && (
                <SingleChallengeAdditionalInfoWithTooltip
                  title={additionalField.title}
                  value={additionalField.value}
                  key={additionalField.title}
                />
              )
            )
          )}

      {/* Formula */}
      {!isPrivateForUser && (
        <SingleChallengeFormula
          coefficients={data.challengeAdditionalData.fieldToCompareCoefficients}
        />
      )}

      {/* Author card */}
      <div className="challenge__creator">
        <CreatorCard
          isFollowedByCurrentUser={data.isCreatorFollowedByCurrentUser}
          creatorFollowersNumber={data.creatorFollowersCount}
          creatorSlug={data.creatorProfile.slug}
          isPlaceholder={false}
          creatorUserName={data.creatorProfile.userName}
          creatorLogo={data.creatorProfile.profileLogoUrl}
          creatorId={data.creatorProfile.uid}
        />
      </div>

      {/* Description */}
      {data.challengeData.description && (
        <div className="challenge__description">
          <h3>Description</h3>
          <p>{data.challengeData.description}</p>
        </div>
      )}

      {/*  Leaderboard */}
      {children}

      {/* {Array.from(Array(7), (e, index) => {
                return (
                    <div key={index}>
                      {/* ADD SKELETON LOADER FOR LEADERBOARD }
                    
                    </div>
                );
              })} */}
    </div>
  );
};
