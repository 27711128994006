import { RouterProvider } from "react-router-dom";
import "@github/markdown-toolbar-element";
import "../branchAndBrandSpecific/styles/main.scss";
import { router } from "./../branchAndBrandSpecific/Routes";
import { useEffect } from "react";
import brand from "../branchAndBrandSpecific/utils/brandVariables/brandVariables";

function App(): JSX.Element {
  //pushing to main- > should appear in LENOVO_IMAGINE_2023
  //pushing to main- > should appear in LENOVO_IMAGINE_23 - new branch

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");

    if (document.title.length === 0) {
      document.title = brand.siteTitle;
    }

    if (!link) {
      link = document.createElement("link");
      if (link) {
        link.rel = "icon";
        document.head.appendChild(link);
        link.href = brand.siteIcon;
      }
    }
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
