import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { ReactComponent as IconGather } from "../../assets/gathers-shared.svg";
import { Query_shared } from "../../../../api/shared/index";
import { Query_Imgix } from "../../../../api/imgix/index";
import { SinglePostProps } from "../../types/singlePostsProps";

const SharedNews: React.FunctionComponent<{
  data: SinglePostProps;
  isFromFeed: boolean;
}> = ({ data, isFromFeed }) => {
  const [logoError, setLogoError] = useState<boolean>(false);
  if (data && data.additionalData) {
    try {
      return (
        <div className="shared-gather">
          {!isFromFeed && <div className="vertical-line" />}

          <div
            aria-hidden
            className={`shared-gather__card ${isFromFeed ? "feed" : ""}`}
          >
            <Link to={`/news/${data.additionalData.slug}`}>
              <div className="shared-gather__card-image">
                <Image
                  onError={() => setLogoError(true)}
                  alt={`User-uploaded image for ${name} team`}
                  src={Query_shared.imageErrorHandler(
                    data.additionalData.imageURL.includes("wog.imgix")
                      ? `${
                          data.additionalData.imageURL
                        }?${Query_Imgix.getImgixUrlProps({
                          w: "248",
                          h: "165",
                        })}`
                      : data.additionalData.imageURL,
                    logoError,
                    undefined
                  )}
                />
              </div>

              <div className="shared-gather__card--content">
                <h3>{data.additionalData.title}</h3>
                <p>{data.additionalData.intro}</p>
              </div>
              <div className="shared-gather__card-icon d-xs-none d-md-block">
                <IconGather width={42} height={38} />
              </div>
            </Link>
          </div>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default SharedNews;
