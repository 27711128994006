import brand_logo_large from "../../assets/jbl_logo.svg";
import brand_logo_small from "../../assets/jbl_logo.svg";
// import brand_logo_medium from "@assets/brands/generic_company_black.svg";
//TAB ICON SHOULD BE REPLACED
import tabIcon from "../../utils/brandVariables/assets/appIcon.ico";
//this is test comment -> delete it!!
import brand_logo_white from "../../utils/brandVariables/assets/wog-mark-white.svg";

type BrandProps = {
  siteTitle: string; // This is the text that is show in the tab
  siteIcon: string; // This is the icon that is show in the tab
  name: string;
  name_small: string;
  logos: {
    large: string;
    small: string;
    white: string;
    profile: string;
    alt_text: string;
    medium: string;
  };
  brandValue: string;
  eventCode: string;
  brandTheme: string;
  brandIntials: string;
  site: string;
  authMethod: "phone" | "email";
  defaultCountryCode: string;
  brandStoreExtension: string;
  collectionForSubmissions: string; // This will be for events, when submitting a score, it will be sent to this collection
  documentForSubmissions: string; // This will be for events, when submitting a score, it will be sent to this document
  // for future platforms we can disable certain features this way, For example the themes tab in profile settings, the tab + page will be gone/unaccessable
  defaultNavigateUrl: string; // In case its needed, when the page gets loaded on "/" it can redirect to this url
  isEvent: boolean;
  hasFeature: {
    themes: boolean; //Profile tab
    connections: boolean; //Profile tab
    achievements: boolean;
    quickSignUp: boolean; // Sign up method
    messages: boolean;
    teams: boolean;
    news: boolean;
    challenges: boolean;
    singleChallenge: boolean;
    gathers: boolean;
    friends: boolean;
    zones: boolean;
    feed: boolean;
    qrSignUp: boolean;
    manualSubmissions: boolean;
    landing: boolean;
    login: boolean;
    notifications: boolean;
    haslanguagePicker: boolean;
  };
};

// !!! IF YOU ADD ANY FIELD TO THIS OBJECT, IT WILL NOT BE PUSHED TO OTHER BRANCHES
// !!! MAINLY IN CASE YOU ADD A "hasFeature" THAT HAS A GLOBAL USE: PUSH THIS CHANGE MANUALY TO EACH BRANCH
// !!! THIS WILL CAUSE A UNDEFINED ERROR OTHERWISE ON OTHER BRANCHES IN THOSE FILES WEN ITS REDEPLOYED

const brand = {
  siteTitle: "JBL 2023",
  siteIcon: tabIcon,
  name: "JBL Dreamhack Winter 2023",
  name_small: "JBL Dreamhack",
  logos: {
    large: brand_logo_large,
    white: brand_logo_white,
    small: brand_logo_small,
    medium: brand_logo_small,
    alt_text: "JBL Legion logo",
    profile: brand_logo_small,
  },
  brandTheme: "jbl", // Theme of the brand
  site: "", // Live url of site
  authMethod: "phone", // Only needed for events, gives user set options to manualy sign up, options are:  email | phone
  defaultCountryCode: "DK", // Only needed for retail platforms | For initial phone number | Changes all auth methods`
  brandStoreExtension: "", // Only needed for retail platforms | Extension used for email
  brandValue: "JBL", // Only needed for retail platforms | Brand value needed for BE related functions
  eventCode: "JBL", // Only needed for event platforms
  brandIntials: "j", // gets added after the username, to drasticaly descrease chance of duplicate usernames across different apps | Only needed for event platforms
  collectionForSubmissions: "dreamhack_submissions",
  documentForSubmissions: "elgiganten_dreamhack_winter2023",
  defaultNavigateUrl: "/",
  isEvent: true,
  hasFeature: {
    themes: false,
    connections: false,
    achievements: false,
    quickSignUp: true,
    messages: false,
    teams: false,
    news: false,
    challenges: false,
    singleChallenge: true,
    gathers: false,
    friends: false,
    zones: false,
    feed: false,
    qrSignUp: false,
    manualSubmissions: true,
    landing: false,
    login: false,
    notifications: false,
    haslanguagePicker: false,
  },
} as BrandProps;

export default brand;
