import { FC, useState } from "react";
import { Badge } from "@/components/Badge/index";
import { ReactComponent as EmptyStar } from "@/assets/actions/Star-icon.svg";
import { ReactComponent as FilledStar } from "@/assets/actions/Star-icon-filled.svg";
import QueryDB from "@/api/queryDB/common";
import { createToast } from "@/utils/toaster/createToast";
import { featureTypeToCollection } from "@/utils/constants/collectionTypes";
import { useAuthPersistState } from "@/store/auth/auth.store";
import firebase from "firebase/app";

export const FavoriteButton: FC<{
  slug: string;
  isPreview?: boolean;
  isInFavoritesOfCurrentUser?: boolean;
  featureType: "challenge" | "team" | "gather" | "news";
  name: string;
}> = ({
  slug,
  isInFavoritesOfCurrentUser,
  featureType,
  isPreview = false,
  name,
}): JSX.Element => {
  const currentUser = useAuthPersistState((s) => s.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCurrUserFavourite, setIsCurrUserFavourite] = useState<boolean>(
    isInFavoritesOfCurrentUser
  );
  const handleAddToFavorite = async () => {
    if (loading || isPreview || !slug || !currentUser.uid) {
      return;
    }
    setLoading(true);
    setIsCurrUserFavourite(!isCurrUserFavourite);
    if (featureType === "challenge") {
      return await QueryDB.updateDoc(
        featureTypeToCollection[featureType],
        currentUser.uid,
        {
          ["favoriteChallenges"]: isCurrUserFavourite
            ? firebase.firestore.FieldValue.arrayRemove(slug)
            : firebase.firestore.FieldValue.arrayUnion(slug),
        }
      )
        .then(() => {
          createToast({
            type: "success",
            message: isCurrUserFavourite
              ? `Removed \`${name}\` from favourites`
              : `Added \`${name}\` to favourites`,
          });
        })
        .catch(() => {
          setLoading(false);

          setIsCurrUserFavourite(!isCurrUserFavourite);
          createToast({
            type: "error",
            message: isCurrUserFavourite
              ? `Failed to remove \`${name}\` from favourites`
              : `Failed to add \`${name}\` to favourites`,
          });
        });
    }

    await QueryDB.updateDoc(featureTypeToCollection[featureType], slug, {
      isFavorite: !isInFavoritesOfCurrentUser,
    })
      .then(() => {
        setLoading(false);
        createToast({
          type: "success",
          message: isCurrUserFavourite
            ? `Removed \`${name}\` from favourites`
            : `Added \`${name}\` to favourites`,
        });
      })
      .catch(() => {
        setLoading(false);
        setIsCurrUserFavourite(!isCurrUserFavourite);
        createToast({
          type: "error",
          message: isCurrUserFavourite
            ? `Failed to remove \`${name}\` from favourites`
            : `Failed to add \`${name}\` to favourites`,
        });
      });
  };

  return (
    <Badge
      data-challenges="card-icons"
      onClick={() => {
        if (!loading) {
          handleAddToFavorite();
        }
      }}
      variant="fav"
    >
      {isInFavoritesOfCurrentUser ? <FilledStar /> : <EmptyStar />}
    </Badge>
  );
};
