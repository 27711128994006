import BaseLogo from "@/components/BaseLogo/BaseLogo";
import i18next from "i18next";
import { FC } from "react";
import { Profile } from "@/models/database/profiles/profile.model";
import { Button } from "@/components";
import { Query_shared } from "@/api/shared";

export const SettingsProfileImageButtons: FC<{
  imgSrc: string;
  profile: Profile;
  setIsSubmiting: any;
  submitProfileLogo: any;
  setIsAvatar: any;
  setImgSrc: any;
  isUpdating: boolean;
}> = ({
  imgSrc,
  profile,
  setIsSubmiting,
  submitProfileLogo,
  setIsAvatar,
  setImgSrc,
  isUpdating,
}) => {
  return (
    <div className="settings__info">
      <div className="settings__alert">
        <p>{i18next.t(`profile.edit.avatar.upload_msg`)}</p>
      </div>
      <div className="settings__image">
        <BaseLogo
          logoSrc={imgSrc || profile.profileLogoUrl}
          name={profile.userName}
          isPlaceHolder={undefined}
        />
      </div>

      {imgSrc && (
        <div className="settings__changes">
          <Button
            variant="primary"
            customStyles="settings__changes--save"
            disabled={isUpdating}
            onClickAction={() => {
              setIsSubmiting(true);
              submitProfileLogo();
            }}
          >
            Save changes
          </Button>
          <Button
            variant="secondary"
            disabled={isUpdating}
            customStyles="settings__changes--cancel"
            onClickAction={() => {
              setIsAvatar(false);
              setImgSrc(undefined);
            }}
          >
            Cancel
          </Button>
        </div>
      )}

      {profile.profileLogoUrl && !imgSrc && (
        <Button
          variant="secondary"
          customStyles="settings__changes--remove"
          onClickAction={() => {
            setImgSrc(undefined);
            setIsSubmiting(true);
            submitProfileLogo();
          }}
          disabled={isUpdating}
        >
          {i18next.t("buttons.remove_avatar")}
        </Button>
      )}
    </div>
  );
};
