import React, { useEffect, useState } from "react";
import { WinnersList } from "../components/WinnerList";
import useCloudFunctionCall from "../../../../src/hooks/useCloudFunctionCall";
import { WinnersResponse } from "../types/winnersTypes";

export const Winners = () => {
  const buttonNames = ["Friday", "Saturday", "Sunday"];
  const [winnersData, setWinnersData] = useState<any>();
  const [availableHours, setAvailableHours] = useState<any>();
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [data, loading, error, fetch_challenge] = useCloudFunctionCall<
    WinnersResponse[]
  >("getEventChallengeAndTheirWinnersBasedOnEventId", undefined);

  const formatWinnersData = () => {
    let availableHours: any = [];
    if (data) {
      const formattedData = data.map((doc: WinnersResponse) => {
        const startHour = new Date(
          (doc.challenge.startDate as any)._seconds * 1000
        ).getHours();
        const day = new Date(
          (doc.challenge.startDate as any)._seconds * 1000
        ).getDay();

        if (!availableHours.includes(startHour)) {
          availableHours.push(startHour);
        }

        return {
          ...doc,
          startHour: startHour,
          day: weekday[day],
        };
      });
      setAvailableHours(availableHours);
      setWinnersData(formattedData);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetch_challenge({
        eventCode: "jbl-dreamhack-winter-2023",
        gameType: ["fm8", "fm8"],
      }).then(() => {
        formatWinnersData();
      });
    };

    fetch();
  }, []);
  console.log(winnersData);

  return (
    <section className="winners">
      <h1>Winners</h1>
      <p>
        Check out our challenge winners during Dreamhack Winter 2023 in
        Jönköping.
      </p>
      <WinnersList
        buttonNames={buttonNames}
        loading={loading}
        error={error}
        data={winnersData}
        availableHours={availableHours}
      />
    </section>
  );
};
