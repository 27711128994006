import { collectionDoc } from "@/api/collectionRef/collectionOperations";
import { auth } from "@/api/firebase/firebase.config";
import QueryDB from "@/api/queryDB/common";
import { Spinner } from "@/components/Spinner";
import SignUp1 from "@/features/authentication/pages/SignUp1";
import { avatarsRaw } from "@/features/authentication/utils/avatars";
import mockUsernames from "@/features/authentication/utils/mockUsernames";
import { Profile } from "@/models/database/profiles/profile.model";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import brand from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import UnAuthorizedLayout from "../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { Query_Imgix } from "@/api/imgix";
const ProtectedRoutesAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthPersistState((state) => state.user);
  const setUser = useAuthPersistState((state) => state.setUser);
  const [error, setError] = useState<string | null>(null);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const profile = useCurrentUserProfile((s) => s.user);
  const isUrlEventCode = window.location.pathname.includes("event-qr-code");

  useEffect(() => {
    if (!user) {
      setIsLoading(true);
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        console.log(user, profile);

        if (user) {
          if (!profile) {
            if (!isUrlEventCode) {
              const username = `${
                mockUsernames[
                  Math.floor(Math.random() * mockUsernames.length) + 1
                ]
              }${brand.brandIntials}${Math.floor(Math.random() * 100) + 1}`;

              const slug = normalizeString(
                convertCleanEntityNameToSlug(cleanEntityName(username))
              );

              let imgSrc =
                avatarsRaw[Math.floor(Math.random() * avatarsRaw.length)]; // Pick random image
              let firebaseFileURL;
              // Convert image to
              await fetch(imgSrc)
                .then((data) => data.blob())
                .then(async (blob) => {
                  const myFile = new File([blob], `${imgSrc}`, {
                    type: blob.type,
                  });
                  firebaseFileURL = await Query_Imgix.convertImageToImgIX(
                    myFile,
                    "profile"
                  );
                });
              const userData = {
                slug,
                region: "Europe",
                userName: username,
                uid: user.uid,
                fullName: "",
                profileDescription: "",
                favoriteGames: [],
                lang: "eng",
                profileLogoUrl: firebaseFileURL,
                theme: brand.brandTheme,
                platform: brand.brandValue,
                eventCode: "jbl-dreamhack-winter-2023",
                allPlatforms: [brand.brandValue],
                emailAddress: "",
                country: "",
                followersCount: 0,
                friendsCount: 0,
                profileLogoUrlHistory: [],
              } as Profile & {
                eventCode: string;
              };
              await QueryDB.createNewDoc("profiles", userData, user?.uid);

              setProfile(userData);
              setIsLoading(false);
            } else {
              (async () => {
                const profileRef = await collectionDoc.profiles(user.uid);
                const profile = profileRef.data();
                if (profile) {
                  setProfile(profile);
                }
              })();
            }
          }
          setUser(user);
        } else {
          if (!isUrlEventCode) {
            await auth.signInAnonymously();
          } else {
            // in case user log out
            // setProfile(null);
            // setUser(null);
          }
        }
      } catch (error: any) {
        setError(error.message);
      }

      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading && !user) {
    return <Spinner />;
  }

  if (!!error) {
    throw Error(error);
  }
  return (
    <>
      {!!user && user !== null ? (
        <Outlet />
      ) : (
        <UnAuthorizedLayout>
          <SignUp1 />
        </UnAuthorizedLayout>
      )}
    </>
  );
};

export default ProtectedRoutesAuth;
