import React, { useState, useCallback, useEffect, FC } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as ActiveFire } from "../../assets/fire-active.svg";
import { ReactComponent as InactiveFire } from "../../assets/fire-inactive.svg";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { commentsResponse } from "../../types/commentsTypes";

export const SinglePostSingleComment: FC<{
  comment: commentsResponse;
  postId: any;
  setComments: any;
  whenWasPosted: any;
}> = ({ comment, postId, setComments, whenWasPosted }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [hasUserLikedState, setHasUserLikedState] = useState(
    comment.hasCurrentUserLikedThisComment
  );
  const [totalLikesNumberState, setTotalLikesNumberState] = useState(
    comment.totalLikesNumber
  );

  const handleLike = useCallback(async () => {
    if (!currentUser.uid) return;
    if (hasUserLikedState) {
      setHasUserLikedState(() => false);
      setTotalLikesNumberState((prev) => prev - 1);
      // await deleteLikeFromComment(
      //   comment.postId,
      //   currentUser.uid,
      //   comment.id as string
      // );
    } else {
      setHasUserLikedState(() => true);
      setTotalLikesNumberState((prev) => prev + 1);
      // await addLikeToComment(comment.postId, currentUser.uid, comment.id as string);
    }
  }, [hasUserLikedState, currentUser.uid, comment.id, postId]);
  const [existingUsers, setExistingUsers] = useState<
    Array<{ userName: string; slug: string; uid: string }>
  >([]);

  // useEffect(() => {
  //   (async () => {
  //     const profiles = await getAllProfiles();
  //     const users = profiles.map((profileData) => ({
  //       userName: `@${profileData.userName}`,
  //       slug: profileData.slug,
  //       uid: profileData.uid,
  //     }));
  //     setExistingUsers(users);
  //   })();
  // }, []);

  const highlightAtMentions = (
    text: string | undefined
  ): React.ReactNode[] | null => {
    if (!text) return null;

    const words = text.split(" ");
    return words.map((word: string) => {
      if (word.startsWith("@")) {
        const existingUser = existingUsers.find(
          (user) => user.userName === word
        );

        if (existingUser) {
          return (
            <Link to={`/profile/${existingUser.slug}`} key={`${word}`}>
              <span className="post-highlight__tag">{word}</span>
            </Link>
          );
        }

        return <React.Fragment key={`${word}`}>{word} </React.Fragment>;
      }
      return <React.Fragment key={`${word}`}>{word} </React.Fragment>;
    });
  };

  return (
    <div className="single-comment">
      <div className="vertical-line--comment" />
      <div />
      <div className="single-comment__wrapper">
        <div className="single-comment__content">
          <Link to={`/profile/${comment.authorSlug}`}>
            {/* <picture className="adaptive">
              <figure className="adaptive-photo"> */}
            <div className="avatar">
              <BaseLogo
                name={comment.authorUsername}
                logoSrc={comment.authorLogo}
                isPlaceHolder={undefined}
              />
            </div>
            {/* </figure>
            </picture> */}
          </Link>
          <div>
            <Link
              className="single-comment__profile"
              to={`/profile/${comment.authorSlug}`}
            >
              <span>{comment.authorUsername}</span>
              <time dateTime={whenWasPosted?.stringDate}>
                {whenWasPosted?.howLongAgo}
              </time>
            </Link>
          </div>
        </div>

        <div className="single-comment__actions">
          <p className="">{highlightAtMentions(comment.commentText)}</p>

          <Button
            className={`profile-post__interactions--like ${
              hasUserLikedState ? "liked" : ""
            }`}
            variant="link"
            onClick={handleLike}
          >
            {hasUserLikedState ? <ActiveFire /> : <InactiveFire />}
            <span>
              {totalLikesNumberState > 0 ? totalLikesNumberState : ""}
            </span>
          </Button>
        </div>
      </div>
      {/* <Col xs={{ offset: 2, span: 10 }}>
        <CreateCommentForm setComments={comment.setComments} postId={comment.id} />
      </Col> */}
    </div>
  );
};
