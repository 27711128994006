import { Button } from "@/components";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import i18next from "i18next";
import { FC } from "react";
import { ReactComponent as BinIcon } from "../../../../../assets/bin.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/actions/plus.svg";
import { AiOutlineMinus as RemoveIcon } from "@react-icons/all-files/ai/AiOutlineMinus";

export const EditWithNoInitImage: FC<{
  name: string;
  profileCroppedImage: string;
  handleInputChange: any;
  setCurrentContentOfModal: any;
  submitCroppedImage: any;
}> = ({
  name,
  profileCroppedImage,
  handleInputChange,
  setCurrentContentOfModal,
  submitCroppedImage,
}) => {
  return (
    <div className="edit-profile__missing-initial">
      <div>
        <h3>{i18next.t("profile.edit.title")}</h3>
        <div className="edit-profile__missing-initial--wrapper profile__cropper-wrapper">
          <div className="edit-profile__missing-initial--base-logo">
            <BaseLogo
              logoSrc={profileCroppedImage}
              name={name}
              isPlaceHolder={false}
            />
          </div>

          <div className="profile__cropper-controls">
            <Button variant="" disabled>
              <BinIcon />
            </Button>

            <Button variant="" disabled>
              <RemoveIcon />
            </Button>

            <Button variant="" disabled>
              <PlusIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className="edit-profile__missing-initial--buttons">
        <label htmlFor="modal-profile-image-input">
          <p className="btn btn-secondary">Upload</p>
          <input
            accept="image/*"
            type="file"
            className="opacity-0 d-none"
            id="modal-profile-image-input"
            onChange={(e) => {
              handleInputChange(e);
              setCurrentContentOfModal("Edit image with initial image");
            }}
          />
        </label>

        <Button variant="primary" onClickAction={submitCroppedImage}>
          {i18next.t("buttons.save")}
        </Button>
      </div>
    </div>
  );
};
