import React, { memo } from "react";
import Badge from "react-bootstrap/Badge";
import { ReactComponent as BellIcon } from "../assets/bell.svg";
import { Notification } from "@/models/database/notifications/Notification.model";
import { archiveOrReadNotification } from "../helperFunctions/readNotification";
import { Button, ErrorFallback, Spinner } from "@/components";
import NotificationsActions from "../helperFunctions/notificationsActions";
import { showActionTypes } from "@/utils/constants/notificationVariables";

const SingleNotificationActions: React.FunctionComponent<{
  read: boolean;
  setRead: any;
  data: Notification;
  docId: string;
  dataObj: any; // THIS HOLD THE OBJECT OF A SINGLE TEAM, GATHER, CHALLENGE etc
  setLoading: any;
  loading: boolean;
}> = ({ read, setRead, data, docId, dataObj, setLoading, loading }) => {
  try {
    return (
      <div className="user-notifications">
        {!read &&
          !data.read &&
          ["Delete", "Join"].map((title) => (
            <Button
              key={title}
              variant=""
              customStyles={` ${
                title === "Join" ? "btn-primary" : "btn-secondary"
              }`}
              onClickAction={async () => {
                setLoading(true);

                await NotificationsActions(
                  data,
                  docId,
                  title as "Delete" | "Join",
                  showActionTypes[data.type].notificationRespType,
                  dataObj
                );
                if (title !== "Delete") {
                  // await archiveOrReadNotification();
                  // data.link(
                  //   notificationRoutes[data.type]
                  //     ? `/${notificationRoutes[data.type]}/${data.contentId}`
                  //     : ""
                  // );
                }
                setRead(true);
                setLoading(false);
              }}
            >
              {title}
            </Button>
          ))}
        {loading && <Spinner />}
      </div>
    );
  } catch {
    return <ErrorFallback></ErrorFallback>;
  }
};

export default memo(SingleNotificationActions);
