import React, { memo, FC } from "react";

import ChallengeGamePicker from "../inputComponents/ChallengeGamePicker";
import { CreateChallengeFormTypes } from "@/features/challenge/utils/CreateChallengeTypes";

const CreateChallengeStep0: FC<{
  values: CreateChallengeFormTypes;
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  errors: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({ values, setFieldValue, errors, setStep }): JSX.Element => {
  return (
    <ChallengeGamePicker
      label="Pick a game"
      name="game"
      value={values.game}
      setFieldValue={setFieldValue}
      errors={errors}
      setStep={setStep}
    />
  );
};

export default memo(CreateChallengeStep0);
