import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";
import { siteRoutes } from "../../../routes/siteRoutesList";

const NavigationBarAvatar: React.FunctionComponent<{
  styleProps: string;
  isPlaceholder?: boolean;
  closeWindow?: () => void;
  profile: Profile;
}> = ({ profile, closeWindow, styleProps, isPlaceholder }) => {
  const navigate = useNavigate();
  return (
    <Link
      to={`/profile`}
      className="header__user"
      onClick={() => {
        if (closeWindow) {
          closeWindow();
        }
        navigate(siteRoutes["profile"]);
      }}
    >
      <div className="user">
        <span className="user__info">
          <p className="user__nickname">{profile?.userName}</p>
          <p className="user__fullname">{profile?.fullName}</p>
        </span>
        <div className="user__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo
                name={profile?.userName}
                logoSrc={Query_shared.imageErrorHandler(
                  profile.profileLogoUrl?.includes("wog.imgix")
                    ? `${profile.profileLogoUrl}?${Query_Imgix.getImgixUrlProps(
                        {
                          w: "92",
                          h: "92",
                          fit: "crop",
                        }
                      )}`
                    : profile.profileLogoUrl,
                  false,
                  false
                )}
                isPlaceHolder={undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default memo(NavigationBarAvatar);
