import React from "react";

export const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <article className="legal">
      <header>
        <h1>Privacy Policy</h1>
      </header>
      <section>
        <h2>1. Data manager</h2>
        <h3>1.1. Purpose</h3>
        <p>
          As we treat personal information, we have adopted the following
          Privacy Policy, which describes how we handle information about you.
        </p>
        <p>
          In order to protect your personal data as much as possible, we
          constantly assess the risk of our data processing negatively affecting
          your fundamental rights. We are particularly aware of the risk of
          discrimination, identity theft, financial loss, loss of reputation and
          confidentiality of data.
        </p>
        <h3>1.2. Contact information</h3>
        <p>
          iPlay ApS as data manager ensures that your personal information is
          processed in accordance with the law.
        </p>
        <p>
          <strong className="d-block">iPlay ApS </strong>
          <span className="d-block">Hersegade 22, </span>
          <span className="d-block">DK-4000 Roskilde </span>
          <span className="d-block">VAT no: DK28889410 </span>
          <span className="d-block">Telephone: (+45) 53651565 </span>
          <span className="d-block">Email: info@iplay.dk </span>
          <span>Site: https://iplay.dk/</span>
        </p>
        <h3>1.3. We ensure fair and transparent data processing</h3>
        <p>
          If we ask you to submit your personal information to us, we will
          inform you about what information we process and to what purpose we
          process them. You can get information about this at the time of
          submitting personal data.
        </p>
        <p>
          In the case of us collecting data from a third party (e.g., partners,
          suppliers, or authorities), we will notify you within 14 days of
          receiving your personal information. We will also announce the purpose
          of the data collection and the legal basis that allows us to collect
          your personal information.
        </p>
      </section>
      <section>
        <h2>2. What data is collected</h2>
        <p>
          We collect information about you to improve our service, to ensure the
          quality of our products and services, and to enable us to communicate
          with you. The data collected includes:
        </p>
        <h3>2.1. Automatically collected data</h3>
        <p>
          Our digital solutions are based on a variety of technologies to ensure
          user-friendliness and security. These technologies are able to
          automatically collect data and provide the best possible solution
          either directly by us or by a third party on our behalf. An example of
          this is the analysis of websites clicking data and cookies.
        </p>
        <p>
          All visits to any digital solutions lead to information from your
          browser being sent to a server. For the optimization of our digital
          solutions, we analyze these data. Data is collected through third
          parties, on our behalf. From your computer, data can be collected for
          system management and internal marketing analysis. These data are
          statistical information on user behavior in digital solutions.
          Examples of collected and analyzed data:
        </p>
        <ul>
          <li>Date and time of visit.</li>
          <li>Visited pages in our digital solution.</li>
          <li>Visitors IP address.</li>
          <li>Geographical location.</li>
          <li>
            Information about the browser and computer you are using (type,
            version, operating system, etc.)
          </li>
          <li>
            Referral location URL (page from which the visitor reached our
            website)
          </li>
        </ul>
        <p>
          We use Google Analytics as a web analytics solution in our digital
          solutions. In this regard, the web analytics data collected by our
          digital solutions is sent to the service offered by Google Analytics.
          Google Analytics acts as an authorized data processor for data that we
          are data administrators. We are responsible for providing these data
          only by agreement and complying with legal requirements.
        </p>
        <p>
          Read more about the privacy policies we use:
          <a
            aria-hidden
            href="https://www.google.com/url?q=https://policies.google.com/privacy/update?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1669117961617368&amp;usg=AOvVaw0eXHFm8MuZ58Vhn830srVR"
          >
            {" "}
          </a>
          <a href="https://www.google.com/url?q=https://policies.google.com/privacy/update?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1669117961617731&amp;usg=AOvVaw0ujkrpeAWBIImeFZhbRloU">
            Google&rsquo;s Privacy Policy
          </a>
          .
        </p>
        <h3>2.2. Your submitted data</h3>
        <p>
          In addition to automatically collecting data, we also process data
          that our users are actively providing us with.
        </p>
        <p>The data that users are actively presenting are, for example:</p>
        <ul>
          <li>Information sent via social media</li>
          <li>Information sent via email</li>
          <li>Information we receive from you in a contact form</li>
          <li>Information shared at polls, events and shows</li>
          <li>etc.</li>
        </ul>
      </section>
      <section>
        <h2>3. Use and storage of collected data</h2>
        <h3>3.1. The purpose of the collection</h3>
        <p>Below are examples of using the information we collect:</p>
        <ul>
          <li>Sales follow-up.</li>
          <li>Communication regarding your booking or other inquiries.</li>
          <li>Making communication with us easy and safe for you.</li>
          <li>Continuous optimization of digital solutions.</li>
          <li>
            In some cases participation in customer surveys, competitions,
            give-aways and other digital solutions.
          </li>
          <li>
            Ability to target sales offers and campaigns based on user behavior
            history and demographic information with third-party solutions and
            data.
          </li>
        </ul>
        <h3>3.2. Storage</h3>
        <p>
          We will keep records of you if we have a legitimate and purposeful
          legal basis for this, including in order for us to be able to serve
          you as good as possible.
        </p>
        <h3>3.3. Disclosure of information</h3>
        <p>
          We will disclose customer information to third parties only in
          accordance with this Privacy Policy.
        </p>
        <p>
          We may disclose your information if we have to do so in order to
          comply with a legal obligation. Transmission may also take place at
          the discretion of the court or other authority or to protect
          trademarks, rights or property. This includes the exchange of
          information with other companies and organizations for anti-fraud
          protection.
        </p>
        <p>
          We use service providers and data processors who work on our behalf.
          Services may be, for example: web hosting and system maintenance,
          analyzes, payment solutions, email services, etc. These collaborators
          will be able to access the necessary data for the provision of
          services. The partners are contractually bound to treat all
          information with strict confidentiality and therefore the data may not
          be used for anything other than what is covered by the contractual
          obligation. We check that our partners are fulfilling their
          responsibilities. When we disclose your information to a service
          provider or reporting agent outside the European Union, we will ensure
          that we comply with the regulatory requirements for such transfers. We
          will never collect personal information unless you have given us
          permission when ordering, purchasing, or participating in surveys, and
          so on.
        </p>
      </section>
      <section>
        <h2>4. Cookies</h2>
        <h3>4.1. What are cookies?</h3>
        <p>
          Cookies are small text files that contain letters and numbers that are
          inserted into your computer or other device. Cookies will be entered
          when you visit a website that uses cookies and their purpose is to
          track for example what pages you have visited. Cookies will help you
          continue where you left off and they will remember your preferences
          (e.g., language settings). When we insert cookies, you will be
          informed about the use and purpose of cookies. Before the cookies are
          inserted on your device, we ask for your consent. However, the cookies
          required to ensure the functionality and settings can be used without
          your permission.
        </p>
        <h3>4.2. Cookies and their goals</h3>
        <p>We use the following cookie types:</p>
        <ul>
          <li>
            Personalized cookies: Use information to sort the content in order
            to display relevant content for you.
          </li>
          <li>
            We also use cookies from the following third parties who have access
            to personalized cookies:
            <ul>
              <li>
                <a href="https://www.google.com/url?q=https://www.google.com&amp;sa=D&amp;source=editors&amp;ust=1669117961621099&amp;usg=AOvVaw2Y79NsBaSekaLLWdr5g68m">
                  https://www.google.com
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <h3>4.3. Delete or disable cookies in your browser</h3>
        <p>
          By changing the browser settings, you can always delete or disable
          cookies on your computer. The location of your browser settings
          depends on which browser you are using. Remember, if you do so, there
          are many features and services on the Internet that you can no longer
          use.
        </p>
        <p>
          All browsers allow you to delete your cookies together or separately.
          How to do this depends on which browser you are using. Do not forget
          that if you use more than one browser, delete the cookies from all of
          them.
        </p>
        <p>
          <strong>Browser on the computer</strong>
        </p>
        <ul>
          <li>
            <a href="https://www.google.com/url?q=https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies%23ie%3Die-10&amp;sa=D&amp;source=editors&amp;ust=1669117961622150&amp;usg=AOvVaw32kLHwPGDz9Ltgk3xuGXgj">
              How to delete cookies in Microsoft Internet Explorer
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.microsoft.com/en-us/help/10607/microsoft-edge-view-delete-browser-history&amp;sa=D&amp;source=editors&amp;ust=1669117961622647&amp;usg=AOvVaw259VHRSFHiDDv0K0rLD0Hm">
              How to delete cookies in Microsoft Edge
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.mozilla.org/et/kb/delete-cookies-remove-info-websites-stored&amp;sa=D&amp;source=editors&amp;ust=1669117961623002&amp;usg=AOvVaw0_IsKnimNh6V56tgW82MAE">
              How to delete cookies in Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?co%3DGENIE.Platform%253DDesktop%26hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1669117961623397&amp;usg=AOvVaw06iG1c_DLqu4FMvQ7d36Ki">
              How to delete cookies in Google Chrome
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://www.opera.com/help/tutorials/security/privacy/&amp;sa=D&amp;source=editors&amp;ust=1669117961623700&amp;usg=AOvVaw0ZunVVEc52-rS8VZPLam2z">
              How to delete cookies in Opera
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://support.apple.com/kb/ph21411?locale%3Den_GB&amp;sa=D&amp;source=editors&amp;ust=1669117961623970&amp;usg=AOvVaw3gW_sd_BbfxGePr-PFcgjH">
              How to delete cookies in Apple Safari (OS X)?
            </a>
          </li>
        </ul>
        <p>
          <strong>Browser on the mobile</strong>
        </p>
        <ul>
          <li>
            <a href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&amp;sa=D&amp;source=editors&amp;ust=1669117961624272&amp;usg=AOvVaw0Ub4ocnBwAcU1rMbdABeLf">
              How to delete cookies in Apple Safari (iOS)
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://hubpages.com/technology/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device&amp;sa=D&amp;source=editors&amp;ust=1669117961624550&amp;usg=AOvVaw3-avlHQfcx1kmxViLOKZdn">
              How to delete cookies in Android phone
            </a>
          </li>
          <li>
            <a href="https://www.google.com/url?q=https://hubpages.com/technology/How-to-delete-internet-cookies-on-your-Droid-or-any-Android-device&amp;sa=D&amp;source=editors&amp;ust=1669117961625004&amp;usg=AOvVaw1o9Px-rPO94Eh744oyE6-P">
              How to delete cookies in Windows Phone 7?
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2>5. Security</h2>
        <p>
          We will protect your personal information and comply with our internal
          data protection rules.
        </p>
        <p>
          We have taken into use an Internal Data Protection Policy that
          contains guidelines and measures on how to protect your personal
          information in the event of destruction, loss, unauthorized disclosure
          and unauthorized access.
        </p>
        <p>
          We have created rules for regulating the control of our
          employees&rsquo; access to personal data that is sensitive and to data
          that shows information about personal interests and habits. We control
          their actual access by activity logs and monitoring.
        </p>
        <p>
          To avoid data loss, we will continuously back up our databases. We
          also protect the confidentiality and authenticity of your data by
          encryption. In the event of a security breach leading to a high level
          of discrimination risk, identity theft, financial loss, reputation or
          other significant inconvenience, we will notify you as soon as
          possible.
        </p>
      </section>
      <section>
        <h2>6. Your rights</h2>
        <h3>6.1. General</h3>
        <p>
          If you wish to access, modify or delete your data, or file a complaint
          against our data processing, we will investigate if / how this is
          possible and we will respond to you as soon as possible and no later
          than one month after your request has been sent.
        </p>
        <h3>6.2. You have the right to access your personal data</h3>
        <p>
          You have the right at any time to control what data we store about
          you, where it came from and for what purpose we use it. You can also
          check how long we will store your personal information and who will
          receive it in case we need to disclose your information.
        </p>
        <p>
          If you wish for it, we can give you access to your data that we
          process. However, access may be restricted to protect the privacy of
          others, business secrets and intellectual property rights.
        </p>
        <p>
          Contact us if you want to exercise your rights. Our contact details
          are at the top. 1.2.
        </p>
        <h3>
          6.3. You have the right to correct or erase inaccurate personal
          information.
        </h3>
        <p>
          If you believe that the personal data handled is inaccurate, you are
          entitled to correct it. In this case, you need to contact us to show
          which data is inaccurate and how we can fix it.
        </p>
        <p>
          In some cases, we are required to delete your personal information.
          This applies, for example, if you want to reject your consent. If you
          think your data is no longer necessary for your goals, you can request
          that it would be deleted. You can also contact us if you believe that
          your personal data will be processed against any laws or other legal
          obligations.
        </p>
        <p>
          When you call for a correction or deletion of your personal
          information, we will investigate if the conditions are met and then
          apply changes in this case or delete the data as soon as possible.
        </p>
        <h3>
          6.4. You have the right to object to the processing of your personal
          data.
        </h3>
        <p>
          You have the right to object to the processing of your personal data.
          You may also oppose to the disclosure of data for marketing purposes.
          You can use the contact information at the top to send the complaint.
          If your opposition is justified, we will stop processing your personal
          data.
        </p>
        <h3>
          6.5. In certain cases, you may be entitled to get your information
          delivered.
        </h3>
        <p>
          You have the right to receive your personal data that you have made
          available to us and collected from other parties with your consent. If
          we process information about you as part of the contract, you can also
          request your information. You also have the right to transfer these
          personal data to another service provider.
        </p>
        <p>
          If you want to exercise your right to transfer data, you will receive
          your personal information from us in a public format.
        </p>
      </section>
      <section>
        <h2>7. Version</h2>
        <p>This Privacy Policy was last changed on November 22nd, 2022.</p>
      </section>
    </article>
  );
};
