import React, { memo } from "react";
import { Link } from "react-router-dom";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_shared } from "@/api/shared";
import { Query_Imgix } from "@/api/imgix";

const SideMenuAvatar: React.FunctionComponent<{
  styleProps: string;
  isPlaceholder?: boolean;
  closeWindow?: () => void;
  profile: Profile;
}> = ({ profile, closeWindow, styleProps, isPlaceholder }) => {
  return (
    <Link
      to={`/profile/${profile.slug}`}
      className="offcanvas__user"
      onClick={closeWindow}
    >
      <div className="user">
        <span className="user__info">
          <p>{profile?.userName}</p>
          <p>{profile?.fullName}</p>
        </span>
        <div className="user__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo
                name={profile?.userName}
                logoSrc={Query_shared.imageErrorHandler(
                  profile.profileLogoUrl?.includes("wog.imgix")
                    ? `${profile.profileLogoUrl}?${Query_Imgix.getImgixUrlProps(
                        {
                          w: "92",
                          h: "92",
                          fit: "crop",
                        }
                      )}`
                    : profile.profileLogoUrl,
                  false,
                  false
                )}
                customStyles={` ${
                  isPlaceholder ? "placeholder placeholder-wave" : ""
                } `}
                isPlaceHolder={undefined}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SideMenuAvatar;
