import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import FeedFetcher from "../components/Feed.fetcher";

export default function FeedPage() {
  const currentUser = useCurrentUserProfile((s) => s.user);
  return (
    <div>
      <h2>Welcome back, {currentUser.userName.toLowerCase()}!</h2>
      <FeedFetcher />
    </div>
  );
}
