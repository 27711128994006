import { functions } from "@/api/firebase/firebase.config";
import {
  useGeneralStoreForCloudFunctionsForLists as _useGeneralStoreForCloudFunctionsForLists,
  useGeneralStoreForCloudFunctionsForListsPersisted,
  useGeneralStoreForLeaderboards,
} from "@/store/database/database.store";
import { useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

type Error = {
  message: string;
};

const useStoreHook = {
  true: useGeneralStoreForCloudFunctionsForListsPersisted,
  false: _useGeneralStoreForCloudFunctionsForLists,
};
/**
 * Usage: When you have to call a cloud function
 * @param cloud_function_name Name of the cloud function you are going to call
 * @returns error, loading state, data and fetch function, ref, _hasMore
 */
export default function useCloudFunctionCallForListsAndInfiniteScroll<
  T,
  FT,
  PropTypes = any | undefined
>(
  cloud_function_name: string,
  slug: string = "", // slug of profile -> could be own slug or other user slug
  type: FT, // inital filter value
  persist?: boolean
) {
  const [filterTypes, setFilterTypes] = useState<FT>(() => type);
  const rawKey = `${cloud_function_name}_${slug}`;
  const filterTypesKey = useMemo(() => {
    if (typeof filterTypes === "string") {
      return filterTypes;
    } else {
      return JSON.stringify(filterTypes);
    }
  }, [filterTypes]);
  const key = `${rawKey}_${filterTypesKey || ""}`;

  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  const useGeneralStoreForCloudFunctionsForLists = useMemo(() => {
    const persistKey = String(!!persist) as "true" | "false";
    if (cloud_function_name === "getLeaderBoardForChallengeId") {
      return useGeneralStoreForLeaderboards;
    }
    return useStoreHook[persistKey];
  }, [persist]);

  const data = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.data
  )[key] as T | undefined;
  console.log("data::", data);

  const hasMore = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.hasMore
  )[key] as boolean;
  console.log("key::", key, "has more:", hasMore);

  const lastFeedDateInSeconds = useGeneralStoreForCloudFunctionsForLists(
    (state: any) => state.lastFeedDateInSeconds
  )[key] as number | undefined;

  const dispatchData = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.dispatchData
  );

  const updateHasMore = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setHasMore
  );

  const updateLastVisibleDate = useGeneralStoreForCloudFunctionsForLists(
    (s: any) => s.setLastFeedDateInSeconds
  );

  const functionQuery = useMemo(
    () => functions(cloud_function_name),
    [cloud_function_name]
  );

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  /**
   *
   * @param params Ensure that all the necessary properties required by the cloud function are passed as arguments or parameters.
   * @param merge If set to 'true', the new upcoming data from the cloud function will be merged with the existing data. Use 'merge: true' in scenarios involving infinite scrolling, where you need to retain the previous data and incorporate the new data seamlessly.
   */
  let fetchTries = 0;
  async function fetch(params: PropTypes, merge?: boolean) {
    if (hasMore === false) {
      return;
    }
    setError(undefined);
    setLoading(true);

    try {
      console.log("Fetching data ...");

      const response = await functionQuery(params || {});
      const _data = response.data;

      console.log("Fetched Data::", _data);

      if (_data) {
        const dataKey = `${rawKey}_${_data.type}`;

        dispatchData(_data.dataArray, dataKey, merge);
        // set has more
        updateHasMore(dataKey, _data.hasMore);

        //last visible item number
        updateLastVisibleDate(dataKey, _data.lastFeedDateInSeconds);
      } else {
        if (fetchTries < 6) {
          // 5 tries to fetch the data again
          setTimeout(() => {
            //retrying fetching.
            fetch(params, merge);
            fetchTries++;
          }, 5000);
        }
      }
    } catch (error: any) {
      console.log("ERROR OCCUR:", error.message);

      setLoading(false);
      setError({
        message: error,
      });
    }

    setLoading(false);
  }
  return [
    data,
    lastFeedDateInSeconds,
    hasMore,
    loading,
    error,
    fetch,
    ref,
    inView,
    [filterTypes, setFilterTypes],
  ] as const;
}
