import React, { memo, FC } from "react";
import { AiOutlineInfoCircle as InfoIcon } from "@react-icons/all-files/ai/AiOutlineInfoCircle";
import BaseTooltipWrapper from "@/components/BaseWrappers/BaseTooltipWrapper";

const SingleChallengeAdditionalInfoWithTooltip: FC<{
  title: string;
  value: string;
}> = ({ title, value }): JSX.Element => {
  return (
    <BaseTooltipWrapper
      overlayTrigger={
        <div className="challenge__additional">
          <div className="challenge__tooltip">
            <h5>{title}: </h5>
            <span>{value}</span>
            <InfoIcon color="var(--text-clr-muted)" />
          </div>
        </div>
      }
      tooltipContent={<></>}
    />
  );
};

export default memo(SingleChallengeAdditionalInfoWithTooltip);
