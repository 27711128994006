import React, { memo } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import AuthProcessBackground from "../components/AuthProcessBackground";
import AuthProcessAgreementText from "../components/AuthProcessAgreementText";

const SignUp3: React.FunctionComponent = () => {
  const clearStorageValues = () => {
    localStorage.removeItem("signUpMethod");
  };
  return (
    <AuthProcessBackground>
      <div className="sign_up--step-four">
        <h4 className=" sign-up__form--step">
          {i18next.t("strings.step")} 3 {i18next.t("strings.of")} 3
        </h4>
        <h1 className="sign-up__form--margin">{i18next.t("auth.all_set")}</h1>
        <h4>{i18next.t("auth.start_info")}</h4>
        <AuthProcessAgreementText />
        <div className="sign_up--step-four--buttons">
          <Link
            className="sign_up--step-four--buttons-connect"
            to="settings"
            state={{ from: "sign-up-step-4" }}
            onClick={clearStorageValues}
          >
            {i18next.t("buttons.connect_accounts")}
          </Link>
          <Link
            className="sign_up--step-four--buttons-explore"
            to="/challenges"
            onClick={clearStorageValues}
          >
            {i18next.t("buttons.explore_challenges")}
          </Link>
          {/* <br /> */}
          <Link
            className="sign_up--step-four--buttons-skip"
            to="/"
            onClick={clearStorageValues}
          >
            {i18next.t("buttons.skip_to_home")}
          </Link>
        </div>
      </div>
    </AuthProcessBackground>
  );
};

export default memo(SignUp3);
