const mockUsernames = [
  "PixelWarrior01",
  "StealthMaster02",
  "GameGenius03",
  "EliteGamer04",
  "CyberNinja05",
  "CtrlAltDefeat06",
  "VictoryChaser07",
  "DigitalDreamer08",
  "NoobSlayer09",
  "MythicMarauder10",
  "KeyboardKnight11",
  "JoystickJuggernaut12",
  "HeadshotHero13",
  "QuantumGamer14",
  "ShadowStriker15",
  "PowerPlayer16",
  "InfiniteGamer17",
  "EpicAdventurer18",
  "MegaBoss19",
  "ChampionController20",
  "CosmicCommander21",
  "VirtualViking22",
  "WarpSpeedWizard23",
  "TimeTraveler24",
  "AstroAvenger25",
  "GalacticGladiator26",
  "RogueRacer27",
  "Mastermind28",
  "SupersonicStrategist29",
  "PhantomFighter30",
  "RapidResponder31",
  "PixelPirate32",
  "MysticMage33",
  "WanderingWarrior34",
  "CosmicCrusader35",
  "DigitalDuelist36",
  "RoyalRaider37",
  "JadeJester38",
  "SapphireSorcerer39",
  "RubyRogue40",
  "EmeraldEnchanter41",
  "TopazTitan42",
  "AmethystAssassin43",
  "OpalOverlord44",
  "TurquoiseTactician45",
  "MoonstoneMage46",
  "GarnetGuardian47",
  "PeridotPaladin48",
  "AquamarineArcher49",
  "SpinelSentry50",
  "ZirconZealot51",
  "TanzaniteTyrant52",
  "LapisLancer53",
  "CitrineCenturion54",
  "QuartzQuester55",
  "OnyxOutlaw56",
  "JasperJuggler57",
  "AmberAmbusher58",
  "TourmalineTactician59",
  "SunstoneSwordsman60",
  "MalachiteMarksman61",
  "AlexandriteAvenger62",
  "KunziteKnight63",
  "MorganiteMarauder64",
  "SodaliteSlayer65",
  "ObsidianOverseer66",
  "IoliteInfiltrator67",
  "ChalcedonyChampion68",
  "ScapoliteSorcerer69",
  "FluoriteFighter70",
  "AndalusiteAssassin71",
  "AxiniteAdventurer72",
  "DanburiteDuelist73",
  "TremoliteTitan74",
  "SpheneSpecter75",
  "GrandidieriteGamer76",
  "TaaffeiteTactician77",
  "PoudretteitePirate78",
  "JeremejeviteJester79",
  "PainitePaladin80",
  "BenitoiteBoss81",
  "RedBerylRaider82",
  "MusgraviteMage83",
  "SerendibiteSorcerer84",
  "HauyneHero85",
  "BixbiteBattler86",
  "EuclaseElite87",
  "ZektzeriteZealot88",
  "SinhaliteStrategist89",
  "CalderiteCommander90",
  "PezzottaitePower91",
  "HiboniteHunter92",
  "GrandidieriteGuardian93",
  "TaafeiteTyrant94",
  "PoudretteitePlayer95",
  "JeremejeviteJuggernaut96",
  "PainiteProtector97",
  "BenitoiteBrawler98",
  "RedBerylRebel99",
  "MusgraviteMaster100",
  "SerendibiteSlayer101",
  "HauyneHuntsman102",
  "BixbiteBattalion103",
  "EuclaseEnforcer104",
  "ZektzeriteZapper105",
  "SinhaliteSniper106",
  "CalderiteCrusher107",
  "PezzottaiteProwler108",
  "HiboniteHacker109",
  "GrandidieriteGatherer110",
  "TaafeiteTamer111",
  "PoudretteitePunisher112",
  "JeremejeviteJedi113",
  "PainiteParagon114",
  "BenitoiteBuccaneer115",
  "RedBerylRipper116",
  "MusgraviteMystic117",
  "SerendibiteSentry118",
  "HauyneHarbinger119",
  "BixbiteBerserker120",
  "EuclaseEmissary121",
  "ZektzeriteZeus122",
  "SinhaliteSphinx123",
  "CalderiteCataclysm124",
  "PezzottaitePanther125",
  "HiboniteHerald126",
  "GrandidieriteGryphon127",
  "TaafeiteTitan128",
  "PoudretteitePhoenix129",
  "JeremejeviteJoker130",
  "PainitePegasus131",
  "BenitoiteBasilisk132",
  "RedBerylReaper133",
  "MusgraviteMinotaur134",
  "SerendibiteSalamander135",
  "HauyneHydra136",
  "BixbiteBanshee137",
  "EuclaseElemental138",
  "ZektzeriteZombie139",
  "SinhaliteSkeleton140",
  "CalderiteCentaur141",
  "PezzottaitePandora142",
  "HiboniteHarpy143",
  "GrandidieriteGoblin144",
  "TaafeiteTroll145",
  "PoudretteitePoltergeist146",
  "JeremejeviteJinn147",
  "PainitePixie148",
  "BenitoiteBehemoth149",
  "RedBerylRevenant150",
  "MusgraviteManticore151",
  "SerendibiteSiren152",
  "HauyneHellhound153",
  "BixbiteBarghest154",
  "EuclaseEttin155",
  "ZektzeriteWraith156",
  "SinhaliteSpectre157",
  "CalderiteChimera158",
  "PezzottaitePeryton159",
  "HiboniteHippogriff160",
  "GrandidieriteGorgon161",
  "TaafeiteTengu162",
  "PoudretteitePhoenix163",
  "JeremejeviteJuggler164",
  "PainitePirate165",
  "BenitoiteBattlemage166",
  "RedBerylRanger167",
  "MusgraviteMonk168",
  "SerendibiteSellsword169",
  "HauyneHealer170",
  "BixbiteBlacksmith171",
  "EuclaseEngineer172",
  "ZektzerzeriteZeppelin173",
  "SinhaliteSamurai174",
  "CalderiteCavalry175",
  "PezzottaitePaladin176",
  "HiboniteHunter177",
  "GrandidieriteGrenadier178",
  "TaafeiteTactician179",
  "PoudretteiteParatrooper180",
  "JeremejeviteJester181",
  "PainitePilot182",
  "BenitoiteBerserker183",
  "RedBerylBomber184",
  "MusgraviteMusketeer185",
  "SerendibiteSniper186",
  "HauyneHeavy187",
  "BixbiteBlaster188",
  "EuclaseElite189",
  "ZektzeriteZephyr190",
  "SinhaliteSapper191",
  "CalderiteCommando192",
  "PezzottaitePyro193",
  "HiboniteHeavy194",
  "GrandidieriteGunner195",
  "TaafeiteTechnician196",
  "PoudretteitePathfinder197",
  "JeremejeviteJuggler198",
  "PainitePriest199",
  "BenitoiteBard200",
  "RedBerylRogue201",
  "MusgraviteMage202",
  "SerendibiteSorcerer203",
  "HauyneHunter204",
  "BixbiteBishop205",
  "EuclaseEnchanter206",
  "ZektzeriteZealot207",
  "SinhaliteSummoner208",
  "CalderiteCleric209",
  "PezzottaiteProtector210",
  "HiboniteHealer211",
  "GrandidieriteGuardian212",
  "TaafeiteTemplar213",
  "PoudretteitePaladin214",
  "JeremejeviteJedi215",
  "PainitePugilist216",
  "BenitoiteBrawler217",
  "RedBerylBattler218",
  "MusgraviteMonk219",
  "SerendibiteStriker220",
  "HauyneHeavy221",
  "BixbiteBoxer222",
  "EuclaseElite223",
  "ZektzeriteZapper224",
  "SinhaliteSlammer225",
  "CalderiteCrusher226",
  "PezzottaitePuncher227",
  "HiboniteHitter228",
  "GrandidieriteGrinder229",
  "TaafeiteTitan230",
  "PoudretteitePower231",
  "JeremejeviteJuggernaut232",
  "PainitePounder233",
  "BenitoiteBruiser234",
  "RedBerylBasher235",
  "MusgraviteMasher236",
  "SerendibiteSmasher237",
  "HauyneHammer238",
  "BixbiteBludgeoner239",
  "EuclaseEnforcer240",
  "ZektzeriteZealot241",
  "SinhaliteSlayer242",
  "CalderiteClobberer243",
  "PezzottaitePulverizer244",
  "HiboniteHavoc245",
  "GrandidieriteGorger246",
  "TaafeiteThrasher247",
  "PoudretteitePummeler248",
  "JeremejeviteJuggler249",
  "PainitePunisher250",
  "BenitoiteBane251",
  "RedBerylRavager252",
  "MusgraviteMauler253",
  "SerendibiteSavage254",
  "HauyneHulk255",
  "BixbiteBulldozer256",
  "EuclaseExecutioner257",
  "ZektzeriteZodiac258",
  "SinhaliteStomper259",
  "CalderiteCrusher260",
  "PezzottaitePaintrain261",
  "HiboniteHeavy262",
  "GrandidieriteGamer263",
  "TaafeiteTornado264",
  "PoudretteiteProwler265",
  "JeremejeviteJackal266",
  "PainitePanther267",
  "BenitoiteBengal268",
  "RedBerylRaptor269",
  "MusgraviteMongoose270",
  "SerendibiteSerpent271",
  "HauyneHyena272",
  "BixbiteBadger273",
  "EuclaseEagle274",
  "ZektzeriteZebra275",
  "SinhaliteStallion276",
  "CalderiteCobra277",
  "PezzottaitePython278",
  "HiboniteHawk279",
  "GrandidieriteGrizzly280",
  "TaafeiteTiger281",
  "PoudretteitePuma282",
  "JeremejeviteJaguar283",
  "PainitePiranha284",
  "BenitoiteBarracuda285",
  "RedBerylBison286",
  "MusgraviteMammoth287",
  "SerendibiteShark288",
  "HauyneHornet289",
  "BixbiteBumblebee290",
  "EuclaseElephant291",
  "ZektzeriteZorse292",
  "SinhaliteScorpion293",
  "CalderiteCheetah294",
  "PezzottaitePangolin295",
  "HiboniteHippopotamus296",
  "GrandidieriteGazelle297",
  "TaafeiteToucan298",
  "PoudretteitePorcupine299",
  "JeremejeviteJellyfish300",
  "PainitePlatypus301",
  "BenitoiteButterfly302",
  "RedBerylBuffalo303",
  "MusgraviteMeerkat304",
  "SerendibiteSeagull305",
  "HauyneHummingbird306",
  "BixbiteBaboon307",
  "EuclaseEchidna308",
  "ZektzeriteZebu309",
  "SinhaliteSloth310",
  "CalderiteChameleon311",
  "PezzottaitePeacock312",
  "HiboniteHedgehog313",
  "GrandidieriteGiraffe314",
  "TaafeiteTarsier315",
  "PoudretteitePenguin316",
  "JeremejeviteJackrabbit317",
  "PainitePelican318",
  "BenitoiteBinturong319",
  "RedBerylRaccoon320",
  "MusgraviteMandrill321",
  "SerendibiteSquirrel322",
  "HauyneHare323",
  "BixbiteBeaver324",
  "EuclaseErmine325",
  "ZektzeriteZorilla326",
  "SinhaliteSailfish327",
  "CalderiteCaracal328",
  "PezzottaitePuffin329",
  "HiboniteHeron330",
  "GrandidieriteGopher331",
  "TaafeiteTapir332",
  "PoudretteitePorpoise333",
  "JeremejeviteJellyfish334",
  "PainitePiranha335",
  "BenitoiteBarracuda336",
  "RedBerylBison337",
  "MusgraviteMammoth338",
  "SerendibiteShark339",
  "HauyneHornet340",
  "BixbiteBumblebee341",
  "EuclaseElephant342",
  "ZektzeriteZorse343",
  "SinhaliteScorpion344",
  "CalderiteCheetah345",
  "PezzottaitePangolin346",
  "HiboniteHippopotamus347",
  "GrandidieriteGazelle348",
  "TaafeiteToucan349",
  "PoudretteitePorcupine350",
  "JeremejeviteJellyfish351",
  "PainitePlatypus352",
  "BenitoiteButterfly353",
  "RedBerylBuffalo354",
  "MusgraviteMeerkat355",
  "SerendibiteSeagull356",
  "HauyneHummingbird357",
  "BixbiteBaboon358",
  "EuclaseEchidna359",
  "ZektzeriteZebu360",
  "SinhaliteSloth361",
  "CalderiteChameleon362",
  "PezzottaitePeacock363",
  "HiboniteHedgehog364",
  "GrandidieriteGiraffe365",
  "TaafeiteTarsier366",
  "PoudretteitePenguin367",
  "JeremejeviteJackrabbit368",
  "PainitePelican369",
  "BenitoiteBinturong370",
  "RedBerylRaccoon371",
  "MusgraviteMandrill372",
  "SerendibiteSquirrel373",
  "HauyneHare374",
  "BixbiteBeaver375",
  "EuclaseErmine376",
  "ZektzeriteZorilla377",
  "SinhaliteSailfish378",
  "CalderiteCaracal379",
  "PezzottaitePuffin380",
  "HiboniteHeron381",
  "GrandidieriteGopher382",
  "TaafeiteTapir383",
  "PoudretteitePuma384",
  "JeremejeviteJaguar385",
  "PainitePiranha386",
  "BenitoiteBengal387",
  "RedBerylRaptor388",
  "MusgraviteMongoose389",
  "SerendibiteSerpent390",
  "HauyneHyena391",
  "BixbiteBadger392",
  "EuclaseEagle393",
  "ZektzeriteZebra394",
  "SinhaliteStallion395",
  "CalderiteCobra396",
  "PezzottaitePython397",
  "HiboniteHawk398",
  "GrandidieriteGrizzly399",
  "TaafeiteTiger400",
  "PoudretteitePuma401",
  "JeremejeviteJaguar402",
  "PainitePiranha403",
  "BenitoiteBarracuda404",
  "RedBerylBison405",
  "MusgraviteMeerkat406",
  "SerendibiteShark407",
  "HauyneHornet408",
  "BixbiteBumblebee409",
  "EuclaseElephant410",
  "ZektzeriteZorse411",
  "SinhaliteScorpion412",
  "CalderiteCheetah413",
  "PezzottaitePangolin414",
  "HiboniteHippopotamus415",
  "GrandidieriteGazelle416",
  "TaafeiteToucan417",
  "PoudretteitePorcupine418",
  "JeremejeviteJellyfish419",
  "PainitePlatypus420",
  "BenitoiteButterfly421",
  "RedBerylBuffalo422",
  "MusgraviteMeerkat423",
  "SerendibiteSeagull424",
  "HauyneHummingbird425",
  "BixbiteBaboon426",
  "EuclaseEchidna427",
  "ZektzeriteZebu428",
  "SinhaliteSloth429",
  "CalderiteChameleon430",
  "PezzottaitePeacock431",
  "HiboniteHedgehog432",
  "GrandidieriteGiraffe433",
  "TaafeiteTarsier434",
  "PoudretteitePenguin435",
  "JeremejeviteJackrabbit436",
  "PainitePelican437",
  "BenitoiteBinturong438",
  "RedBerylRaccoon439",
  "MusgraviteMandrill440",
  "SerendibiteSquirrel441",
  "HauyneHare442",
  "BixbiteBeaver443",
  "EuclaseErmine444",
  "ZektzeriteZorilla445",
  "SinhaliteSailfish446",
  "CalderiteCaracal447",
  "PezzottaitePuffin448",
  "HiboniteHeron449",
  "GrandidieriteGopher450",
  "TaafeiteTapir451",
  "PoudretteitePuma452",
  "JeremejeviteJaguar453",
  "PainitePiranha454",
  "BenitoiteBengal455",
  "RedBerylRaptor456",
  "MusgraviteMongoose457",
  "SerendibiteSerpent458",
  "HauyneHyena459",
  "BixbiteBadger460",
  "EuclaseEagle461",
  "ZektzeriteZebra462",
  "SinhaliteStallion463",
  "CalderiteCobra464",
  "PezzottaitePython465",
  "HiboniteHawk466",
  "GrandidieriteGrizzly467",
  "TaafeiteTiger468",
  "PoudretteitePuma469",
  "JeremejeviteJaguar470",
  "PainitePiranha471",
  "BenitoiteBarracuda472",
  "RedBerylBison473",
  "MusgraviteMeerkat474",
  "SerendibiteShark475",
  "HauyneHornet476",
  "BixbiteBumblebee477",
  "EuclaseElephant478",
  "ZektzeriteZorse479",
  "SinhaliteScorpion480",
  "CalderiteCheetah481",
  "PezzottaitePangolin482",
  "HiboniteHippopotamus483",
  "GrandidieriteGazelle484",
  "TaafeiteToucan485",
  "PoudretteitePorcupine486",
  "JeremejeviteJellyfish487",
  "PainitePlatypus488",
  "BenitoiteButterfly489",
  "RedBerylBuffalo490",
  "MusgraviteMeerkat491",
  "SerendibiteSeagull492",
  "HauyneHummingbird493",
  "BixbiteBaboon494",
  "EuclaseEchidna495",
  "ZektzeriteZebu496",
  "SinhaliteSloth497",
  "CalderiteChameleon498",
  "PezzottaitePeacock499",
  "HiboniteHedgehog500",
];

export default mockUsernames;
