import { FC } from "react";
import ChallengeCardComponent from "./ChallengeCardComponent";
import { Timestamp } from "@/api/firebase/firebase.config";

type Props = {
  name: string;
  slug: string;
  goal: string;
  game: string;
  logoUrl: string;
  createdBy: string;
  creatorType: string;
  startDate: Timestamp;
  endDate: Timestamp;
  created: Timestamp;
  fieldToCompare: string[];
};

export const ChallengeCard: FC<Props> = (props): JSX.Element => {
  return (
    <>
      <ChallengeCardComponent
        key={props.slug}
        slug={props.slug}
        text={props.goal ?? "No goal provided."}
        name={props.name}
        game={props.game}
        logo={props.logoUrl}
        ctreatedBy={props.createdBy}
        creatorType={props.creatorType}
        startDate={props.startDate}
        endDate={props.endDate}
        // isCurrUserParticipant={hit.isCurrUserParticipant}
        // isInFavoritesOfCurrentUser={hit.isInCurrUserFavorites}
        // isPlaceholder={false}
        // progressBarLabel={getSingleChallengeDateDescription(
        //   hit.startDate ? hit.startDate._seconds : undefined,
        //   hit.endDate ? hit.endDate._seconds : undefined
        // )}
        // progressBarValue={getProgressBarValue(
        //   hit.startDate ? hit.startDate._seconds : undefined,
        //   hit.endDate ? hit.endDate._seconds : undefined
        // )}
        fieldToCompare={props.fieldToCompare}
        author={undefined}
        // selectedStatus={selectedStatus}
        // author={hit.author}
      />
    </>
  );
};
