import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import placeholderImage from "@/assets/placeholder/challenge_placeholder.png";
import { Query_shared } from "@/api/shared/index";
import { Query_Imgix } from "@/api/imgix/index";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { TeamOverviewCardProps } from "@/features/team/types/TeamTypes";
import { FavoriteButton } from "@/components/index";

export const TeamCardComponent: FC<TeamOverviewCardProps> = (
  props
): JSX.Element => {
  const [favoriteVisible, setFavoriteVisible] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);
  //   const totalMembers =
  //     props.memberCountCalculated - (4 - props.firstFourParticipantsLogos.length);
  return (
    <>
      <div
        onMouseEnter={() => {
          setFavoriteVisible(true);
        }}
        onMouseLeave={() => {
          setFavoriteVisible(false);
        }}
      >
        <div className="team-card">
          <Link to={`/teams/${props.slug}`}>
            <div className="team-card__image">
              <figure className="adaptive">
                <picture className="adaptive-photo">
                  <img src={props.teamLogo} alt="" />
                </picture>
              </figure>
              <div
                className={`team-card__favorite 
              ${favoriteVisible || props.isFavorite ? "" : "hidden"}
              `}
              >
                <Link to={window.location.pathname}>
                  {/* <FavoriteButton
                    slug={props.slug}
                    isInFavoritesOfCurrentUser={props.isFavorite}
                    featureType="team"
                  /> */}
                </Link>
              </div>
              <div className="team-card__heading">
                <h5 title={props.name}>{props.name}</h5>
              </div>
            </div>
            <div className="team-card__meta">
              <ul className="team-card__meta-members">
                {props.firstFourParticipantsLogos.map((user) => {
                  return (
                    <li key={user.slug}>
                      <img
                        className="team-card__logo"
                        width={20}
                        height={20}
                        src={Query_shared.imageErrorHandler(
                          props.teamLogo?.includes("wog.imgix")
                            ? `${props.teamLogo}?${Query_Imgix.getImgixUrlProps(
                                {
                                  w: "20",
                                  h: "20",
                                }
                              )}`
                            : props.teamLogo,
                          logoError,
                          false
                        )}
                        onError={() => setLogoError(true)}
                      />

                      {user.logoUrl === "" && (
                        <BaseLogo name={user.slug} isPlaceHolder={undefined} />
                      )}
                    </li>
                  );
                })}
                {/* {mockImages.length > 4 &&
                  !props.isPlaceholder &&
                  totalMembers > 0 && (
                    <li className="team-card__members" key="memberss">
                      +{totalMembers}
                    </li>
                  )} */}
              </ul>

              <ul className="team-card__badges">
                {props.games &&
                  props.games?.length !== 0 &&
                  props.games.map((field) => {
                    return (
                      <li key={field}>
                        <p>{field}</p>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
