import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutesAuth from "../src/pages/routes/ProtectedRoutesAuth";
import { loggedInRoutes } from "./routes/loggedInRoutes";
import ProtectedRoutesProfile from "../src/pages/routes/ProtectedRoutesProfile";
import { Landing } from "../src/features/landing";
import brand from "./utils/brandVariables/brandVariables";
import { PageDoesntExist } from "../src/pageDoesntExist";
import { EventQrCode } from "./features/eventQrSignUp/EventsQr";
import { ScannedQR } from "./features/eventQrSignUp/ScannedQR";
import { siteRoutes } from "./routes/siteRoutesList";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutesAuth />, //for sign-up-1
    errorElement: <h1>Error occur</h1>,
    children: [
      {
        path: "/",
        element: <ProtectedRoutesProfile />, // for sign-up-2
        errorElement: <h1>Error occur</h1>,
        children: loggedInRoutes.map((route) => {
          const Element = route.element;
          const Fallback = FallBackLoader;
          return {
            path: route.path,
            element: (
              <Suspense fallback={<Fallback />}>
                <Element />
              </Suspense>
            ),
          };
        }),
      },
    ],
  },
  {
    path: "/landing",
    element: brand.hasFeature.landing ? <Landing /> : <PageDoesntExist />, //for landing page
    errorElement: <h1>Error occur</h1>,
  },
  {
    path: "/",
    element: brand.hasFeature.landing ? <Landing /> : <PageDoesntExist />, //for landing page
    errorElement: <h1>Error occur</h1>,
  },
  {
    path: siteRoutes["eventQrCode"],
    element: <EventQrCode />,
    errorElement: <h1>Error occur</h1>,
  },
  {
    path: `${siteRoutes["scanQrCodeAndSlug"]}:pcId`,
    element: <ScannedQR />,
    errorElement: <h1>Error occur</h1>,
  },
  {
    path: "/login",
    element: brand.hasFeature.landing ? (
      <PageDoesntExist />
    ) : (
      <PageDoesntExist />
    ), //for landing page
    errorElement: <h1>Error occur</h1>,
  },
]);

function FallBackLoader() {
  return <h1>fallback...</h1>;
}
