import { Button } from "@/components";
import { ChallengeCard } from "@/features/challenge";
import { FeedTypeAndComponent } from "@/features/feed";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import brand from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ProfileTabDataMobileFetcher: FC<{}> = ({}) => {
  const { slug } = useParams();
  const [exists, setExists] = useState<boolean>(true);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const navigate = useNavigate();
  const accessibleTypes = [
    {
      name: "challenges",
      gridStyles: "challenges-list",
      featureAvailable: brand.hasFeature.challenges,
    },
    {
      name: "gathers",
      gridStyles: "gathers-overview",
      featureAvailable: brand.hasFeature.gathers,
    },
    {
      name: "teams",
      gridStyles: "teams-overview",
      featureAvailable: brand.hasFeature.teams,
    },
    {
      name: "achievements",
      gridStyles: "",
    },
  ];
  const currentActiveType = accessibleTypes.find((s) => s.name === slug);

  const [
    data, // this feed will be either posts/challenges/gathers/teams,etc based on feedType
    last,
    hasMore,
    _loading,
    error,
    fetchData,
    ref,
    inView,
    filters,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    any[] | undefined,
    FeedType
  >(
    "getUserFeedBasedOnSlug",
    currentUser.slug,
    slug as "posts" | "challenges" | "gathers" | "teams" | "achievements", // future task: should move the local storage state inside the hook itself
    true // to persist the data in the local storage.
  );

  const [feedType, setFeedType] = filters;

  const params: GetUserFeedBasedOnSlugProps = {
    slug: currentUser.slug,
    lastFeedDateInSeconds: last || undefined,
    type: slug as "posts" | "challenges" | "gathers" | "teams" | "achievements",
  };

  const ElementToRender = useMemo(() => {
    return FeedTypeAndComponent[slug];
  }, [slug]);

  useEffect(() => {
    if (slug && currentActiveType) {
      if (currentActiveType && !currentActiveType.featureAvailable) {
        return setExists(false);
      }
      (async () => {
        fetchData({
          slug: currentUser.slug,
          lastFeedDateInSeconds: undefined,
          type: slug,
        });
      })();

      return setExists(true);
    } else {
      return setExists(false);
    }
  }, [slug]);

  const fetchMore = async () => {
    await fetchData(params, true);
  };

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  const inViewRefElementIndex = useMemo(() => {
    return data ? data?.length - 4 : -1;
  }, [data, hasMore]);

  return (
    <>
      {exists && (
        <div className="profile-mobile">
          <div className="profile-mobile__header">
            <h2>Your {slug}</h2>
            <Button
              variant="primary"
              onClickAction={() => navigate(siteRoutes["profile"])}
            >
              Back
            </Button>
          </div>

          <div
            className={`profile-mobile__grid ${currentActiveType?.gridStyles}`}
          >
            {(data || []).map((dataObj, ind) => {
              const key = dataObj.slug;

              return (
                <div key={key} ref={ind === inViewRefElementIndex ? ref : null}>
                  {/* Render cards */}
                  <ElementToRender props={{ ...dataObj, error }} />;
                </div>
              );
            })}
            {error && (
              <>
                <h2>Error: Failed to fetch more data!</h2>
              </>
            )}
          </div>
        </div>
      )}
      {!exists && (
        <>
          <h2>Nothing found related to {slug}</h2>
          <Button
            variant="primary"
            onClickAction={() => navigate(siteRoutes["profile"])}
          >
            Back to your profile
          </Button>
        </>
      )}
    </>
  );
};
