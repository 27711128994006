import React, { useState } from "react";
import { ErrorFallback, Spinner } from "../../../../src/components";
import { WinnerListItem } from "./WinnerListItem";
export const WinnersList: React.FC<{
  buttonNames: any;
  loading: boolean;
  error: any;
  data: any;
  availableHours: any;
}> = ({ buttonNames, loading, error, data, availableHours }) => {
  const [isActive, setIsActive] = useState(buttonNames[0]);

  return (
    <div className="tabs">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <ErrorFallback>Failed to load winners</ErrorFallback>
          ) : (
            <>
              <div className="tabs__tab">
                {/* Looping over buttonNames array to create tab buttons */}
                {buttonNames.map((day: any) => (
                  <button
                    className={`tabs__btn ${
                      isActive === day ? "tabs__btn--active" : ""
                    }`}
                    onClick={() => setIsActive(day)}
                  >
                    {day}
                  </button>
                ))}
              </div>
              <section className="tabs__item">
                <ul>
                  {data &&
                    data.filter((doc: any) => doc.day === isActive).length !==
                      0 &&
                    availableHours &&
                    availableHours.map((hour: any) => {
                      return (
                        <div className="item">
                          {data.filter(
                            (doc: any) =>
                              doc.startHour === hour && doc.day === isActive
                          ).length !== 0 && (
                            <div className="item__times">
                              <p>{hour}.00</p>
                              <span className="item__divider" />
                              <p>{hour + 1}.00</p>
                            </div>
                          )}
                          <div className="item__winners">
                            {data.map((doc: any) => {
                              if (
                                doc.day === isActive &&
                                doc.startHour === hour
                              ) {
                                return <WinnerListItem data={doc} />;
                              }
                            })}
                          </div>
                        </div>
                      );
                    })}
                </ul>
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
};
