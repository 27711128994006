// THIS FOLDER SHOULD ONLY CONTAIN HELPERFUNCTIONS THAT ARE USED ACROSS DIFFERENT FEATURES
// SUCH AS FOLLOWING/UNFOLLOWING A USER IS USED IN DIFFERENT FEATURES
import QueryDB_Notifications from "./../../api/queryDB/notifications/index";
import QueryDB from "./../../api/queryDB/common/index";
import { createToast } from "../toaster/createToast";
import QueryDBProfiles from "@/api/queryDB/profiles";
import { Profile } from "@/models/database/profiles/profile.model";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "../constants/stringProcessing";

// Function to update wether you are following someone or not, this will return true or false based on the previous condition

export const handleFollow = async (
  isFollower: boolean | undefined,
  setFollower: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  userToFollowUid: string,
  currentUserUid: string
) => {
  if (isFollower === undefined) {
    createToast({
      type: "error",
      message: "Oops, Something went wrong",
    });
    return false;
  }

  if (isFollower) {
    setFollower(false);
    // setCreatorFollowers((prev) => prev - 1);
    await QueryDB.deleteJunction(
      "usersFollowers",
      `${userToFollowUid}`,
      "followers",
      `${currentUserUid}`
    ).then(() => {
      createToast({
        type: "info",
        message: "No longer following challenge creator",
      });
    });
  } else {
    setFollower(true);
    // setCreatorFollowers((prev) => prev + 1);
    await QueryDB.createJunction(
      "usersFollowers",
      `${userToFollowUid}`,
      "followers",
      `${currentUserUid}`
    ).then(() => {
      createToast({
        type: "success",
        message: "Following challenge creator",
      });
    });
    await QueryDB_Notifications.createNewNotification(
      `${userToFollowUid}`,
      `${currentUserUid}`,
      `${userToFollowUid}`,
      "friends-following",
      // notificationsTextMapping["friends-following"]
      ""
    );
  }
};

// Update the user data that is available in the useCurrentUserProfile hook

// setUserProfile = useCurrentUserProfile((s) => s.setUser);
// currentUser = useCurrentUserProfile((s) => s.user);

export const refreshUser = async (
  setUserProfile: (newUser: Profile | null) => void,
  currentUser: Profile
) => {
  const _userProfile = await QueryDBProfiles.getUserBasedOnUid(currentUser.uid);

  if (_userProfile) {
    setUserProfile(_userProfile);
  }
};
export const convertStringToSlug = (value: string) => {
  const formattedString = normalizeString(
    convertCleanEntityNameToSlug(cleanEntityName(value))
  );
  return formattedString;
};
