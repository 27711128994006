import { FC } from "react";
import logo from "../../../../src/assets/placeholders/challenge_placeholder.png";
import React from "react";

const SingleChallengeLoader: FC = (): JSX.Element => {
  return (
    <div className=" single-challenge-JBL placeholders-jbl">
      <div className="single-challenge-JBL__main">
        <div className="single-challenge-JBL__general-info ">
          <div className="placeholders-jbl__info">
            <div>
              <img src={logo} className="custom-placeholder wave" />
            </div>

            <div className="placeholders-jbl__data">
              <h1 className="custom-placeholder wave">placeholder</h1>

              <h5 className="custom-placeholder wave">placeholder</h5>

              <p className="placeholders-jbl__goal custom-placeholder wave">
                placeholder
              </p>
            </div>
          </div>
          <div className="placeholders-jbl__progressbar">
            <img src={logo} className=" custom-placeholder wave" />
          </div>
        </div>

        <div className="  single-challenge-JBL__description placeholders-jbl__description">
          <h3 className="custom-placeholder wave">Description</h3>
          <p className="custom-placeholder wave">placeholder</p>
        </div>
        <div className="single-challenge-JBL__leaderboard ">
          <div className="placeholders-jbl__text">
            <h2 className="custom-placeholder wave">Leaderboard</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleChallengeLoader;
