import { memo, FC, useState } from "react";
import Image from "react-bootstrap/Image";
import { ReactComponent as PlusIcon } from "../assets/actions/plus.svg";
import Form from "react-bootstrap/Form";
import { v4 } from "uuid";
import { Avatars } from "../utils/avatars";
import { SignUp2ProfileProps } from "@/features/authentication/types/props/SignUpTypes";
import { Button } from "react-bootstrap";
import i18next from "i18next";
import { Query_Imgix } from "@/api/imgix";
import ImageInputPreview from "@/components/ImagePreview/ImagePreview";

const SignUp2ProfileImage: FC<SignUp2ProfileProps> = (props): JSX.Element => {
  const [fileSrc, setFileSrc] = useState<File | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const [selectedAvatar, setSelectedAvatar] = useState<string>();

  const handleInput = async (file: File | undefined) => {
    setFileSrc(file);

    const type = file?.type.slice(0, 5);

    if (!file || (type === "image" && file.size > 15e6)) {
      setError(true);
      return;
    }
    let firebaseFileURL: string | null = null;
    if (file) {
      firebaseFileURL = await Query_Imgix.convertImageToImgIX(file, "profile");
      props.imgSrc(firebaseFileURL || undefined);
    }
  };
  return (
    <Form.Group role="group" className="auth__input auth__input--avatars">
      <div className="auth__header">
        <h2>{i18next.t("auth.profile_image")}</h2>
        <p>{i18next.t("profile.edit.avatar.upload_msg")}</p>
      </div>
      <div className="avatar">
        <h3 className="avatar__heading">Custom</h3>
        <div className="avatar__upload">
          <div className="avatar__file">
            <Button
              variant="link"
              className="btn-file"
              as="label"
              htmlFor="profile-image-input"
            >
              <PlusIcon />

              <input
                accept="image/*"
                type="file"
                className=""
                id="profile-image-input"
                onChange={(e) => {
                  setError(false);
                  setSelectedAvatar(undefined);
                  handleInput(e?.target?.files?.[0]);
                }}
                value=""
              />
              {error && <p> File is too big and can not be uploaded</p>}
            </Button>
          </div>

          <div
            className={`${
              !fileSrc && ""
            }  profile-image-input file--preview position-relative `}
          >
            {fileSrc && !selectedAvatar ? (
              <ImageInputPreview
                funcToRemove={() => {
                  setFileSrc(undefined);
                  setSelectedAvatar(undefined);
                  props.imgSrc(undefined);
                  //   setFieldValue("postMedia", undefined);
                }}
                file={fileSrc}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="avatar__select">
          {Avatars.map((item) => {
            return (
              <div className="avatar__category" key={v4()}>
                <h3 className="avatar__heading">{item.category}</h3>
                <ul className="avatar__list">
                  {item.values.map((avt) => {
                    return (
                      <li className="avatar__item">
                        <Button
                          variant="link"
                          onClick={() => {
                            fetch(avt.img)
                              .then((data) => data.blob())
                              .then((blob) => {
                                const myFile = new File([blob], `${avt.img}`, {
                                  type: blob.type,
                                });

                                handleInput(myFile);
                              });
                            setSelectedAvatar(avt.img);
                          }}
                        >
                          <Image
                            key={`${avt.img}`}
                            src={avt.img}
                            className={`avatar__image ${
                              avt.img === selectedAvatar &&
                              "avatar__image--selected"
                            } `}
                          />
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </Form.Group>
  );
};

export default memo(SignUp2ProfileImage);
