import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";

export default function UnAuthorizedLayout({
  children,
  user,
}: {
  children: React.ReactNode;
  user?: boolean;
}) {
  return (
    <>
      <main className="auth__layout">
        {children}
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
          limit={3}
        />
      </main>
    </>
  );
}
