import React, { useState, useEffect, memo } from "react";
import "firebase/auth";
import "firebase/firestore";
import { Button } from "react-bootstrap";
import i18next from "i18next";
import { Link } from "react-router-dom";
import SideMenuAdditionalInfoWithTooltip from "./SideMenuAdditionalInfoWithTooltip";
import { routesForCreateButton } from "../utils/sideMenuRoutes";

const SideMenuCreateButton: React.FunctionComponent<{
  closeWindow?: () => void;
  isSideMenuSm: boolean;
}> = ({ isSideMenuSm, closeWindow }) => {
  const [showItems, setShowItems] = useState<boolean>(false);
  useEffect(() => {
    if (!isSideMenuSm) {
      const specifiedElement = document.getElementById("create-button");
      document.addEventListener("click", (event) => {
        const isClickInside = specifiedElement?.contains(
          event.target as HTMLInputElement
        );
        if (isClickInside === false && showItems) {
          setShowItems(false);
        }
      });
    }
  }, [showItems]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowItems(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [showItems]);

  const handleInteraction = () => {
    setShowItems(!showItems);
    const timeout = setTimeout(() => {
      setShowItems(false);
    }, 5000);
    clearTimeout(timeout);
  };

  // TODO it should be deleted once /teams and /tournaments are build
  // const handleTemporaryDisabledLinks = (title: string, location: any) => {
  //   if (title === "Challenges") return "create-challenge";
  //   return location.pathname;
  // };

  return (
    <>
      <div className="create-buttons">
        <Button
          variant="link"
          id="create-button"
          onClick={handleInteraction}
          className={`create-btn-gooey ${showItems && "show-Buttons"}  ${
            isSideMenuSm ? "" : ""
          }`}
        >
          <div className="gooey-boxes">
            {routesForCreateButton.map((value) => {
              if (value) {
                return (
                  <SideMenuAdditionalInfoWithTooltip
                    content={value.title}
                    placements={value.title === "Tournaments" ? "right" : "top"}
                    key={value.title}
                  >
                    <span className="gooey-boxes__item">
                      <Link aria-hidden onClick={closeWindow} to={value.link}>
                        <value.logo width={25} height={25} />
                      </Link>
                    </span>
                  </SideMenuAdditionalInfoWithTooltip>
                );
              }
            })}
          </div>
          <div className="create-btn__inside">
            <p>{i18next.t("buttons.create")}</p>
          </div>
        </Button>
      </div>
    </>
  );
};

export default memo(SideMenuCreateButton);
