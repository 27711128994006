import React, { memo, FC, useState } from "react";
import { Image } from "react-bootstrap";
import ProgressBar from "../../../../../src/components/ProgressBar/ProgressBar";
import { Query_Imgix } from "../../../../../src/api/imgix/index";
import { Query_shared } from "../../../../../src/api/shared/index";
import { singleChallengeProps } from "../../../../../src/features/challenge/utils/singleChallengeProps";
import { getChallengeDate } from "../../../../../src/features/challenge/helperFunctions/getChallengeDate";
import { getProgressBarValue } from "../../../../../src/features/challenge/helperFunctions/getProgressBarValue";
import { ParticipateButton } from "../../../../../src/features/challenge/components/single/SingleChallengeParticipateButton";
// import { ParticipateButton } from "../single/SingleChallengeParticipateButton";

const SingleChallengeGeneralInfo: FC<{
  data: singleChallengeProps;
  isEdit: boolean;
  isPreview: boolean;
  isPrivateForUser: boolean;
}> = ({ data, isEdit, isPreview, isPrivateForUser }): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);
  console.log(data.challengeData.status, "status");

  return (
    <div className="general-info">
      <div
        className={`general-info__meta ${
          data.challengeData.status !== "ongoing" ? "ongoing" : ""
        }`}
      >
        <div className="general-info__image">
          <div className="adaptive">
            <div className="adaptive-photo">
              <Image
                alt={`User-uploaded image for ${data.challengeData.name} challenge`}
                src={Query_shared.imageErrorHandler(
                  data.challengeData.logoUrl?.includes("wog.imgix")
                    ? `${
                        data.challengeData.logoUrl
                      }?${Query_Imgix.getImgixUrlProps({
                        qty: "35",
                        w: "310",
                        h: "235",
                        fit: "crop",
                      })}`
                    : data.challengeData.logoUrl,
                  logoError,
                  false
                )}
                className="general-info__image"
                onError={() => setLogoError(true)}
              />
            </div>
          </div>
          {!isPrivateForUser && data.challengeData.status === "ongoing" && (
            <div className="challenge__participate_btn">
              <ParticipateButton
                slug={data.challengeData.slug}
                game={data.challengeData.game}
                isPreview={isPreview || data.challengeData.status !== "ongoing"}
                isCurrentUserParticipant={data.isCurrUserParticipant}
                isText
              />
            </div>
          )}
        </div>

        <div className="general-info__description">
          <h1 className="general-info__name">{data.challengeData.name}</h1>
          <div className="general-info__game">{data.challengeData.game}</div>
          <div className="general-info__goal">
            <p>{data.challengeData.description}</p>
          </div>
        </div>
      </div>
      {!isPrivateForUser && (
        <div>
          <ProgressBar
            id="challenge-time"
            label={getChallengeDate(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            value={getProgressBarValue(
              data?.challengeData.startDate
                ? data?.challengeData.startDate._seconds
                : undefined,
              data?.challengeData.endDate
                ? data?.challengeData.endDate._seconds
                : undefined
            )}
            color=""
            onOverview={false}
            isPlaceholder={false}
          />
        </div>
      )}
      <div className="general-info__faq">
        <a href="#faq-1">How to win?</a>
        <a href="#faq-2">What to win?</a>
        <a href="#faq-3">Terms</a>
      </div>
    </div>
  );
};

export default memo(SingleChallengeGeneralInfo);
