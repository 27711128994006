import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { createToast } from "@/utils/toaster/createToast";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import QueryDB from "@/api/queryDB/common";
import { Spinner } from "@/components/Spinner";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../routes/siteRoutesList";
import mockUsernames from "@/features/authentication/utils/mockUsernames";
import brand from "../../utils/brandVariables/brandVariables";
import { avatarsRaw } from "@/features/authentication/utils/avatars";
import { validateEmail } from "@/features/authentication/helperFunctions/validateEmail";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { Button } from "@/components";
import { auth } from "@/api/firebase/firebase.config";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_Imgix } from "@/api/imgix";

export const ScannedQR = (): JSX.Element => {
  const { pcId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const responded = useRef(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [phone, setPhone] = useState<string>("");
  // const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const [response, loadingResponse, error, fetch_challenge] =
    useCloudFunctionCall<
      "Desktop is in use!" | "Success!" | "Wrong QR!" | undefined
    >("scanQRForOverwolf");
  const phoneNumberRegex = /^\d+$/;
  const isNumberValid = phoneNumberRegex.test(phone);
  let responseString = response;

  useEffect(() => {
    responseString = undefined;
  }, [loadingResponse]);

  useEffect(() => {
    responseString = response;
  }, [response]);

  const handleScanQr = async () => {
    setLoading(true);
    await fetch_challenge({
      QR_id: pcId,
    })
      .then((res) => {
        setTimeout(() => {
          if (responseString) {
            responded.current = true;
          }

          if (responseString === "Success!") {
            createToast({
              type: "success",
              message: responseString,
            });
          }
          if (response !== "Success!") {
            createToast({
              type: "info",
              message: responseString,
            });
          }
          responseString = undefined;
        }, 1000);
      })
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          return navigate(siteRoutes["profile"]);
        }, 1000);
      })
      .catch((e) => {
        responded.current = true;

        createToast({
          type: "error",
          message: e.message,
        });
      });
  };

  const handleLogInAnonymously = async () => {
    setLoading(true);
    const username = `${
      mockUsernames[Math.floor(Math.random() * mockUsernames.length) + 1]
    }${brand.brandIntials}${Math.floor(Math.random() * 100) + 1}T`;

    const slug = normalizeString(
      convertCleanEntityNameToSlug(cleanEntityName(username))
    );

    let imgSrc = avatarsRaw[Math.floor(Math.random() * avatarsRaw.length)]; // Pick random image
    let firebaseFileURL;

    // Convert image to
    await fetch(imgSrc)
      .then((data) => data.blob())
      .then(async (blob) => {
        const myFile = new File([blob], `${imgSrc}`, {
          type: blob.type,
        });
        firebaseFileURL = await Query_Imgix.convertImageToImgIX(
          myFile,
          "profile"
        );
      });

    let userData = {} as Profile;
    await firebase
      .auth()
      .signInAnonymously()
      .then(async (res) => {
        userData = {
          slug,
          region: "Europe",
          userName: username,
          uid: res.user?.uid,
          fullName: "",
          profileDescription: "",
          favoriteGames: [],
          lang: "eng",
          profileLogoUrl: firebaseFileURL,
          theme: brand.brandTheme,
          platform: brand.brandValue,
          eventCode: "jbl-dreamhack-winter-2023",
          allPlatforms: [brand.brandValue],
          emailAddress: "",
          country: "",
          followersCount: 0,
          friendsCount: 0,
          profileLogoUrlHistory: [],
        } as Profile & {
          eventCode: string;
        };
        await QueryDB.createNewDoc("profiles", userData, res.user?.uid);
      })
      .then(async () => {
        setProfile(userData);
        await handleScanQr();
      });
  };

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.uid) {
      handleScanQr();
    } else {
      handleLogInAnonymously();
    }
  }, []);

  return (
    <>
      <Spinner />
    </>
  );
};
