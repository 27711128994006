import React, { FC, useEffect, useState } from "react";
import FormErrorMessage from "@/components/FormErrorMessage/FormErrorMessage";
import { Button } from "@/components";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
type GameProps = {
  name: string;
  value: string;
  gameCover: string;
};

const ChallengeGamePicker: FC<{
  label: string;
  value: string;
  setFieldValue: any;
  errors: any;
  name: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}> = ({ value, label, setFieldValue, errors, name, setStep }): JSX.Element => {
  const handleChange = (gameName, gameValue) => {
    setFieldValue(name, gameName);
    setFieldValue("gameType", gameValue);
    setStep((s) => s + 1);
  };

  const [games, setGames] = useState<GameProps[]>([]);
  useEffect(() => {
    const getGames = async () => {
      const games = collectionRef.games;
      const gamesRes = await games.where("isDisplayed", "==", true).get();

      setGames(
        gamesRes.docs.map((doc) => {
          return doc.data() as GameProps;
        })
      );
    };
    getGames();
  }, []);

  return (
    <>
      <div className="game-picker">
        <label>{label}</label>
        <div className="game-picker__cards">
          {games &&
            games.map((game) => {
              return (
                // xs={6} md={4} xl={3} xxl={2}
                <div>
                  <Button
                    type="submit"
                    variant="clear"
                    customStyles={`game-picker__card
                  ${value === game.value ? "active" : ""}`}
                  >
                    <div className="image-handler">
                      <img
                        onClick={() => {
                          handleChange(game.name, game.value);
                        }}
                        data-value={game.name}
                        src={game.gameCover}
                        alt=""
                        className=""
                      />
                    </div>
                    <Button
                      variant=""
                      type="submit"
                      aria-label="pick game"
                      onClickAction={() => {
                        handleChange(game.name, game.value);
                      }}
                      data-value={game.name}
                      customStyles="title-handler"
                    >
                      <></>
                    </Button>
                    <Button
                      variant=""
                      type="submit"
                      aria-label="pick game"
                      onClickAction={() => {
                        handleChange(game.name, game.value);
                      }}
                      data-value={game.name}
                      customStyles="game-title"
                    >
                      {game.name}
                    </Button>
                  </Button>
                </div>
              );
            })}
        </div>
        {errors && errors[name] && (
          <FormErrorMessage
            text={errors[name] as string}
            isVisible={!!errors[name]}
          />
        )}
      </div>
    </>
  );
};

export default ChallengeGamePicker;
