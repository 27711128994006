import { siteRoutes } from "../../routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";

export const EventQrCode = (): JSX.Element => {
  const navigate = useNavigate();

  const handleResult = async (result: any) => {
    try {
      if (result && result.text) {
        const pcId = result.text.split("/").at(-1);
        navigate(`${siteRoutes["scanQrCodeAndSlug"]}${pcId}`);
      }
    } catch (e) {
      createToast({
        type: "error",
        message: "Failed to connect!",
      });
    }
  };
  return (
    <>
      <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(result) => handleResult(result)}
      />
    </>
  );
};
