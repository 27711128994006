import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { auth } from "@/api/firebase/firebase.config";
import { createToast } from "@/utils/toaster/createToast";
import firebase from "firebase/app";
import { archiveOrReadNotification } from "./readNotification";
import QueryDB from "@/api/queryDB/common";
import QueryDB_Notifications from "./../../../api/queryDB/notifications/index";

const NotificationsActions = async (
  data: any,
  docId: string,
  title: "Delete" | "Join",
  notificationRespType: string,
  dataObj: any
) => {
  const currentUserUid = auth.currentUser?.uid;
  if (!currentUserUid || !data.contentId || !data) {
    return;
  }

  try {
    if (title !== "Delete") {
      // Challenges actions
      if (data.type === "challenges-requests") {
        // TODO
        // NEEDS TO BE ADDED LATER WHEN CHALLENGES WHEN CHALLENGEES IS WORKING
        // await participateInChallenge(currentUserUid, data.contentId);
      }
      //   Gathers actions
      if (data.type === "gather-requests") {
        await QueryDB.createUserProfileJunction(
          "userGatherJunction",
          `${currentUserUid}_${data.contentId}`,
          {
            uid: currentUserUid,
            gatherSlug: data.contentId,
            status: "participant",
            gatherId: data.contentId,
            isFavorite: false,
            generalRole: "member",
            isInterested: true,
            notificationSettings: {
              memberRequests: true,
              newMembers: true,
              newPostsInFeed: true,
              newComntsInFeed: true,
            },
          }
        );
      }
      //   Teams actions
      if (data.type === "teams-requests") {
        await QueryDB.createUserProfileJunction(
          "userTeamJunction",
          `${data.currUserId}_${data.contentId}`,
          {
            uid: data.currUserId,
            teamId: data.contentId,
            generalRole: "member",
            joinedTeamTimestamp:
              firebase.firestore.FieldValue.serverTimestamp(),
            monthlyPoints: 0,
            status: "accepted",
            notificationSettings: {
              memberRequests: true,
              newMembers: true,
              newPostsInFeed: true,
              newComntsInFeed: true,
              upcomingEvents: true,
            },
            isFavorite: true,
            isPlayerOfTheMonth: false,
            inTeamRole: "new",
          }
        );
      }
      // Friend requests
      if (data.type.includes("friends")) {
        await QueryDB.createJunction(
          "usersFollowers",
          `${data.contentId}`,
          "friends",
          `${data.currUserId}`
        );

        await QueryDB.createJunction(
          "usersFollowers",
          `${data.currUserId}`,
          "friends",
          `${data.contentId}`
        );
      }
      if (data.contentId) {
        //   Create notification
        await QueryDB_Notifications.createNewNotification(
          currentUserUid,
          data.senderUid,
          data.contentId,
          notificationRespType,
          `${notificationsTextMapping[notificationRespType]} ${dataObj?.name}!`
        );
      }
      await archiveOrReadNotification(docId, true, false);
    }
    if (title === "Delete") {
      // await firebase.database().ref("notifications").child(docId).remove();
      firebase.database().ref("notifications").child(docId).update({
        read: true,
        archived: false,
      });
    }
  } catch {
    createToast({
      type: "error",
      message: "Something went wrong, please try again later",
    });
  }
};
export default NotificationsActions;
