import React, { useState, useCallback, useMemo, useEffect } from "react";
import { createToast } from "@/utils/toaster/createToast";
import { EditWithNoInitImage } from "./modals/EditWithNoInitImage";
import { EditWithInitImage } from "./modals/EditWithInitImage";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import { Query_Imgix } from "@/api/imgix";
import { getCroppedImg } from "@/features/profile/helperFunctions/cropImage";
import QueryDB from "@/api/queryDB/common";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { DeleteModal } from "./modals/DeleteModal";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import { submitUpdateProfile } from "@/utils/constants/formSubmitUtils";

const submit = async (
  imgixURl: string | null,
  uid?: string,
  avatarArr = []
) => {
  if (!imgixURl || !uid) return;
  let newArray;
  if (imgixURl) {
    if (avatarArr && avatarArr.length < 5) {
      newArray = [imgixURl, ...avatarArr];
    } else if (avatarArr && avatarArr.length > 4) {
      const newHistoryArr = avatarArr.slice(0, 4);
      newArray = [imgixURl, ...newHistoryArr];
    }
  }
  await submitUpdateProfile(
    "profiles",
    {
      profileLogoUrl: imgixURl,
      profileLogoUrlHistory: newArray,
    },
    uid
  )
    .then(() => {
      createToast({
        type: "success",
        message: "Avatar updated",
      });
    })
    .catch(() => {
      createToast({
        type: "error",
        message: "Failed to update avatar",
      });
    });
};

export const EditProfileModal: React.FunctionComponent<{
  openEditModal: boolean;
  setLogoSrc: (arg: string | null) => void;
  setOpenEditModal: (arg: boolean) => void;
  logoSrc: string | null;
  name: string;
  isPlaceHolder: boolean;
  avatarHistory: any;
}> = ({
  logoSrc,
  name,
  isPlaceHolder = false,
  setOpenEditModal,
  setLogoSrc,
  openEditModal,
  avatarHistory,
}) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [profileImage, setProfileImage] = useState(logoSrc);
  const [profileCroppedImage, setCroppedProfileImage] = useState(logoSrc);
  const [crop, setCrop] = useState({ x: 1, y: 1 });
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [currentContentOfModal, setCurrentContentOfModal] = useState(
    logoSrc
      ? "Edit image with initial image"
      : "Edit image with no initial image"
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setProfileImage(logoSrc);
    setCroppedProfileImage(logoSrc);
  }, [logoSrc]);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsValue) => {
    setCroppedAreaPixels(croppedAreaPixelsValue);
  }, []);

  const onClose = useCallback(() => {
    setOpenEditModal(false);
    setCurrentContentOfModal(
      logoSrc
        ? "Edit image with initial image"
        : "Edit image with no initial image"
    );
  }, [logoSrc]);

  useEffect(() => {
    if (openEditModal) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [openEditModal]);

  // SUBMIT CROPPED IMAGE
  const submitCroppedImage = async () => {
    setLoading(true);
    if (!profileCroppedImage) return;
    const cropped = await getCroppedImg(
      profileCroppedImage!,
      croppedAreaPixels,
      0
    );

    const BlobToFile = new File([cropped!], "filename");
    const url = await Query_Imgix.convertImageToImgIX(BlobToFile, "profile");

    setProfileImage(url);

    await submit(url, currentUser.uid, avatarHistory);
    setLogoSrc(null);
    refreshUser(setUserProfile, currentUser);
  };

  // REMOVE IMAGE
  const removeImage = async () => {
    setCroppedProfileImage(null);

    await QueryDB.updateDoc("profiles", currentUser.uid, {
      profileLogoUrl: null,
    });
    refreshUser(setUserProfile, currentUser);
    setLogoSrc(null);
  };

  // HANDLE IMG CHANGE
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCroppedProfileImage(URL.createObjectURL(e?.target?.files![0]));
      setOpenEditModal(true);
    },
    []
  );

  useEffect(() => {
    setCurrentContentOfModal(
      logoSrc
        ? "Edit image with initial image"
        : "Edit image with no initial image"
    );
  }, [logoSrc]);

  const contentToDisplay: { [key: string]: JSX.Element } = useMemo(
    () => ({
      "Edit image with no initial image": (
        <EditWithNoInitImage
          name={name}
          profileCroppedImage={profileCroppedImage}
          handleInputChange={handleInputChange}
          setCurrentContentOfModal={setCurrentContentOfModal}
          submitCroppedImage={submitCroppedImage}
        />
      ),
      "Edit image with initial image": (
        <EditWithInitImage
          onCropComplete={onCropComplete}
          crop={crop}
          zoom={zoom}
          profileCroppedImage={profileCroppedImage}
          setCrop={setCrop}
          setZoom={setZoom}
          setCurrentContentOfModal={setCurrentContentOfModal}
          handleInputChange={handleInputChange}
          isMobile={isMobile}
          onClose={onClose}
          loading={loading}
          submitCroppedImage={submitCroppedImage}
          setOpenEditModal={setOpenEditModal}
        />
      ),
      "Confirm delete": (
        <DeleteModal
          logoSrc={logoSrc}
          setCurrentContentOfModal={setCurrentContentOfModal}
          removeImage={removeImage}
        />
      ),
    }),
    [
      crop,
      handleInputChange,
      logoSrc,
      name,
      onClose,
      onCropComplete,
      profileImage,
      removeImage,
      submitCroppedImage,
      zoom,
    ]
  );

  return (
    <div className="edit-profile__container">
      <BaseModalWindow
        show={openEditModal}
        closeModal={() => {
          onClose();
          setProfileImage(logoSrc);
        }}
        size="modal--profile-edit"
      >
        <div className="edit-profile">
          {contentToDisplay[currentContentOfModal]}
        </div>
      </BaseModalWindow>
    </div>
  );
};
