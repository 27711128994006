import React, { useState, memo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/app";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import i18next from "i18next";
import FormErrorMessage from "../components/FormErrorMessage";
import SignUp2ProfileImage from "../components/SignUp2ProfileImage";

import { useAuthPersistState } from "@/store/auth/auth.store";
import AuthProcessBackground from "../components/AuthProcessBackground";
import { validateEmail } from "../helperFunctions/validateEmail";
import { validateName } from "../helperFunctions/validateName";
import TextInput from "@/components/TextInput/TextInput";
import { QueryDB_profileAuthProviders } from "@/api/queryDB/profileAuthProviders";
import QueryDB from "@/api/queryDB/common";
import CloudFunctions from "@/api/cloudFunctions/CloudFunctions";
import { useValidatorEmailOrUsername } from "../helperFunctions/validateEmailOrUsername";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";

const SignUp2: React.FunctionComponent = () => {
  const user = useAuthPersistState((s) => s.user);
  const currentUserId = user?.uid;
  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [showEmailField, setShowEmailField] = useState(false);
  const [username, setUsername] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [disabledEmailAddressField, setDisableEmailAddressField] =
    useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [touched, setTouched] = useState(false);
  const [errorEmail, setErrorEmail] = useState<string | undefined>(undefined);
  const [touchedEmail, setTouchedEmail] = useState(false);

  const providers = [
    QueryDB_profileAuthProviders.getSteamData(),
    QueryDB_profileAuthProviders.getBnetData(),
  ];

  //valid email and username
  useValidatorEmailOrUsername("username", username, setError);
  if (!disabledEmailAddressField) {
    useValidatorEmailOrUsername("email", emailAddress, setErrorEmail);
  }

  useEffect(() => {
    if (localStorage.getItem("signUpMethod")) {
      if (
        localStorage.getItem("signUpMethod") === "Battle.net" ||
        localStorage.getItem("signUpMethod") === "STEAM"
      ) {
        setShowEmailField(true);
      }
    }
    // get email address from auth -> disable email address field if email address is already provided
    (async () => {
      const doesEmailRequired = await CloudFunctions.callCloudFunction(
        "isUsernameInvalid",
        {
          type: "get-email",
        }
      );
      if (!!doesEmailRequired) {
        setDisableEmailAddressField(true);
        setEmailAddress(doesEmailRequired);
      } else {
        setDisableEmailAddressField(false);
        setEmailAddress("");
      }
    })();
  }, []);

  const handleChange = useCallback(async (e: any) => {
    setUsername(e.target.value);
    setError(undefined);
    setTouched(() => true);
  }, []);

  const handleEmailChange = useCallback(async (e: any) => {
    setTouchedEmail(() => true);
    setErrorEmail(undefined);
    setEmailAddress(e.target.value);
    if (!e.target.value) {
      return setErrorEmail("Empty field is not allowed");
    }
  }, []);

  useEffect(() => {
    const prefillUsername = async () => {
      providers.forEach(async (provider) => {
        if (
          await QueryDB_profileAuthProviders.isProfilePresentInProvider(
            provider,
            currentUserId
          )
        ) {
          const currentDoc = await provider.doc(currentUserId).get();
          const currentDocData = currentDoc.data();
          if (currentDocData?._json?.personaname) {
            return setUsername(() => currentDocData?._json?.personaname);
          }
          if (currentDocData?.battletag) {
            return setUsername(() => currentDocData?.battletag);
          }
        }
      });
    };

    firebase
      .firestore()
      .collection("profiles")
      .doc(`${currentUserId}`)
      .get()
      .then((doc) => {
        if (!doc.data()?.userName) {
          prefillUsername();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = useCallback(async () => {
    if (!currentUserId) {
      window.location.reload();
      return;
    }
    if (!username) {
      return setError("Username required!");
    }
    // min name limit
    if (username && username.length < 4) {
      return setError("Use at least 4 characters");
    }
    // max name limit
    if (username && username.length > 25) {
      return setError("Use a maximum of 25 characters");
    }

    const result = await validateName(username.toLowerCase(), "Username");

    if (result) {
      return setError(result?.toString());
    }
    if (showEmailField) {
      if (!emailAddress) {
        return setErrorEmail("Empty field is not allowed");
      }
    }
    const slug = normalizeString(
      convertCleanEntityNameToSlug(cleanEntityName(username))
    );

    await QueryDB.createNewDoc(
      "profiles",
      {
        slug,
        region: "Europe",
        userName: username,
        uid: currentUserId,
        fullName: "",
        profileDescription: "",
        favoriteGames: [],
        profileLogoUrl: imgUrl,
        profileLogoUrlHistory: [imgUrl],
        emailAddress,
        lang: localStorage.getItem("lang") || "eng",
        theme: "wog",
        status: "active",
      },
      currentUserId
    );
    return navigate(siteRoutes["signUpSuccess"]);
  }, [username, emailAddress, imgUrl, history, currentUserId]);

  return (
    <AuthProcessBackground>
      <div className="auth__step auth__step--two">
        <div className="auth__header">
          <span className="auth__breadcrumbs">
            {location.pathname === "/login"
              ? ""
              : `${i18next.t("strings.step")} ${
                  location.pathname[9]
                } ${i18next.t("strings.of")} 3`}
          </span>{" "}
          <h1 className="auth__title">{i18next.t("auth.personalize.large")}</h1>
          <p className="auth__description">* {i18next.t("auth.small_info")}</p>
        </div>

        <div className="auth__input auth__input--username">
          <Form.Label htmlFor="userName">
            {i18next.t("auth.pick_username")}
          </Form.Label>
          <TextInput
            name="userName"
            value={username}
            onChange={handleChange}
            // onBlur={handleBlur}
            placeholder="Username"
            isValid={touched && !error}
            isInvalid={!!(touched && error)}
            hasUploading={false}
          />
          <FormErrorMessage text={error} isVisible={!!error} />
        </div>
        <SignUp2ProfileImage imgSrc={setImgUrl} />
        {showEmailField && (
          <div className="auth__input auth__input--email">
            <Form.Label htmlFor="emailAddress">Your email address</Form.Label>
            <TextInput
              isDisabled={disabledEmailAddressField}
              type="email"
              name="emailAddress"
              value={emailAddress}
              onChange={handleEmailChange}
              // onBlur={handleEmailBlur}
              placeholder="Email address"
              isValid={touchedEmail && !errorEmail}
              isInvalid={!!(touchedEmail && errorEmail)}
              hasUploading={false}
            />
            <FormErrorMessage text={errorEmail} isVisible={!!errorEmail} />
          </div>
        )}

        <div className="auth__actions">
          <Button
            variant="primary"
            disabled={
              !username ||
              error !== undefined ||
              (showEmailField && (!emailAddress || errorEmail !== undefined))
            }
            type="submit"
            onClick={submit}
          >
            {i18next.t("buttons.ready")}
          </Button>
        </div>
      </div>
    </AuthProcessBackground>
  );
};

export default memo(SignUp2);
