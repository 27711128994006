import { useState, FC, useEffect } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { Profile } from "@/models/database/profiles/profile.model";
import { Query_Imgix } from "@/api/imgix";
import { useScrollBlock } from "./../../../hooks/useScrollBlock";
import BaseLogo from "../../../components/BaseLogo/BaseLogo";
import { Query_shared } from "./../../../api/shared/index";
import { Button } from "./../../../components/Button/index";
import ProfileCommunityActionButtons from "./ProfileCommunityActionButtons";
import brand from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

const ProfileGeneralInfo: FC<
  Profile & {
    belongsToCurrUser: boolean;
    isFollowedByCurrentUser: boolean;
    isFriendsOfCurrentUser: boolean;
    isFriendsRequestPending: boolean;
    isPlaceholder: boolean;
    community: any;
    error?: string;
  }
> = (props): JSX.Element => {
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const numberOfLineBreaks = (props.profileDescription?.match(/\n/g) || [])
    .length;
  const [blockScroll, allowScroll] = useScrollBlock();
  const [communityMembersModalOpen, setCommunityMembersModalOpen] =
    useState<boolean>(false);
  if (communityMembersModalOpen) {
    blockScroll();
  } else {
    allowScroll();
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const isMobile = windowWidth < 991;

  if (props.error) {
    //SHOW ERROR UI
    return <span>{props.error}</span>;
  }

  return (
    <>
      {communityMembersModalOpen && (
        <div className="community__modal">
          <div className="modal single-team-all-members-modal-container single-team-modal justify-content-lg-center">
            <div
              className="background"
              onClick={() => setCommunityMembersModalOpen(false)}
              aria-hidden="true"
            />
            {/* <ProfileCommunityMembersModal
              setCommunityMembersModalOpen={setCommunityMembersModalOpen}
            /> */}
          </div>
        </div>
      )}
      <div className="card">
        {/* <div className="card__content"> */}
        <div className="card__vector">{/* <img src={vector} alt="" /> */}</div>
        <div className="card__avatar">
          <div className="avatar">
            <div className="avatar__content">
              <BaseLogo
                logoSrc={Query_shared.imageErrorHandler(
                  props.profileLogoUrl?.includes("wog.imgix")
                    ? `${props.profileLogoUrl}?${Query_Imgix.getImgixUrlProps({
                        w: "190",
                        h: "190",
                        fit: "crop",
                      })}`
                    : props.profileLogoUrl,
                  false,
                  props.isPlaceholder
                )}
                name={props.userName}
                isPlaceHolder={props.isPlaceholder}
              />
            </div>

            <div className="avatar__zone">
              {/* <div>
                  <img src={themeLogo || wogS} alt="img" />
                </div> */}
            </div>
          </div>

          {/* THIS NEEDS TO BE ADDED LATER WITH REALTIME DB */}
          {/* {props.isOnline ? (
              <div className="avatar__status" />
            ) : (
              ""
            )} */}
        </div>
        <div className="card__content">
          <div className="card__user">
            {props.userName ? (
              <h1 className="user__nickname">{props.userName}</h1>
            ) : (
              <h1 className="user__nickname placeholder">Username</h1>
            )}

            {props.fullName && (
              <p className="user__fullname">{props.fullName}</p>
            )}
            {!props.uid && !props.fullName && (
              <p className="user__fullname placeholder">Name</p>
            )}
          </div>
          <div
            className={`card__bio ${
              showMoreDescription
                ? "card__bio--expanded"
                : "card__bio--collapsed"
            }`}
          >
            <div className="biography">
              <p>
                {props.profileDescription &&
                  props.uid &&
                  props.profileDescription}

                {props.belongsToCurrUser &&
                  !props.profileDescription &&
                  props.uid && <>Share something about yourself</>}

                {!props.profileDescription && !props.uid && (
                  <p className="placeholder">placeholder</p>
                )}
              </p>
            </div>
            {props.profileDescription &&
              (props.profileDescription?.length > 100 ||
                numberOfLineBreaks > 3) && (
                <Button
                  variant="link"
                  onClickAction={() => setShowMoreDescription((prev) => !prev)}
                >
                  {showMoreDescription ? "Show less" : "Show more"}
                </Button>
              )}
          </div>
          {brand.hasFeature.friends && (
            <div className="card__community">
              <>
                {props.community &&
                  ["friends", "followers", "following"]
                    .filter((key) =>
                      Object.prototype.hasOwnProperty.call(props.community, key)
                    )
                    .map((key) => (
                      <div className={`community__${key}`} key={key}>
                        {isMobile ? (
                          <Link to={`/community?${props.slug || ""} `}>
                            <span className="community__amount">
                              {props.community[key].totalNumber}
                            </span>
                            <p className="community__title">
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </p>
                          </Link>
                        ) : (
                          <button
                            type="submit"
                            onClick={() => setCommunityMembersModalOpen(true)}
                          >
                            <span className="community__amount">
                              {props.community[key].totalNumber}
                            </span>
                            <p className="community__title">
                              {key.charAt(0).toUpperCase() + key.slice(1)}
                            </p>
                          </button>
                        )}
                      </div>
                    ))}
              </>
            </div>
          )}
          <div className="card__actions">
            {props.belongsToCurrUser ? (
              <Link to="/profile-settings" className="btn btn-secondary">
                {i18next.t("buttons.manage_btn")}
              </Link>
            ) : (
              ""
            )}
            <ProfileCommunityActionButtons
              belongsToCurrUser={props.belongsToCurrUser}
              isFollowedByCurrentUser={props.isFollowedByCurrentUser}
              isFriendsOfCurrentUser={props.isFriendsOfCurrentUser}
              isFriendsRequestPending={props.isFriendsRequestPending}
              isPlaceholder={props.isPlaceholder}
              uid={props.uid}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ProfileGeneralInfo;
