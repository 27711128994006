import React, { memo } from "react";
import { Placement } from "react-bootstrap/esm/types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const SideMenuAdditionalInfoWithTooltip: React.FunctionComponent<{
  content: string;
  children: any;
  placements: string;
}> = ({ content, children, placements }) => {
  return (
    <OverlayTrigger
      placement={placements as Placement}
      overlay={<Tooltip>{content}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default memo(SideMenuAdditionalInfoWithTooltip);
