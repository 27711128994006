import React, { FC, useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Button } from "@/components/index";
import placeholderImage from "../../assets/placeholders/placeholder.png";
import { ReactComponent as CloseIcon } from "../../assets/actions/close.svg";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import {
  handleAddMember,
  handleRemoveMember,
} from "./helperFunction/handleAddRemoveMember";
import { handleInviteMembers } from "./helperFunction/handleInviteMember";
import BaseUserAvatar from "../BaseUserAvatar";
import { Query_Imgix } from "@/api/imgix";

type props = {
  userRole?: string;
  setAddMemberModalOpen: any;
  slug: string;
  notificationType: string;
};

const InviteUserModal: FC<props> = (props): JSX.Element => {
  const currentUserProfile = useCurrentUserProfile((s) => s.user);
  const currentUserSlug = currentUserProfile?.slug;
  const [community, loading_community, communityError, fetch] =
    useCloudFunctionCall<GetUserCommunityBasedOnSlug>(
      "getUserCommunityBasedOnSlug",
      currentUserSlug
    );
  const [searchValue, setSearchValue] = useState<string>("");
  const [membersToAdd, setMembersToAdd] = useState<any[]>([]);
  const [searchedMembers, setSearchedMembers] = useState<any[]>([]);

  const checkIfSelected = (friend: CommunityUser) => {
    if (membersToAdd.find((obj) => obj.uid === friend.uid) !== undefined) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (searchValue && community) {
      let filteredVal = community.friends.users.map((user: CommunityUser) => {
        if (
          (user.fullName &&
            user.fullName.toLowerCase().includes(searchValue.toLowerCase())) ||
          (user.userName &&
            user.userName.toLowerCase().includes(searchValue.toLowerCase()))
        ) {
          return user;
        }
        return null;
      });

      setSearchedMembers(
        filteredVal && filteredVal.filter((users) => users !== null)
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (!currentUserSlug) {
      return;
    }

    const communityArgs: GetUserCommunityBasedOnSlug_inputArgs = {
      userSlug: currentUserSlug,
    };
    const run = async () => {
      await fetch(communityArgs);
      if (communityError) {
        //SHOW ERROR UI
        return <span>something went wrong</span>;
      }
    };
    if (community) {
      setSearchedMembers(community?.friends.users);
    }

    run();
  }, [currentUserSlug]);

  return (
    <div className="invite-user">
      <Button
        onClickAction={() => {
          props.setAddMemberModalOpen(false);
        }}
        customStyles="invite-user__close"
        variant="link"
      >
        <CloseIcon width={30} height={30} />
      </Button>
      <h2 className="invite-user__title">Invite new users</h2>
      <p className="invite-user__description">Select users</p>
      <Row>
        <Col xs={12} xl={6}>
          <label htmlFor="searchMember" className="w-100">
            <input
              type="search"
              id="searchMember"
              placeholder="Search by name, username"
              className="base-input mb-2 w-100"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </label>
          <ul className="invite-user__friends">
            {!searchValue &&
              !loading_community &&
              community &&
              community.friends.users.map((friend: CommunityUser) => {
                return (
                  <li
                    className={`invite-user__friends--inactive ${
                      checkIfSelected(friend) && "invite-user__friends--active"
                    }`}
                    aria-hidden
                    onClick={() => {
                      handleAddMember(
                        friend,
                        membersToAdd,
                        setMembersToAdd,
                        checkIfSelected
                      );
                    }}
                  >
                    <div>
                      <BaseUserAvatar
                        logoUrl={
                          friend.profileLogoUrl?.includes("wog.imgix")
                            ? `${
                                friend.profileLogoUrl
                              }?${Query_Imgix.getImgixUrlProps({
                                qty: "100",
                                w: "40",
                              })}`
                            : friend.profileLogoUrl
                        }
                        userName={friend.userName}
                        fullName={friend.fullName}
                        customStyles=""
                      />
                    </div>
                    {checkIfSelected(friend) && (
                      <span className="invite-user__tick"></span>
                    )}
                  </li>
                );
              })}
            {searchValue.length !== 0 &&
              !loading_community &&
              community &&
              (searchedMembers && searchedMembers.length === 0 ? (
                <div className="invite-user__empty">
                  <p>No results!</p>
                </div>
              ) : (
                searchedMembers &&
                searchedMembers.map((friend) => {
                  return (
                    <li
                      className={`invite-user__friends--inactive ${
                        checkIfSelected(friend) &&
                        "invite-user__friends--active"
                      }`}
                      aria-hidden
                      onClick={() => {
                        handleAddMember(
                          friend,
                          membersToAdd,
                          setMembersToAdd,
                          checkIfSelected
                        );
                      }}
                    >
                      <div>
                        <BaseUserAvatar
                          logoUrl={
                            friend.profileLogoUrl?.includes("wog.imgix")
                              ? `${
                                  friend.profileLogoUrl
                                }?${Query_Imgix.getImgixUrlProps({
                                  qty: "100",
                                  w: "40",
                                })}`
                              : friend.profileLogoUrl
                          }
                          userName={friend.userName}
                          fullName={friend.fullName}
                          customStyles=""
                        />
                      </div>
                      {checkIfSelected(friend) && (
                        // <span className="invite-user__tick">{tick}</span>
                        <span>selected</span>
                      )}
                    </li>
                  );
                })
              ))}
            {!searchValue &&
              !loading_community &&
              community &&
              community.friends.users.length === 0 && (
                <div className="invite-user__add">
                  <Button variant="primary">Add Friends</Button>
                </div>
              )}
            {loading_community &&
              Array.from(Array(3), () => {
                return (
                  <li
                    className="px-3 py-1 d-flex align-items-center"
                    aria-hidden
                    onClick={() => {}}
                  >
                    <picture>
                      <Image
                        className="rounded-circle me-2 placeholder placeholder-wave text-white"
                        src={placeholderImage}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </picture>
                    <div>
                      <p className=" mb-0 placeholder placeholder-wave text-white">
                        placeholder name
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Col>
        <Col xl={6} className="d-none d-xl-block selected-friends__wrapper">
          <ul className="friends-selected friends">
            {membersToAdd.length !== 0 &&
              membersToAdd.map((friend) => {
                return (
                  <li
                    className={`${checkIfSelected(friend) && "selected"}`}
                    aria-hidden
                    onClick={() => {
                      handleRemoveMember(friend, membersToAdd, setMembersToAdd);
                    }}
                  >
                    <div>
                      <BaseUserAvatar
                        logoUrl={
                          friend.profileLogoUrl?.includes("wog.imgix")
                            ? `${
                                friend.profileLogoUrl
                              }?${Query_Imgix.getImgixUrlProps({
                                qty: "100",
                                w: "40",
                              })}`
                            : friend.profileLogoUrl
                        }
                        userName={friend.userName}
                        fullName={friend.fullName}
                        customStyles=""
                      />
                    </div>
                  </li>
                );
              })}
          </ul>
        </Col>
      </Row>
      <div className="invite-user__invite">
        <Button
          type="button"
          variant={membersToAdd.length === 0 ? "secondary" : "primary"}
          disabled={membersToAdd.length === 0}
          onClickAction={() => {
            handleInviteMembers(
              membersToAdd,
              currentUserProfile.uid,
              currentUserSlug,
              props.slug,
              props.notificationType,
              props.setAddMemberModalOpen
            );
          }}
        >
          Send invites
        </Button>
      </div>
    </div>
  );
};

export default InviteUserModal;
