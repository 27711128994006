import React, { memo, FC, useState } from "react";
import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CreatorTypesOptions } from "@/features/challenge/utils/CreatorTypes";
import ProgressBar from "@/components/ProgressBar/ProgressBar";
import { Query_shared } from "@/api/shared/index";
import { Query_Imgix } from "@/api/imgix/index";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { ChallengeOverviewCardProps } from "@/features/challenge/types/props/OverviewProps";
import { getProgressBarValue } from "@/features/challenge/helperFunctions/getProgressBarValue";
import { Badge } from "@/components/Badge";

const ChallengeCardComponent: FC<ChallengeOverviewCardProps> = ({
  isPlaceholder = false,
  ...props
}): JSX.Element => {
  const [logoError, setLogoError] = useState<boolean>(false);

  return (
    <>
      <div key={props.key} className="challenge-card">
        <div className="challenge-card__content">
          <div className="content__image" data-my-img="resizable">
            <Link
              to={isPlaceholder ? "/challenges" : `/challenges/${props.slug}`}
            >
              <picture className="adaptive">
                <figure className="adaptive-photo">
                  <Card.Img
                    onError={() => setLogoError(true)}
                    src={Query_shared.imageErrorHandler(
                      props.logo?.includes("wog.imgix")
                        ? `${props.logo}?${Query_Imgix.getImgixUrlProps({
                            qty: "100",
                            w: "400",
                          })}`
                        : props.logo,
                      logoError,
                      isPlaceholder
                    )}
                  />
                </figure>
                <div className="content__game">
                  <span className="content__game--text">
                    {props.game === "Call of Duty Warzone"
                      ? "Warzone"
                      : props.game}
                  </span>
                </div>
              </picture>
            </Link>
          </div>{" "}
          <div className="content__heading">
            <Link
              to={isPlaceholder ? "/challenges" : `/challenges/${props.slug}`}
            >
              <h5 title={props.name}>{props.name}</h5>
              <div className="content__creator">
                {props.author && (
                  <picture>
                    <BaseLogo
                      logoSrc={props.author?.profileLogoUrl}
                      isPlaceHolder={undefined}
                      name={props.author?.userName}
                    />
                  </picture>
                )}
                <div>
                  <p className="content__username">
                    {isPlaceholder ? "Placeholder" : props.author?.userName}
                    {!props.author && props.goal ? props.goal : ""}
                  </p>
                </div>
              </div>
              <ul className="content__criteria">
                {props.fieldToCompare &&
                  props.fieldToCompare.map((field, ind) => {
                    return (
                      <Badge variant="secondary" key={ind}>
                        {field}
                      </Badge>
                    );
                  })}
              </ul>
            </Link>
          </div>
          <div className="content__footer">
            <div>
              {props.isCurrUserParticipant ? (
                <Link
                  className="content__button--joined"
                  to={`/challenges/${props.slug}`}
                >
                  Joined
                </Link>
              ) : (
                <Link
                  className="content__button"
                  to={`/challenges/${props.slug}`}
                >
                  Join
                </Link>
              )}
            </div>
            <div className="content__progress">
              <ProgressBar
                id={`progress-bar__${props.slug}`}
                label={[""]}
                value={getProgressBarValue(
                  props.startDate ? props.startDate.seconds : undefined,
                  props.endDate ? props.endDate.seconds : undefined
                )}
                color={
                  CreatorTypesOptions[props.creatorType]
                    ? CreatorTypesOptions[props.creatorType].activeColor
                    : CreatorTypesOptions.Community.activeColor
                }
                onOverview
                isPlaceholder={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ChallengeCardComponent);
