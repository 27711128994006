import { Button } from "@/components";
import React, { memo, FC } from "react";
import { ReactComponent as EditIcon } from "../../assets/logos/pencil.svg";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import { FavoriteButton } from "@/components/FavoriteButton";
import { InviteButton } from "@/components/InviteButton";

export const SingleChallengeActionButtons: FC<{
  data: singleChallengeProps;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isPreview: boolean;
}> = ({ data, isPreview, setIsEdit }): JSX.Element => {
  return (
    <div>
      {data.isCreatedByCurrUser ? (
        <Button
          variant="link"
          disabled={isPreview || data.challengeData.status === "finished"}
          onClickAction={() => {
            setIsEdit(true);
          }}
        >
          <EditIcon />
        </Button>
      ) : (
        ""
      )}
      <FavoriteButton
        slug={`${data.challengeData.slug}`}
        isPreview={isPreview}
        isInFavoritesOfCurrentUser={data.isInCurrUserFavorites}
        featureType="challenge"
        name={data.challengeData.name}
      />

      {data.isCreatedByCurrUser || data.challengeData.type ? (
        <InviteButton
          slug={`${data.challengeData.slug}`}
          isPreview={isPreview || data.challengeData.status !== "ongoing"}
          notificationType="challenges-requests"
        />
      ) : (
        ""
      )}
      {/* <ShareButton
            slug={`${data.challengeData.slug}`}
            text={data.challengeData.description}
            name={data.challengeData.name}
            isPreview={data.challengeData.isPreview}
            logoSrc={data.challengeData.logoUrl as string}
            creatorType={data.challengeData.creatorType}
            game={data.challengeData.game as string}
            progressLabel={data.challengeData.progressBarLabel[1]}
          /> */}
    </div>
  );
};
