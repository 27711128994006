const TRANSLATIONS_EN = {
  // Landing page
  landing: {
    headline: "Your Gaming Experience",
    desc: "Explore, create and join World of Gamers, where you find like-minded gamers, and add to your gaming experience.",

    features: {
      headline: "Explore our features",
      activities: {
        title: "Activities",
        desc: "Engage in various challenges made for your favorite games! Choose challenges created by other users, or create some yourself! We give you the tools, you do the magic!",
      },
      access: {
        title: "Access",
        desc: "Let’s get you in on all the fun. Set up your account by connecting through your desired platform, whetever it is Google, Steam, Battle.net or alternatively with your own personal email. You are in charge.",
      },
      community: {
        title: "Community",
        desc: "Attract new followers, friends or foes, while posting quality gaming content for your community. Customise your profile, share moments from your favourite game and build your own community.",
      },
      profile: {
        title: "profile",
        desc: "Build your own profile, and share your epic moments with your friends. Your profile can be used to interact with other profiles where you can like, share, comment, follow, add friends, follow famous streamers, watch gaming content and much more.",
      },
    },
  },
  // home page
  home: {
    activities: {
      header: "Discover activities",
      info: "Discover new challenges, gathers and teams created by other gamers.",
    },
    members: {
      header: "Top active members",
      info: "Check out selected members of the community.",
    },
    challenges: {
      header: "Challenges",
      info: "Define how you want to play and join to compete with others in your favourite game",
    },
    gathers: {
      header: "Gathers",
      info: "See upcoming gathers and join in on gaming sessions with others",
    },
    teams: {
      header: "Teams",
      info: "Team up with friends or become a member of other teams",
    },
  },

  // Feed
  feed: {
    welcome_msg: "Welcome back, ",
  },

  profile: {
    tabs: {
      posts: "Feed",
      challenges: "Challenges",
      community: "Community",
      gathers: "Gathers",
      teams: "Teams",
      achievements: "Achievements",
    },
    challenges: {
      badges: {
        all: "all",
        participatingIn: "participatingIn",
        createdBy: "createdBy",
        favorites: "favorites",
        private: "private",
        completed: "completed",
        drafts: "drafts",
      },
    },
    community: {
      tabs: {
        friends: "friends",
        following: "following",
        followers: "followers",
      },
    },
    friends: {
      title: "Friends",
      no_friends_msg: "This user has no friends yet.",
    },
    edit: {
      title: "Edit profile",
      tabs: {
        Avatar: "Avatar",
        Information: "Information",
        Connections: "Connections",
        Themes: "Themes",
      },
      avatar: {
        edit_msg: "Edit avatar",
        upload_msg: "Upload an image or pick one of our pre-defined avatars.",
        upload_title: "Upload history",
      },
      connections: {
        title: "Add accounts to your profile",
        // In between desc1 and desc2 is the brands name
        desc1: "DA Connect your",
        desc2:
          "account with third-parties to participate in activities such as Challenges. You can revoke access to your data at any time. Passwords and payment details will never be shared.",
        no_connections: "No connections",
        // In between desc1 and desc2 is the brands name
        no_connections_desc1: "Unlock all",
        no_connections_desc2: "features by connecting your accounts",
      },
    },
  },
  // Overview archive (overview page banners + filters)| news, challenges, teams, gathers
  overview: {
    news: {
      create_btn: "Create new article",
      desc: "Most recent news of the World of Gamers and gaming industry",
      featured: "Featured news",
      headline: {
        mine: "My articles",
        editorial: "Editorial articles",
        popular: "Most popular",
        community: "Community articles",
      },
      badges: {
        mine: "Mine",
        editorial: "Editorial",
        popular: "Most popular",
        community: "Community created",
      },
    },
    challenges: {
      create_btn: "Create new challenge",
      desc: "Define how you want to play!",
      headline: {
        mine: "My challenges",
        new: "Discover new",
        zone: "Zone activity",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
        zone: "Zone activity",
      },
    },
    teams: {
      create_btn: "Create new team",
      desc: "Come together as a team to achieve great things!",
      headline: {
        mine: "My teams",
        new: "Discover new",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
      },
    },
    gathers: {
      create_btn: "Create new gather",
      desc: "Gather your friends or find new ones to shake things up!",
      headline: {
        mine: "My gathers",
        new: "Discover new",
      },
      badges: {
        mine: "Mine",
        new: "Discover new",
      },
    },
  },

  // About page
  about: {
    title: "About World of Gamers",
    desc: "      World of Gamers is a global community platform where gamers engage with other gamers as well as well-known brands. We wish to enable gamers to engage with each other through several means. As a platform we provide rich functionality to support the needs of the individual gamer and organizations while offering a unique possibility for our cooperative partners to engage with their intended key audience.",
    subsection1: {
      header: "What we do",
      desc1:
        "World of Gamers (“WOG”) is a gaming community that carries the DNA of more than 15 years of shaping eSport in Denmark and the Nordics.",
      desc2:
        "WOG is a gaming community with regional activities. We are using the gamification of statistics to activate our user and give them the option to create content for the whole community in a easy and accessible manner.",
    },
    subsection2: {
      header: "Who we are",
      desc1:
        "We are a team of passionate gamers who played our role in creating a strong foundation for eSport and raising the bar.",
      desc2:
        "World of Gamers are based on the Nordic model with good values and ethics, and is born out of our passion for gaming and our ambition to create something unique that has the potential to elevate gaming and eSport and set new standards.",
    },
    subheader2: "Who we are",
    subheader3: "The Nordic model",

    list: {
      1: "Bringing people together physically and/or digitally",
      2: "Uniting people based on a healthy and responsible community",
      3: "The ability for all participants to grow and develop their skills",
      4: "Providing safe and good experiences for all participants",
      5: "Uniting participants regardless of age, gender, and social background",
      6: "Contributing to giving participants increased value in their life",
      7: "Good values and ethics by treating fellow participants nicely",
      8: "Promoting happiness and passion through all activities",
    },
  },

  // Not found
  not_found: {
    title: "404 PAGE NOT FOUND",
    desc: "This page either does not exists, or is no longer available.",
    button: "Go back to Wog",
  },

  // help page
  help: {
    title: "Feedback",
    general_issues: {
      title: "General issues",
      desc: "Report issues that appeared during interacting with website:",
      list: {
        0: "using search field",
        1: "create button",
        2: "navigation, etc.",
      },
    },
    sign_up: {
      title: "Sign up process",
      desc: "Report issues on creating an account: ",
      list: {
        0: "performance",
        1: "occuring errors",
        2: "problems connecting through Battle.net or Steam platform, etc.",
      },
    },
    profile: {
      title: "Profile page",
      desc: "Report issues on profile page: ",
      list: {
        0: "connecting games account",
        1: "adding to friends",
        2: "editing profile",
        3: "notifications, etc.",
      },
    },
    create_challenge: {
      title: "Create a challenge",
      desc: "Report issues that appeared while creating a challenge: ",
      list: {
        0: "text formatting",
        1: "setting the dates",
        2: "choosing criterias, etc.",
      },
    },
    single_challenge: {
      title: "Single challenge",
      desc: "Report issues that appeared during interacting with single challenge page:  ",
      list: {
        0: "incorrect leaderboard",
        1: "wrong data",
        2: "participating in a challenge, etc.",
      },
    },
    challenge_overview: {
      title: "Challenges overview",
      desc: "Report issues that appeared during interacting with challenges overview page: ",
      list: {
        0: "incorrect leaderboard",
        1: "wrong data",
        2: "participating in a challenge, etc.",
      },
    },
  },
  // privacy policy
  privacy_policy: {},

  // terms of service
  term_of_service: {},

  // Sign up
  auth: {
    log_into: "Log in to", // Will have the set brand name after "to"
    log_in_how: "How would you like to log in?",
    sign_up_how: "How would you like to sign up?",
    create_profile: "Create Your Profile",
    // auth step 2
    small_info:
      "you can always change your username, profile image and update your preferences in profile settings",
    pick_username: "Pick a username",
    profile_image: "DA Profile image",
    personalize: {
      small: "Personalizing",
      large: "Personalizing your user profile",
    },
    // ----
    // auth step 3
    all_set: "You are all set!",
    start_info:
      "DA Start your journey by connecting your game accounts or exploring the various challenges available.",
    // ----

    terms: {
      1: "By continuing you agree to our",
      2: "Terms of use",
      3: "and",
      4: "Privacy Policy",
      5: "and confirm that you are at least",
      6: "13 years old",
    },
  },

  // Basic Buttons text. other btn text's will be replaced with these for convenience
  // comments next to it is where where specific ones can be found
  // Words that are all capital should be capitalized in scss, not here.
  buttons: {
    upload: "Upload",
    save: "Save",
    save_changes: "Save changes",
    cancel: "Cancel",
    apply_theme: "APPLY THEME", // Theme picker
    remove_avatar: "Remove avatar", // Profile
    add_friend: "Add friend", // Profile
    unfriend: "Unfriend", // Profile
    manage_btn: "Manage account",
    follow: "Follow",
    unFollow: "Unfollow",
    log_in: "Log in",
    log_out: "Log out", // Navigation
    create: "Create", // Navigation
    see_more: "See more", // Home
    go_back: "Go back", // Home
    join: "Join", // Challenges cards
    get_started: "get started",
    try_btn: "Try it out",
    ready: "Ready", // Sign up 2
    connect_accounts: "Connect accounts", // Sign up 3
    explore_challenges: "Explore challenges",
    explore_teams: "Explore teams",
    skip_to_home: "Skip to homepage", // Sign up 3
    remove_btn: "Remove avatar",
  },

  // Strings used globaly
  strings: {
    step: "Step", // `Step` x of x
    of: "of", // Step x `of` x

    // Feature names, navigation, carousels etc
    challenges: "Challenges",
    challenge: "challenge", // non uppercase
    teams: "Teams",
    team: "team", // non uppercase
    news: "News",
    gathers: "Gathers",
    gather: "gather", // non uppercase
    feed: "Feed",
    friends: "Friends",
    friend: "friend", // non uppercase
    messages: "Messages",
    zones: "Zones",
    participants: "Participants", // Gathers
    interested: "Interested", // Gathers
    you_havent: "You haven't", // Feed
    user_hasnt: "This user hasn't", // Feed
    shared_anything: "shared anything yet.", // Feed
    share_something_friends: "Share something with your friends...", // Placeholder-inputs
    // Will have another word like "team, challenge, news" etc at the end
    share_something_about: "DA Share something about this", // Placeholder-inputs
    share_about_yourself: "Share something about yourself", // profile desc placeholder
  },
};

export default TRANSLATIONS_EN;
