import React, { useState, FC, useEffect } from "react";
import { useCurrentUserProfile } from "../../../../../src/store/auth/userProfile.store";
import useCloudFunctionCall from "../../../../../src/hooks/useCloudFunctionCall";
import { SingleChallengeUi } from "./SingleChallenge.ui";
import { singleChallengeProps } from "../../../../../src/features/challenge/utils/singleChallengeProps";
// import { SingleChallenge404 } from "./SingleChallenge404";
import { LeaderboardDH } from "../leaderboard/leaderboard.fetcher";
import { CreatorTypesOptions } from "../../../../../src/features/challenge/utils/CreatorTypes";
//import SingleChallengeLoader from "../../../../../components/skeletonLoaders/singleChallengeLoader/SingleChallengeLoader";
// import { Fn } from "../../../../../src/features/challenge/utils/constants";
import SingleChallengeLoader from "../../../../components/skeletonLoader/singleChallengeloader/SingleChallengeLoader";

export const SingleChallenge: FC<{
  slug: string;
}> = ({ slug }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [data, loading, error, fetch_challenge] =
    useCloudFunctionCall<singleChallengeProps>("getSingleChallenge", slug);
  const isCurrentUserPending =
    data?.currUserProfile?.status === "pendingRequest";
  const isCurrentUserBanned = data?.currUserProfile?.status === "banned";
  const isPrivateForUser =
    (data?.challengeData.type === "private" && !data.isCurrUserParticipant) ||
    isCurrentUserPending;

  useEffect(() => {
    fetch_challenge({
      challengeId: encodeURIComponent(decodeURIComponent(slug)),
      currUserId: currentUser.uid!,
    });
  }, [slug]);

  return (
    <>
      {/* In  case challenge or author doesnt exist anymore (or other error) */}
      {/* {!loading && error && <SingleChallenge404 navigate={navigate} />} */}

      {/* Single challenge page */}
      {/* Note: Do not add loading state here, it will makes the page slow */}
      {!isCurrentUserBanned && !error && data && (
        <SingleChallengeUi
          data={data} // Contains main data about challenge
          isPreview={false}
          isPrivateForUser={isPrivateForUser}
        >
          <LeaderboardDH
            slug={slug}
            color={
              CreatorTypesOptions[data.challengeData.creatorType]
                ? CreatorTypesOptions[data.challengeData.creatorType]
                    .activeColor
                : CreatorTypesOptions.Community.activeColor
            }
            isPreview={false}
            status={data.challengeData.status}
          />
        </SingleChallengeUi>
      )}
      {/* Skeleton loader should be added */}
      {!data && loading && <SingleChallengeLoader />}
    </>
  );
};
