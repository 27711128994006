import React, { useState, useEffect, memo } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { ReactComponent as LogoutIcon } from "../assets/logout.svg";
import SideMenuCreateButton from "./SideMenuCreateButton";
// import brand from "../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import QueryRTDB from "@/api/queryDB/realTimeDB";
import { SideMenuLinks } from "./SideMenuLinks";
import { Button } from "@/components";
import { siteRoutes } from "../../../routes/siteRoutesList";
import brand from "../../../utils/brandVariables/brandVariables";
import { firestore } from "@/api/firebase/firebase.config";
import { Challenge } from "@/models/database/challenges/challenge.model";

const SideMenuUi: React.FunctionComponent<{ currentViewName: any }> = ({
  currentViewName,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <aside className="primary-nav">
        <div className="primary-nav__brand">
          <Link to="/">
            <img src={brand.logos.large} alt={brand.logos.alt_text} />
          </Link>
        </div>

        <SideMenuLinks currentViewName={`${currentViewName}`} />

        <div className="primary-nav__footer">
          {!brand.isEvent && <SideMenuCreateButton isSideMenuSm={false} />}
          <Button
            variant="link"
            customStyles="btn-logout"
            onClickAction={() => {
              QueryRTDB.setUserStatus("offline");
              firebase.auth().signOut();
              localStorage.removeItem("userProfile");
              navigate(siteRoutes["landing"]);
            }}
          >
            <span className="logout-button">
              <LogoutIcon />
              {i18next.t("buttons.log_out")}
            </span>
          </Button>
        </div>
      </aside>
    </>
  );
};

export default memo(SideMenuUi);
