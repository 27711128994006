import firebase from "firebase";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBdeZ5Tr5J8Xkq_1tTXQSZX0GUKMFvk7KI",
  authDomain: "world-of-gamers-d663f.firebaseapp.com",
  projectId: "world-of-gamers-d663f",
  storageBucket: "world-of-gamers-d663f.appspot.com",
  messagingSenderId: "731640606038",
  appId: "1:731640606038:web:f3464c57793d30166fbaf7",
  measurementId: "G-58QC60YGQY",
  databaseURL:
    "https://world-of-gamers-d663f-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firestore = firebaseApp.firestore();
export const storage = firebaseApp.storage();
export const database_1 = firebaseApp.database();

// auth
export const auth = firebaseApp.auth();
export const GoogleAuthProvider_ID =
  firebase.auth.GoogleAuthProvider.PROVIDER_ID;
export const EmailAuthProvider_ID = firebase.auth.EmailAuthProvider.PROVIDER_ID;
export const PhoneAuthProvider_ID = firebase.auth.PhoneAuthProvider.PROVIDER_ID;
//Classes and Types
export type FirebaseUser = firebase.User;
export const FieldValue = firebase.firestore.FieldValue;
export const timestamp = firebase.firestore.Timestamp;
const timestampNow = timestamp.now();
export type Timestamp = typeof timestampNow;
export type FirebaseCollectionReference =
  firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export type FirestoreQuery =
  firebase.firestore.Query<firebase.firestore.DocumentData>;
export type FirestoreQuerySnapshotDoc =
  firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;

//functions
export const functions = (functionName: string) =>
  firebase.app().functions("europe-west1").httpsCallable(functionName);
