import React, { FC, useCallback, useState } from "react";
import i18next from "i18next";
import { Query_Imgix } from "@/api/imgix";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { SettingsProfileHistory } from "./SettingsProfileHistory";
import { SettingsProfileImageButtons } from "./SettingsProfileImageButtons";
import { SettingsProfileAvatars } from "./SettingsProfileAvatars";
import { EditProfileModal } from "./SettingsProfileUploadModal";
import { refreshUser } from "@/utils/sharedHelperFunctions";

export const SettingsProfileImage: FC<{}> = ({}): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const [openModal, setOpenModal] = useState(false);
  const [imgSrc, setImgSrc] = useState<any>(undefined);
  const [isAvatar, setIsAvatar] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState<string | null>();

  const handleInput = async (file: string | undefined) => {
    if (file) {
      setImgSrc(file);
      setIsAvatar(true);
      setIsLoading(null);
    }
  };
  // Update image
  const submitProfileLogo = useCallback(async () => {
    let firebaseFileURL: string | null = null;
    if (isAvatar) {
      await fetch(imgSrc)
        .then((data) => data.blob())
        .then(async (blob) => {
          const myFile = new File([blob], `${imgSrc}`, {
            type: blob.type,
          });
          firebaseFileURL = await Query_Imgix.convertImageToImgIX(
            myFile,
            "profile"
          );
        });
    } else {
      firebaseFileURL = imgSrc;
    }

    await QueryDB.updateDoc("profiles", currentUser.uid, {
      profileLogoUrl: firebaseFileURL || null,
    })
      .then(() => {
        refreshUser(setUserProfile, currentUser);
        createToast({
          type: "success",
          message: "Avatar updated",
        });
      })
      .catch(() => {
        createToast({
          type: "error",
          message: "Failed to update avatar",
        });
      });

    setImgSrc(undefined);
    setIsAvatar(false);
    setIsSubmiting(false);
  }, [imgSrc]);

  return (
    <>
      <div>
        <SettingsProfileImageButtons
          imgSrc={imgSrc || ""}
          setImgSrc={setImgSrc}
          profile={currentUser}
          setIsAvatar={setIsAvatar}
          setIsSubmiting={setIsSubmiting}
          isUpdating={isSubmiting}
          submitProfileLogo={submitProfileLogo}
        />
      </div>

      <div className="settings__defined">
        <h5>{i18next.t(`profile.edit.avatar.upload_title`)}</h5>

        {/* Row of images of users history */}
        <SettingsProfileHistory
          currentUser={currentUser}
          setOpenModal={setOpenModal}
          setIsAvatar={setIsAvatar}
          setImgSrc={setImgSrc}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />

        {/* List of pre-defined avatars */}
        <SettingsProfileAvatars
          handleInput={handleInput}
          // setIsLoading={setIsLoading}
          setIsAvatar={setIsAvatar}
        />
      </div>

      <EditProfileModal
        logoSrc={currentUser.profileLogoUrl}
        setLogoSrc={setImgSrc}
        setOpenEditModal={setOpenModal}
        openEditModal={openModal}
        name={currentUser.userName}
        isPlaceHolder
        avatarHistory={currentUser.profileLogoUrlHistory}
      />
    </>
  );
};
