import React, { memo, useEffect, useState } from "react";
import { ReactComponent as BellIcon } from "../../assets/bell.svg";
import { Badge } from "@/components";

const NotificationsBell: React.FunctionComponent<{
  newNotifications?: number;
}> = ({ newNotifications }) => {
  const [notificationsNmbr, setNotificationsNmbr] = useState<number>();

  useEffect(() => {
    if (newNotifications && newNotifications !== 0) {
      setNotificationsNmbr(newNotifications);
    }
  }, [newNotifications]);
  return (
    <>
      <div className="notification-bell__indicator ">
        {notificationsNmbr !== 0 ||
          (newNotifications !== 0 && (
            <Badge variant="primary">
              {notificationsNmbr || newNotifications}
            </Badge>
          ))}
        <BellIcon height={22} />
      </div>
    </>
  );
};

export default memo(NotificationsBell);
