import firebase from "firebase";
export class QueryDB_profileAuthProviders {
  constructor() {}

  static getSteamData() {
    return _getSteamData();
  }

  static getBnetData() {
    return _getBnetData();
  }

  static async isProfilePresentInProvider(
    data: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
    userId: string | undefined
  ) {
    return (await data.doc(userId).get()).exists;
  }
}

const _getSteamData =
  (): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
    const steamData = firebase.firestore().collection("profileSteam");
    return steamData;
  };

const _getBnetData =
  (): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
    const bnetData = firebase.firestore().collection("profileBnet");

    return bnetData;
  };
