import React, { memo, useEffect, useState, FC, useMemo } from "react";
import { SlugProps } from "../../../../../src/features/challenge/types/props/SingleChallengeProps";
import LeaderboardTableRows from "../../../../../src/features/challenge/components/leaderboard/LeaderboardTableRows";
import LeaderboardUpdate from "../../../../../src/features/challenge/components/leaderboard/LeaderboardUpdate";
import { useCurrentUserProfile } from "../../../../../src/store/auth/userProfile.store";
import LeaderboardParticipantsTable from "../../../../../src/features/challenge/components/leaderboard/LeaderboardParticipantsTable";
import useCloudFunctionCallForListsAndInfiniteScroll from "../../../../../src/hooks/useCloudFunctionCallForLists";
import {
  LeaderBoardBasedOnChallengeId_Props,
  LeaderBoardBasedOnChallengeId_Response,
} from "../../../../../src/features/challenge/types/cloudFunctions/LeaderBoard";
import { Fn } from "../../../../../src/features/challenge/utils/constants";

export const LeaderboardDH: FC<
  SlugProps & {
    color: string;
    isPreview?: boolean;
    status?: string;
  }
> = ({ slug, color, isPreview = false, status }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);

  // its a temporary solution to check if user participated or not (its supposed to be checked the rank and userlastpoint by backend)
  const challengesString = localStorage.getItem(
    "getChallengesUserParticipatesIn"
  );
  let particatedIn: any;
  // try {
  //   particatedIn = JSON.parse(
  //     challengesString === null ? "" : challengesString
  //   );
  // } catch (error) {
  //   console.error("Error parsing JSON:", error);
  // }
  // const loader = useRef(null);
  const [updatingTimer, setUpdatingTimer] = useState<number>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const updateLeaderboardHandler = (isTimeOut: boolean) => {
    if (isTimeOut) {
      setUpdatingTimer(undefined);
      setIsUpdated(true);
    }
  };

  // NEW DATA
  const [
    leaderboard,
    lastFeedDateInSeconds,
    hasMore,
    _loading,
    error,
    fetchLeaderboard,
    ref,
    inView,
    filters,
  ] = useCloudFunctionCallForListsAndInfiniteScroll<
    LeaderBoardBasedOnChallengeId_Response,
    "ALL" | "FRIENDS" | "FOLLOWING",
    LeaderBoardBasedOnChallengeId_Props // props types for cloud function
  >(
    "getLeaderBoardForChallengeId",
    slug,
    "ALL",
    false // persisting
  );

  //ignore this code block; DO  NOT MODIFY IT ----------------
  const [selectedFilterType, setPropsFilters] = filters;
  const currentUserInThisChallenge = leaderboard?.currentUser;
  const participants = leaderboard?.participants || [];

  // if user is participating then userChallengeData will not be undefined
  const isUserParticipated = !!currentUserInThisChallenge?.userChallengeData;

  //only run at first load!
  useEffect(() => {
    fetchLeaderboard(
      {
        challengeId: slug,
        currentUserId: currentUser.uid,
        type: selectedFilterType,
        lastVisibleItemNumber: 0,
      },
      false
    );
  }, [slug]);

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView]);

  // call this function when inView is true
  const fetchMore = async () => {
    await fetchLeaderboard(
      {
        challengeId: slug,
        currentUserId: currentUser.uid,
        type: selectedFilterType,
        lastVisibleItemNumber: lastFeedDateInSeconds,
      },
      true
    );
  };

  const inViewRefElementIndex = useMemo(() => {
    return leaderboard ? leaderboard?.participants.length - 1 : -1;
  }, [leaderboard, hasMore, selectedFilterType]);

  const currUser = () => (
    <div className="leaderboard__user">
      <p>Your results appear here as soon as you participate</p>
    </div>
  );

  const MemoizedCurrUser = memo(currUser);
  return (
    <>
      <div className="single-challenge-JBL__leaderboard">
        <div className="leaderboard">
          <div className="leaderboard__text">
            <h2>Leaderboard</h2>
          </div>
          <div className="leaderboard__table">
            <LeaderboardParticipantsTable
              color={color}
              items={participants}
              observerRef={ref}
              inViewIndex={inViewRefElementIndex}
              loading={_loading}
            >
              <>
                {!_loading && selectedFilterType === "ALL" && (
                  <>
                    {
                      <p className="leaderboard__table-text">
                        Be the first to participate and invite your friends
                      </p>
                    }

                    {currentUser.uid || !isPreview ? (
                      <div className="leaderboard__table-invite">
                        {/* <InviteButton slug={slug!} text="Invite" /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            </LeaderboardParticipantsTable>
            <div className=" leaderboard__main-blur-line"></div>
          </div>
          {(currentUserInThisChallenge?.userChallengeData &&
            Object.keys(currentUserInThisChallenge.userChallengeData).length >
              0) ||
          isUserParticipated ? (
            <div className=" leaderboard__main-item--user leaderboard__main-item  ">
              <LeaderboardTableRows
                bgStyle=""
                rank={
                  currentUserInThisChallenge?.userChallengeData?.rank ?? "_"
                }
                logo={currentUser.profileLogoUrl}
                name={[currentUser.userName, currentUser.fullName]}
                points={
                  currentUserInThisChallenge?.userChallengeData?.points ?? "_"
                }
                slug={currentUser.slug}
              />
            </div>
          ) : (
            <MemoizedCurrUser />
          )}
        </div>
      </div>
    </>
  );
};
