import useDetectClickOutside from "@/hooks/useDetectClickOutside";
import { useState, useRef } from "react";

export const DropDown = ({
  children,
  title,
  logo,
}: {
  children: any;
  title?: string;
  logo?: any;
}) => {
  const [dropDownOpen, setDropDownOpen] = useState<boolean>();
  const wrapperRef = useRef(null);
  const handleClose = () => {
    setDropDownOpen(false);
  };
  // Small timeout to make the dropdown open and "exist" before setting the trigger actions
  setTimeout(() => {
    const options = document.querySelectorAll(".dropdown__option");
    // options.forEach((e) => {
    //   e.addEventListener("click", function () {
    //     handleClose();
    //   });
    // });
  }, 500);

  // In case user clicks a option in the dropdown, useDetectClickOutside wont be triggered,
  // so add a trigger to every child to close menu

  useDetectClickOutside(wrapperRef, handleClose);
  return (
    <div ref={wrapperRef} className="dropdown">
      {title || logo ? (
        <button
          className="dropdown__btn"
          ref={wrapperRef}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          {" "}
          {title || logo}
        </button>
      ) : (
        ""
      )}

      {dropDownOpen && <div className="dropdown__options">{children}</div>}
    </div>
  );
};
