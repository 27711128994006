import React, { useEffect, FC } from "react";
import { commentsResponse } from "../../types/commentsTypes";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { SinglePostSingleComment } from "./SinglePostSingleComment";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Query_shared } from "@/api/shared";
import CreateCommentForm from "./SinglePostCreateCommentForm";
import { ErrorFallback, Spinner } from "@/components";

export const SinglePostComments: FC<{
  showComments: boolean;
  postId: string;
  postAuthorUid: string;
}> = ({ showComments, postId, postAuthorUid }): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [comments, loading, error, fetch_comments] = useCloudFunctionCall<
    commentsResponse[]
  >("getCommentsOfCurrentPost", currentUser.slug);

  const fetchComments = async () => {
    await fetch_comments({
      postId,
      currUserId: currentUser.uid,
      parentId: postId,
    });
  };

  useEffect(() => {
    if (postId && currentUser.uid) {
      fetchComments();
    }
  }, [postId, currentUser.uid]);

  return (
    <>
      {error && <ErrorFallback>Failed to fetch comments</ErrorFallback>}
      {showComments ? (
        <div className="comment-single--post">
          <ul>
            {comments &&
              comments.length > 0 &&
              comments.map((comment) => {
                try {
                  return (
                    <SinglePostSingleComment
                      comment={comment}
                      postId={postId}
                      setComments={undefined}
                      whenWasPosted={{
                        stringDate: new Date(
                          comment.created._seconds * 1000
                        ).toDateString(),
                        howLongAgo: Query_shared.getWhenWasPosted(
                          comment.created._seconds * 1000
                        ),
                      }}
                    />
                  );
                } catch (error) {
                  return <ErrorFallback>Comment failed to load</ErrorFallback>;
                }
              })}
          </ul>

          {loading && <Spinner />}
          <CreateCommentForm
            postId={postId}
            postAuthorUid={postAuthorUid}
            fetchComments={fetchComments}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
