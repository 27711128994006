import { FC } from "react";
import BaseLogo from "@/components/BaseLogo/BaseLogo";

const BaseUserAvatar: FC<{
  logoUrl?: string;
  userName: string;
  fullName?: string;
  customStyles?: string;
  isPlaceholder?: boolean;
}> = ({ isPlaceholder = false, ...props }) => {
  return (
    <div className={`${props.customStyles}"user-avatar"`}>
      <BaseLogo
        logoSrc={props.logoUrl}
        name={props.userName}
        isPlaceHolder={isPlaceholder}
      />
      {isPlaceholder ? (
        <div className="user-name">
          <p className="">{props.userName}</p>
          <span className="">{props.fullName}</span>
        </div>
      ) : (
        <div>
          <p className=" mb-0 placeholder placeholder-wave text-white">
            placeholder name
          </p>
        </div>
      )}
    </div>
  );
};
export default BaseUserAvatar;
