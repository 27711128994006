import React, { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import BaseVideoTagWrapper from "../BaseWrappers/BaseVideoTagWrapper";
import { ReactComponent as CloseIcon } from "../../assets/actions/close.svg";

const ImageInputPreview: React.FunctionComponent<{
  funcToRemove: () => void;
  file?: File | null;
  url?: string;
}> = ({ funcToRemove, file, url }) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string | undefined>(undefined);

  useEffect(() => {
    // create the preview
    if (file) {
      setType(file?.type?.slice(0, 5));
      const objectUrl = URL.createObjectURL(file);

      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
    setType(undefined);
  }, [file]);

  return (
    <div className="image-post">
      {preview ? (
        type === "video" ? (
          // <div style={{ maxWidth: "10rem", maxHeight: "10rem" }}>
          <BaseVideoTagWrapper
            videoSrc={preview}
            //  style={{ maxWidth: "10rem", maxHeight: "10rem" }}
          />
        ) : (
          // </div>
          <div className="image-post__preview">
            <Image
              src={preview || url}
              alt="Uploaded image"
              className="image-post__preview--img"
            />
            <Button
              variant="link"
              type="button"
              onClick={() => funcToRemove()}
              className="image-post__preview--cancel"
            >
              <CloseIcon width={45} height={45} />
            </Button>
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default ImageInputPreview;
