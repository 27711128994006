import React, { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import i18next from "i18next";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import { routes } from "../utils/sideMenuRoutes";
import { Challenge } from "@/models/database/challenges/challenge.model";
import { ReactComponent as Flag } from "../assets/icon_flag.svg";
import { ReactComponent as FallGuys } from "../assets/icon_fallguys.svg";
import { siteRoutes } from "../../../routes/siteRoutesList";

export const SideMenuLinks: React.FunctionComponent<{
  currentViewName: any;
  closeWindow?: () => void;
}> = ({ currentViewName, closeWindow }) => {
  const navigate = useNavigate();
  return (
    <nav className="primary-nav__nav">
      {Object.entries(routes).map(([key, value]) => (
        <div key={key} className="primary-nav__section">
          <div className="primary-nav__heading">
            <h4>{key}</h4>
          </div>

          <ul className="primary-nav__list">
            {key === "Play challenge" && (
              <>
                <li key={key} className={`primary-nav__item`}>
                  <Link
                    onClick={() => {
                      if (closeWindow) {
                        closeWindow();
                      }
                      navigate(siteRoutes["home"]);
                    }}
                    to={`home`}
                    className="primary-nav__link"
                  >
                    <span className="primary-nav__link--contents">
                      <Flag />
                      <span>F1® 23</span>
                    </span>
                  </Link>
                </li>
              </>
            )}

            {value.map((v) => {
              if (v) {
                return (
                  <li
                    key={v.title}
                    className={`primary-nav__item ${
                      v.link === currentViewName ? "active" : ""
                    }`}
                  >
                    <Link
                      to={`${v.link}`}
                      className="primary-nav__link"
                      onClick={closeWindow}
                    >
                      <span className="primary-nav__link--contents">
                        {/* {v.logo && <v.logo width={24} height={24} />} */}
                        <span>{i18next.t(v.title)}</span>
                      </span>
                      {v.title === "Messages" &&
                        localStorage!.getItem("hasMessages") === "true" &&
                        v.link !== currentViewName && (
                          <div className="badge-wrapper ms-1">
                            <Badge bg="primary">New</Badge>
                          </div>
                        )}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      ))}
    </nav>
  );
};
