import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { Button, Spinner } from "@/components";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { participateInChallenge } from "../../helperFunctions/participateUserIntoChallenge";
import { ReactComponent as ParticipateIcon } from "../../assets/actions/participate-inactive.svg";
import { ReactComponent as DoneIcon } from "../../assets/actions/participate-active.svg";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import brand from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Games } from "@/models/database/games/games.model";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";

export const ParticipateButton: FC<{
  slug: string;
  game?: string;
  isPreview?: boolean;
  isCurrentUserParticipant?: boolean;
  isText?: boolean;
}> = ({
  slug,
  game,
  isPreview,
  isCurrentUserParticipant,
  isText,
}): JSX.Element => {
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile((s) => s.user);

  const [isParticipant, setIsParticipant] = useState<boolean | undefined>(
    isCurrentUserParticipant
  );

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getGameData = async () => {
    const games = collectionRef.games;
    const gamesRes = await games.where("name", "==", game).limit(1).get()[0];

    if (gamesRes && gamesRes.data() && gamesRes.data()) {
      return gamesRes.data();
    }
    return false;
  };

  const participateCurrentUserIntoThatChallenge = useCallback(async () => {
    const gameData = (await getGameData()) as Games;
    const getUserChallData = async () => {
      if (currentUser.uid) {
        // await dispatch(
        //   fetchDBChallengesUserParticipatesInAndOverrideLocalChallenges({
        //     currentUser.uid,
        //   })
        // );
      }
    };

    if (gameData.isManualSubmission) {
      return navigate(`${siteRoutes["submitScoreAndSlug"]}${slug}`, {
        state: {
          orgSlug: slug,
        },
      });
    }

    if (!currentUser.uid) {
      navigate(siteRoutes["signUp1"]);
      return;
    }

    if (isParticipant) {
      setLoading(false);
      createToast({
        type: "info",
        message: `Already joined \`${slug}\``,
      });
      return;
    }
    if (isPreview) return;

    setLoading(true);
    const res = await participateInChallenge(
      navigate,
      "public",
      gameData,
      currentUser.uid,
      slug
    );

    if (res) {
      createToast({
        type: "success",
        message: `Joined challenge \`${slug}\``,
      });
      setIsParticipant(true);
      getUserChallData();
      setLoading(false);
    } else {
      setShow(true);
      setLoading(false);
    }
  }, [
    navigate,
    isParticipant,
    slug,
    currentUser.uid,
    isCurrentUserParticipant,
  ]);

  return (
    <>
      <BaseModalWindow
        show={show}
        closeModal={() => {
          setShow(false);
          setLoading(false);
        }}
        title={`Connect your ${brand.name_small} profile with your
          ${game === "Call of Duty Warzone" ? "Battle.net" : "game"} account to
          get started.`}
      >
        <Button
          variant="link"
          customStyles="connect-account__button"
          onClickAction={() => {
            navigate(siteRoutes["profileSettings"], {
              state: { from: "challenges" },
            });
          }}
        >
          Connect
        </Button>
      </BaseModalWindow>
      <Button
        variant="link"
        data-challenges="card-icons"
        onClickAction={participateCurrentUserIntoThatChallenge}
        data-type="participate"
        disabled={isPreview}
        customStyles={`${isText && ""}`}
      >
        {(!loading && isParticipant) || isCurrentUserParticipant ? (
          <>{isText ? "Joined" : <DoneIcon />}</>
        ) : (
          !loading && <>{isText ? "Join" : <ParticipateIcon />}</>
        )}
        {loading && (
          <>
            <Spinner />
          </>
        )}
      </Button>
    </>
  );
};
