import { Button, Image } from "@/components";
import { Profile } from "@/models/database/profiles/profile.model";
import { FC } from "react";
import { ReactComponent as PlusIcon } from "../../../../../../assets/actions/plus.svg";

export const SettingsProfileHistory: FC<{
  currentUser: Profile;
  setOpenModal: any;
  setIsAvatar: any;
  setImgSrc: any;
  setIsLoading: any;
  isLoading: any;
}> = ({
  currentUser,
  setOpenModal,
  setIsAvatar,
  setImgSrc,
  setIsLoading,
  isLoading,
}) => {
  return (
    <div className="settings__history">
      <Button
        variant="link"
        customStyles="settings__history-input"
        onClickAction={() => {
          setOpenModal(true);
          setIsAvatar(false);
        }}
      >
        <PlusIcon />
      </Button>

      {currentUser.profileLogoUrlHistory &&
        currentUser.profileLogoUrlHistory?.map((item: string) => {
          return (
            <Button
              variant="link"
              customStyles="settings__defined--btn-history"
              onClickAction={() => {
                setImgSrc(item);
                setIsAvatar(false);
                setIsLoading(null);
              }}
              disabled={isLoading === item}
            >
              <Image
                alt=""
                imgIxProps={[{ w: "" }]}
                imageSrc={item}
                customStyles="hover-image profile-image-input"
              />
            </Button>
          );
        })}
    </div>
  );
};
