import { FC } from "react";
import logo from "@/assets/placeholders/placeholder.png";

const LeaderboardTableLoader: FC = (): JSX.Element => {
  return (
    <div className="placeholders__table">
      {Array.from(Array(3), (e, index) => {
        return (
          <li key={index}>
            <h3 className="custom-placeholder wave">place</h3>
            <img src={logo} className="custom-placeholder wave" />
            <p className="custom-placeholder wave">placeholder</p>
          </li>
        );
      })}
    </div>
  );
};

export default LeaderboardTableLoader;
