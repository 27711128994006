import React, { useEffect } from "react";
import ProfileCardLoader from "../../../src/components/skeletonLoaders/profileLoader/ProfileCardLoader";
import ProfileGeneralInfo from "./../../../src/features/profile/components/ProfileGeneralInfo";
import useCloudFunctionCall from "../../../src/hooks/useCloudFunctionCall";
import {
  GetUserProfileBasedOnUserSlug,
  GetUserProfileBasedOnUserSlug_InputArgs,
} from "@/features/profile/types/cloudFunctions/GetUserProfileBasedOnUserSlug";

export default function ProfileAndCommunity({
  slug,
  children,
}: {
  slug: string;
  children: React.ReactNode;
}) {
  const [profile, _loading_profile, profileGeneralError, fetch_1] =
    useCloudFunctionCall<GetUserProfileBasedOnUserSlug>(
      "getUserProfileBasedOnUserSlug",
      slug
    );

  useEffect(() => {
    if (!slug) {
      return;
    }
    // to fetch current user's profile data
    const profileArgs: GetUserProfileBasedOnUserSlug_InputArgs = {
      userSlug: slug,
      type: "slug",
    };
    const run = async () => {
      await Promise.all([fetch_1(profileArgs)]);
    };
    run();
  }, [slug]);

  return (
    <div className="profile">
      <div className="profile__grid">
        <div className="profile__card">
          {!_loading_profile && profile && (
            <ProfileGeneralInfo
              profileLogoUrl={profile.profileLogoUrl}
              profileLogoUrlHistory={profile.profileLogoUrlHistory}
              userName={profile.userName}
              fullName={profile.fullName}
              belongsToCurrUser={profile.belongsToCurrentUser}
              isFollowedByCurrentUser={profile.isFollowedByCurrentUser}
              isFriendsOfCurrentUser={profile.isFriendsOfCurrentUser}
              isFriendsRequestPending={profile.isFriendsRequestPending}
              // gamesWithLinkedAccounts={profile?.gamesWithLinkedAccounts}
              // isOnline={isOnline}
              uid={profile?.uid}
              isPlaceholder={false}
              country={profile.country}
              profileDescription={profile.profileDescription}
              slug={profile.slug}
              followersCount={profile.followersCount}
              friendsCount={profile.friendsCount}
              community={undefined}
              lang={profile.lang || "eng"}
              error={profileGeneralError?.message}
            />
          )}

          {/* PROFILE CARD SKELETON LOADER */}
          {_loading_profile && <ProfileCardLoader />}

          {/* <ProfileAchievements /> */}
        </div>
        <div className="profile__feed">
          <div className="profile-field">{children}</div>
        </div>
      </div>
    </div>
  );
}
