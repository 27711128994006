type NotificationType =
  | "teams-requests"
  | "gather-requests"
  | "challenges-requests"
  | "friends-requests"
  | "message-recieved"
  | "comment-mention"
  | "post-mention"
  | "post-commented";

export const showActionTypes = {
  "teams-requests": {
    type: "teams-requests",
    notificationRespType: "teams-requests-accepted",
    collection: "teams",
  },
  "gather-requests": {
    type: "gather-requests",
    notificationRespType: "gather-requests-accepted",
    collection: "gathers",
  },
  "challenges-requests": {
    type: "challenges-requests",
    notificationRespType: "challenges-requests-accepted",
    collection: "challenges",
  },
  "friends-requests": {
    type: "friends-requests",
    notificationRespType: "friends-requests-accepted",
    collection: "profiles",
  },
} as {
  [key in NotificationType]: {
    type: string;
    notificationRespType: string;
    collection: string;
  };
};
export const notificationRoutes = {
  "teams-requests": "teams",
  "gather-requests": "gathers",
  "challenges-requests": "challenges",
  "friends-requests": "profile",
  "message-recieved": "post",
  "comment-mention": "post",
  "post-mention": "post",
  "post-commented": "post",
};
