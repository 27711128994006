import QueryDB from "@/api/queryDB/notifications";
import { notificationsTextMapping } from "@/utils/constants/notificationsText";
import { createToast } from "@/utils/toaster/createToast";

export const handleInviteMembers = async (
  membersToAdd: CommunityUser[],
  currentUseruid: string,
  currentUserSlug: string,
  slug: string,
  notificationType: string, // Adjust the type as needed
  setAddMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (membersToAdd.length < 1 || !currentUseruid || !currentUserSlug) {
    return;
  }

  const invitePromises = membersToAdd.map(async (uid) => {
    await QueryDB.createNewNotification(
      currentUseruid,
      uid.uid,
      slug,
      notificationType,
      `${notificationsTextMapping[notificationType]} ${slug}`
    );
  });

  try {
    await Promise.all(invitePromises);
    setAddMemberModalOpen(false);
    createToast({
      type: "success",
      message: `Sent invite${membersToAdd.length === 1 ? "" : "s"} to ${
        membersToAdd.length === 1
          ? membersToAdd[0].slug
          : `${membersToAdd.length} users`
      } for ${slug}`,
    });
  } catch (error) {
    // Handle any errors that occurred during the invitations.
    console.error("Error sending invitations:", error);
  }
};
