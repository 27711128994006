import { CustomSignInProps } from "../types/props/CustomSignInButtonProps";

export const CustomSignInButton: React.FunctionComponent<CustomSignInProps> = ({
  logo,
  backgroundColor,
  url,
  longText,
  shortText,
}) => {
  return (
    <ul className="firebaseui-idp-list d-flex ">
      <li className="firebaseui-list-item">
        <a
          href={url}
          className="d-flex align-items-center mx-auto firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-id-idp-button "
          style={{ backgroundColor }}
          onClick={() => {
            localStorage.setItem("signUpMethod", shortText);
          }}
        >
          <span className="firebaseui-idp-icon-wrapper">
            <img className="firebaseui-idp-icon" alt="" src={logo} />
          </span>
          <span className="firebaseui-idp-text firebaseui-idp-text-long">
            {longText}
          </span>
          <span className="firebaseui-idp-text firebaseui-idp-text-short">
            {shortText}
          </span>
        </a>
      </li>
    </ul>
  );
};
