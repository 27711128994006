import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { siteRoutes } from "../../routes/siteRoutesList";
import { useCurrentUserProfile } from "../../../src/store/auth/userProfile.store";
import ProfileAndCommunity from "./ProfileAndCommunity.fetcher";

export default function ProfilePage({
  belongToCurrentUser,
}: {
  belongToCurrentUser?: boolean;
}) {
  const { slug } = useParams();
  const currentUserProfile = useCurrentUserProfile((s) => s.user);
  const currentUserSlug = currentUserProfile?.slug;
  const navigate = useNavigate();
  useEffect(() => {
    if (slug && slug === currentUserSlug) {
      navigate(siteRoutes["profile"]);
    }
  }, [slug, currentUserSlug]);

  if (!currentUserSlug) {
    //reload the window if there's no current user stored
    window.location.reload();
    return <></>;
  }
  // define the slug
  const slugToPass = slug || currentUserSlug;
  const _belongToCurrentUser =
    !!belongToCurrentUser || slug === currentUserSlug;
  return (
    <>
      <ProfileAndCommunity slug={slugToPass}>
        {/* <ChallengesList /> */}
      </ProfileAndCommunity>
    </>
  );
}
