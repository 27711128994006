import React, { memo } from "react";

const FormErrorMessage: React.FunctionComponent<{
  text: string | undefined;
  isVisible: boolean;
}> = ({ text, isVisible }) => {
  return (
    <>
      {isVisible ? (
        <p className="error-indicator">
          <span className="error-indicator__mark">!</span>
          <span className="error-indicator__text">
            {text ?? "Some error occurred."}
          </span>
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(FormErrorMessage);
