import React, { useEffect, FC } from "react";
import SingleChallengeGeneralInfo from "./SingleChallengeGeneralInfo";
import { singleChallengeProps } from "../../../../../src/features/challenge/utils/singleChallengeProps";
import SingleChallengeFormula from "./SingleChallengeFormula";

export const SingleChallengeUi: FC<{
  data: singleChallengeProps;
  isPreview: boolean;
  isPrivateForUser: boolean;
  children: React.ReactNode;
}> = ({
  data,
  isPreview,
  isPrivateForUser,

  children,
}): JSX.Element => {
  return (
    <div className="single-challenge-JBL">
      <div className="single-challenge-JBL__main">
        {/* General info (Image, time, desc, tags, title) */}
        <div className="single-challenge-JBL__general-info">
          <SingleChallengeGeneralInfo
            data={data}
            isEdit={false}
            isPreview={isPreview}
            isPrivateForUser={isPrivateForUser}
          />
        </div>

        {/* score base */}

        <SingleChallengeFormula
          coefficients={data.challengeAdditionalData.fieldToCompareCoefficients}
        />

        {/* Terms and Conditions */}
        {data.challengeData.description && (
          <div className="single-challenge-JBL__description">
            <h2 id="faq-2">What can I win?</h2>
            <p>
              At the end of each challenge, the number one on the leaderboard
              will win a JBL QUANTUM 910 WIRELESS HEADSET.
            </p>
            <h2 id="faq-3">Terms and Conditions</h2>
            <p>
              You need to be present at Dreamhack and show up at the JBL booth
              the day you have won and within opening hours in order to claim
              your prize. If you are unable to show up, then we can
              unfortunately not grand you your otherwise well deserved prize. It
              will instead be awarded to the next player on the leaderboard.
            </p>
          </div>
        )}

        {/*  Leaderboard */}
        {children}
      </div>
    </div>
  );
};
