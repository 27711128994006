import React, { useState, useEffect, FC } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SettingsHeading } from "./SettingsHeading";
import { SettingsProfileImage } from "./pages/profileImage/SettingsProfileImage";
import { GeneralSettings } from "./pages/general/SettingsGeneral";
import { SettingsAccounts } from "./pages/accounts/SettingsAccounts.fetcher";
import { ThemeSwitcher } from "./pages/themes/ThemeSwitcher";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import brand from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ReactComponent as BreadCrumbArrow } from "../../../../assets/actions/chevron-right.svg";
import { Button } from "@/components";

export const ProfileSettings: FC = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState("Information");
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const location = useLocation();

  // if hasFeature is false, the set tab will be removed completely and become unaccessible
  const settingsTabs: { [key: string]: JSX.Element | undefined } = {
    Avatar: <SettingsProfileImage />,
    Information: <GeneralSettings setCurrentTab={setCurrentTab} />,
    // Preferences: <SettingsPreferences />,
    Connections: brand.hasFeature["connections"] ? (
      <SettingsAccounts />
    ) : undefined,
    Themes: brand.hasFeature["themes"] ? <ThemeSwitcher /> : undefined,
  };

  useEffect(() => {
    refreshUser(setUserProfile, currentUser);
  }, []);

  useEffect(() => {
    if (location.state?.from === "sign-up-step-4") {
      setCurrentTab("Connections");
    }
    if (location.state?.to === "Connections") {
      setCurrentTab("Connections");
    }
    if (location.state?.from === "home") {
      setCurrentTab("Connections");
    }
  }, [location.state?.from]);

  return (
    <div className="settings">
      {/* <SettingsHeading
        settingsTabs={settingsTabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      /> */}
      <div className="settings__breadcrumbs">
        {currentTab !== "Information" ? (
          <>
            <h2>
              <Button
                variant=""
                onClickAction={() => {
                  setCurrentTab("Information");
                }}
              >
                Manage account
              </Button>
              <BreadCrumbArrow /> {currentTab}
            </h2>
          </>
        ) : (
          <h2>Manage account</h2>
        )}
      </div>

      <div className={`settings__content ${currentTab}`}>
        {currentUser && settingsTabs ? settingsTabs[currentTab] : ""}
      </div>
    </div>
  );
};
