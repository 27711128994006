import QueryDB from "@/api/queryDB/common";
import { Button, DropDown, Spinner } from "@/components";
import BaseModalWindow from "@/components/BaseModalWindow/BaseModalWindow";
import { createToast } from "@/utils/toaster/createToast";
import React, { FC, useState } from "react";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/actions/more-vertical.svg";

export const SinglePostDropDownActions: FC<{
  setIsDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditPost: React.Dispatch<React.SetStateAction<boolean>>;
  belongsToCurrentUser: boolean;
  postId: any;
  currUserSlug: string;
}> = ({
  setIsDeleted,
  setIsEditPost,
  belongsToCurrentUser,
  postId,
  currUserSlug,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [deleting, isDeleting] = useState<boolean>(false);
  return (
    <>
      <BaseModalWindow
        show={confirmModalOpen}
        closeModal={() => {
          setConfirmModalOpen(false);
        }}
        title="Delete this post?"
      >
        <div className="w-100 d-flex justify-content-between">
          <Button
            variant="secondary"
            onClickAction={() => setConfirmModalOpen(false)}
          >
            Keep
          </Button>

          <Button
            variant="primary"
            onClickAction={async () => {
              if (deleting) {
                return;
              }
              if (!postId || !belongsToCurrentUser) {
                return setConfirmModalOpen(false);
              }
              isDeleting(true);
              await QueryDB.deleteDoc("posts", postId).then(() => {
                setIsDeleted(true);
                createToast({
                  type: "info",
                  message: "Post deleted.",
                });
              });
              setConfirmModalOpen(false);
              isDeleting(false);
              return null;
            }}
          >
            {deleting ? <Spinner /> : "Delete"}
          </Button>
        </div>
      </BaseModalWindow>
      {belongsToCurrentUser && (
        <DropDown logo={<ThreeDotsIcon />}>
          <a
            className="dropdown__option"
            onClick={(e) => {
              e.preventDefault();
              setConfirmModalOpen(true);
            }}
          >
            Delete
          </a>
          <a
            className="dropdown__option"
            onClick={(e) => {
              e.preventDefault();
              setIsEditPost(true);
            }}
          >
            edit
          </a>
        </DropDown>
      )}
    </>
  );
};
