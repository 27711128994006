import { Button } from "@/components/Button";
import { Spinner } from "@/components/Spinner";
import { FC, useState } from "react";
import firebase from "firebase/app";
import { Query_shared } from "@/api/shared/index";
import QueryDB from "@/api/queryDB/common";
import { useNavigate } from "react-router";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import brand from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { avatarsRaw } from "../utils/avatars";
import mockUsernames from "../utils/mockUsernames";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";
import { Profile } from "@/models/database/profiles/profile.model";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { Query_Imgix } from "@/api/imgix";

export const SignUpAnonymously: FC<{}> = ({}) => {
  const navigate = useNavigate();
  const [loading, isLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const setProfile = useCurrentUserProfile((s) => s.setUser);

  const handleLogInAnonymously = async () => {
    isLoading(true);
    await firebase.auth().signOut();

    const username = `${
      mockUsernames[Math.floor(Math.random() * mockUsernames.length) + 1]
    }${brand.brandIntials}${Math.floor(Math.random() * 100) + 1}T`;
    const slug = normalizeString(
      convertCleanEntityNameToSlug(cleanEntityName(username))
    );

    let imgSrc = avatarsRaw[Math.floor(Math.random() * avatarsRaw.length)]; // Pick random image
    let firebaseFileURL;

    // Convert image to
    await fetch(imgSrc)
      .then((data) => data.blob())
      .then(async (blob) => {
        const myFile = new File([blob], `${imgSrc}`, {
          type: blob.type,
        });
        firebaseFileURL = await Query_Imgix.convertImageToImgIX(
          myFile,
          "profile"
        );
      });
    let userData = {} as Profile;

    await firebase
      .auth()
      .signInAnonymously()
      .then(async (res) => {
        userData = {
          slug,
          region: "Europe",
          userName: username,
          uid: res.user?.uid,
          fullName: "",
          profileDescription: "",
          favoriteGames: [],
          lang: "eng",
          profileLogoUrl: firebaseFileURL,
          theme: brand.brandTheme,
          platform: brand.brandValue,
          eventCode: "jbl-dreamhack-winter-2023",
          allPlatforms: [brand.brandValue],
          emailAddress: "",
          country: "",
          followersCount: 0,
          friendsCount: 0,
          profileLogoUrlHistory: [],
        } as Profile & {
          eventCode: string;
        };
        setProfile(userData);
        await QueryDB.createNewDoc("profiles", userData, res.user?.uid);
      })
      .then(async () => {
        navigate(siteRoutes["profile"]);
      });
  };
  return (
    <div className="auth__anonymously">
      <h4>Quick sign up</h4>
      <p>This will create a new basic account for you, no setup required.</p>
      <p>
        This account will only be accessible from the current device & browser,
      </p>
      <p>Option to turn into full permanent account in profile settings.</p>

      <Button
        variant="primary"
        onClickAction={async () => {
          await handleLogInAnonymously();
        }}
      >
        {loading ? <Spinner /> : "Create Quick account"}
      </Button>
    </div>
  );
};
