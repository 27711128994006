import brand from "../utils/brandVariables/brandVariables";

export const siteRoutes = {
  // Back home button, errorfallback etc
  home: "/",
  landing: "/landing",
  default: brand.defaultNavigateUrl,

  // Auth
  login: "/login",
  signUp: "/signUp",
  signUpSuccess: "/signup-success",
  signUp1: "/sign-up-1",

  // Profile
  profile: "/profile",
  profileAndSlug: "/profile/",
  profileSettings: "/profile-settings",
  profileTabAndSlug: "/profile-tab/",

  //  News
  news: "/news",
  newsAndSlug: "/news/",
  createNews: "/create-news",

  // Feed posts
  dashboard: "/dashboard",
  singlePostAndSlug: "/post/",

  // Challenges
  challenges: "/challenges",
  challengesAndSlug: "/challenges/",
  createChallenge: "/create-challenge",
  submitScoreAndSlug: "/submit-score/",

  // Gather
  gathers: "/gathers",
  GathersAndSlug: "/gathers/",
  createGather: "create-gather",

  // Teams
  teams: "/teams",
  teamsAndSlug: "/teams/",
  createTeam: "/create-team",

  // Messages
  messages: "/messages",
  messagesAndSlug: "/messages/",

  // Qr Sign up
  eventQrCode: "/event-qr-code",
  scanQrCodeAndSlug: "/scan-qr/",

  // Notifications
  notifications: "/notifications",

  // Other | unused
  help: "/help",
  about: "/about",
  Terms: "/terms",
  "privacy-policy": "/privacy-policy",
  partners: "/partners",
  zones: "/zones",
  friends: "/friends",
  search: "/search",
  gamepass: "/gamepass",
  "daily-raffle": "/daily-raffle",
  "raffle-winners": "/raffle-winners",
  productAndSlug: "/product/",
  winners: "/winners",
};
