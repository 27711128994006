import React, { Fragment, FC } from "react";
import SingleNotificationItem from "../SingleNotificationItem";
import { Query_shared } from "@/api/shared";
import readAllMessages from "../../helperFunctions/readAllMessages";
import { ErrorFallback } from "@/components";
import CheckArrow from "../../assets/participate-active.svg";
import { Link } from "react-router-dom";

type Props = {
  loading: boolean;
  isFullPage?: boolean;
  notifications?: any;
  setDropdownOpen: any;
  unreadNotifications: number;
};
const NotificationsList: FC<Props> = ({
  notifications,
  isFullPage,
  loading,
  setDropdownOpen,
  unreadNotifications,
}): JSX.Element => {
  try {
    return (
      <>
        <div className="scroll-container mb-3">
          <div className="notification-page">
            {!notifications ? (
              <>
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <p className="notification-page__new">No new notifications</p>
                )}
              </>
            ) : (
              <p className="notification-page__new">Latest</p>
            )}
            <div className="notification-page__read">
              {unreadNotifications !== 0 && isFullPage && notifications ? (
                <>
                  <figure className="notification-page__read--tick">
                    <picture>
                      {/* <Image src={CheckArrow} width={13} /> */}
                    </picture>
                  </figure>
                  <p aria-hidden onClick={() => readAllMessages(notifications)}>
                    Mark all as read
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {notifications && !notifications.empty && (
            <>
              {notifications
                .sort(
                  (a: any, b: any) =>
                    a.data.createdAt.seconds - b.data.createdAt.seconds
                )
                // .reverse()
                .slice(0, isFullPage ? 25 : 5)
                .map((doc: any) => {
                  return (
                    <Fragment key={doc.id}>
                      <SingleNotificationItem
                        data={doc.data}
                        docId={doc.realtimeDocumentID}
                        whenWasSent={Query_shared.getWhenWasPosted(
                          doc.data.createdAt._seconds * 1000
                        )}
                      />
                    </Fragment>
                  );
                })}
            </>
          )}
        </div>

        {isFullPage === true && (
          <span className="notifications-deleted">
            Read notifications are deleted after 10 days
          </span>
        )}
        <div className="notification-dropdown__footer">
          {unreadNotifications !== 0 ? (
            <div className="notification-dropdown__read">
              <figure>
                <picture>
                  <img src={CheckArrow} width={12} />
                </picture>
              </figure>
              <p
                aria-hidden
                onClick={() => {
                  readAllMessages(notifications);
                  setDropdownOpen(true);
                }}
              >
                Mark all as read
              </p>
            </div>
          ) : (
            <div />
          )}
          {isFullPage === false && (
            <>
              <Link onClick={() => setDropdownOpen(false)} to="/notifications">
                <p>All notifications</p>
              </Link>
            </>
          )}
        </div>
      </>
    );
  } catch {
    return <ErrorFallback>Error fetching Notifications</ErrorFallback>;
  }
};

export default React.memo(NotificationsList);
