// NEW field should be marked with `// NEW`

const TRANSLATIONS_EN = {
  // Landing page
  landing: {
    headline: "Din Spiloplevelse",
    desc: "Udforsk, Opret og Deltag i World of Gamers, hvor du finder ligesindede Gamere og udvid spiloplevelse",

    features: {
      headline: "Udforsk mulighederne",
      try_btn: "Prøv nu",
      activities: {
        title: "Aktiviteter",
        desc: "Deltag i forskellige udfordringer i dit yndlingsspil! Vælg udfordringer oprettet af andre brugere, eller opret nogle selv! Vi giver dig værktøjerne, du tilføjer magien",
      },
      access: {
        title: "Adgang",
        desc: "Deltag i forskellige udfordringer i dit yndlingsspil! Vælg udfordringer oprettet af andre brugere, eller opret nogle selv! Vi giver dig værktøjerne, du tilføjer magien!",
      },
      community: {
        title: "Adgang",
        desc: "Få adgang til en masse sjov. Opret din konto i dag via din foretrukne platform. Uanset om det er Google, Steam, Battle.net.  Alternativt kan du komme i gang med din e-mail.",
      },
      profile: {
        title: "Profil",
        desc: "Byg din egen Gamer profil, og del dine episke øjeblikke med dine venner. Din profil kan bruges til at interagere med andre profiler, hvor du kan synes godt om, dele, kommentere, følge, tilføje venner, følge berømte streamere, se spilindhold og meget mere.",
      },
    },
  },
  // home page
  home: {
    activities: {
      header: "Mest aktive medlemmer",
      info: "Opdag nye udfordringer, events og hold skabt af andre gamere.",
    },
    members: {
      header: "Mest aktive medlemmer",
      info: "Opdag udvalgte medlemmer af fællesskabet.",
    },
    challenges: {
      header: "Udfordringer",
      info: "Bestem hvordan du vil spille og deltag i udfordringer i dit yndlingsspil",
    },
    gathers: {
      header: "Events",
      info: "Se kommende events og tilslut dig en gaming session.",
    },
    teams: {
      header: "Hold",
      info: "Tilslut dig dine venner eller opret et nyt hold",
    },
  },

  // Feed
  feed: {
    welcome_msg: "Velkommen tilbage, ",
  },

  // Translated from top until here -----
  profile: {
    tabs: {
      feed: "Væg",
      challenges: "Udfordringer",
      community: "Fællesskab",
    },
    challenges: {
      badges: {
        all: "Alle",
        participatingIn: "Deltager i",
        createdBy: "Oprettet af",
        favorites: "Favoritter",
        private: "Privat",
        completed: "Afsluttet",
        drafts: "Kladder",
      },
    },
    community: {
      tabs: {
        friends: "venner",
        following: "følger",
        followers: "tilhængere",
      },
    },
    friends: {
      title: "Venner",
      no_friends_msg: "Denne bruger har ingen venner endnu.",
    },
    edit: {
      title: "Rediger profil",
      tabs: {
        Avatar: "Avatar",
        Information: "Information",
        Connections: "Forbindelser",
        Themes: "Temaer",
      },
      avatar: {
        edit_msg: "Skift avatar",
        upload_msg:
          "Upload et billede eller vælg et af vores predefinerede avatarer.",
        upload_title: "Upload avatar",
      },
      connections: {
        title: "Tilføj konti til din profil",
        // In between desc1 and desc2 is the brands name
        desc1: "Forbind din", // NEW
        desc2:
          "profil med tredje-parter for at deltage i aktiviteter såsom Udfordringer. Du kan altid fjerne forbindelsen igen. Adgangskoder og betalingsinfo vil aldrig blive delt.", // NEW
        no_connections: "Ingen forbindelser", // NEW
        // In between desc1 and desc2 is the brands name
        no_connections_desc1: "Lås op", // NEW
        no_connections_desc2: "for alle funktioner ved at forbinde dine konti", // NEW
      },
    },
  },
  // Overview archive (overview page banners + filters)| news, challenges, teams, gathers
  overview: {
    news: {
      create_btn: "Opret ny artikel",
      desc: "Senest nyt fra World of Gamers og gaming industrien.",
      featured: "Fremhævede artikler",
      headline: {
        mine: "Mine artikler",
        editorial: "Redaktionel artikler",
        popular: "Mest populære",
        community: "Artikler fra fællesskabet",
      },
      badges: {
        mine: "Mine",
        editorial: "Redaktionel",
        popular: "Mest populære",
        community: "Artikler fra fællesskabet",
      },
    },
    challenges: {
      create_btn: "Opret en udfordring",
      desc: "Definér hvordan du vil spille!",
      headline: {
        mine: "Mine udfordringer",
        new: "Udforsk",
        zone: "Zone aktivitet",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
        zone: "Zone aktivitet",
      },
    },
    teams: {
      create_btn: "Opret et hold",
      desc: "DA Come together as a team to achieve great things!",
      headline: {
        mine: "Mine hold",
        new: "Udforsk",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
      },
    },
    gathers: {
      create_btn: "Opret event",
      desc: "Saml dine venner eller dan nye venskaber!",
      headline: {
        mine: "Mine events",
        new: "Udforsk",
      },
      badges: {
        mine: "Mine",
        new: "Udforsk",
      },
    },
  },

  // About page
  about: {
    title: "About World of Gamers",
    desc: "World of Gamers is a global community platform where gamers engage with other gamers as well as well-known brands. We wish to enable gamers to engage with each other through several means. As a platform we provide rich functionality to support the needs of the individual gamer and organizations while offering a unique possibility for our cooperative partners to engage with their intended key audience.",
    subsection1: {
      header: "DA What we do",
      desc1:
        "DA World of Gamers (“WOG”) is a gaming community that carries the DNA of more than 15 years of shaping eSport in Denmark and the Nordics.",
      desc2:
        "DA WOG is a gaming community with regional activities. We are using the gamiDAcation of statistics to activate our user and give them the option to create content for the whole community in a easy and accessible manner.",
    },
    subsection2: {
      header: "DA Who we are",
      desc1:
        "DA We are a team of passionate gamers who played our role in creating a strong foundation for eSport and raising the bar.",
      desc2:
        "DA World of Gamers are based on the Nordic model with good values and ethics, and is born out of our passion for gaming and our ambition to create something unique that has the potential to elevate gaming and eSport and set new standards.",
    },
    subheader2: "DA Who we are",
    subheader3: "DA The Nordic model",

    list: {
      1: "DA Bringing people together physically and/or digitally",
      2: "DA Uniting people based on a healthy and responsible community",
      3: "DA The ability for all participants to grow and develop their skills",
      4: "DA Providing safe and good experiences for all participants",
      5: "DA Uniting participants regardless of age, gender, and social background",
      6: "DA Contributing to giving participants increased value in their life",
      7: "DA Good values and ethics by treating fellow participants nicely",
      8: "DA Promoting happiness and passion through all activities",
    },
  },

  // Not found
  not_found: {
    title: "404 Siden blev ikke fundet",
    desc: "Denne side eksisterer ikke, eller er ikke længere tilgængelig.",
    button: "Gå tilbage til WOG",
  },

  // help page
  help: {
    title: "DA Feedback",
    general_issues: {
      title: "DA General issues",
      desc: "DA Report issues that appeared during interacting with website:",
      list: {
        0: "DA using search DAeld",
        1: "DA create button",
        2: "DA navigation, etc.",
      },
    },
    sign_up: {
      title: "DA Sign up process",
      desc: "DA Report issues on creating an account: ",
      list: {
        0: "DA performance",
        1: "DA occuring errors",
        2: "DA problems connecting through Battle.net or Steam platform, etc.",
      },
    },
    proDAle: {
      title: "DA ProDAle page",
      desc: "DA Report issues on proDAle page: ",
      list: {
        0: "DA connecting games account",
        1: "DA adding to friends",
        2: "DA editing proDAle",
        3: "DA notiDAcations, etc.",
      },
    },
    create_challenge: {
      title: "DA Create a challenge",
      desc: "DA Report issues that appeared while creating a challenge: ",
      list: {
        0: "DA text formatting",
        1: "DA setting the dates",
        2: "DA choosing criterias, etc.",
      },
    },
    single_challenge: {
      title: "DA Single challenge",
      desc: "DA Report issues that appeared during interacting with single challenge page:  ",
      list: {
        0: "DA incorrect leaderboard",
        1: "DA wrong data",
        2: "DA participating in a challenge, etc.",
      },
    },
    challenge_overview: {
      title: "DA Challenges overview",
      desc: "DA Report issues that appeared during interacting with challenges overview page: ",
      list: {
        0: "DA incorrect leaderboard",
        1: "DA wrong data",
        2: "DA participating in a challenge, etc.",
      },
    },
  },
  // privacy policy
  privacy_policy: {},

  // terms of service
  term_of_service: {},

  // Sign up
  auth: {
    log_into: "Log ind i", // Will have the set brand name after "to"
    log_in_how: "Hvordan vil du gerne logge ind?",
    sign_up_how: "Hvordan vil du gerne oprette en bruger?",
    create_profile: "Opret din profil", // NEW
    // auth step 2
    small_info:
      "du kan altid ændre dit brugernavn, profilbillede og opdatere dine præferencer under dine profilindstillinger",
    pick_username: "Vælg et brugernavn",
    profile_image: "Profilbillede",
    personalize: {
      small: "Personliggør",
      large: "Personliggør din brugerprofil",
    },
    // ----
    // auth step 3
    all_set: "Nu er du klar!",
    start_info:
      "Start din rejse ved at forbinde dine spilkonti eller ved at udforske de forskellige udfordringer.",
    // ----
    terms: {
      1: "Ved at fortsætte bekræfter du vores",
      2: "Vilkår for brug",
      3: "og",
      4: "Privatlivspolitik",
      5: "samt bekræfter at du er mindst",
      6: "13 år gammel",
    },
  },

  // Basic Buttons text. other btn text's will be replaced with these for convenience
  //  comments next to it is where where specific ones can be found
  // words that are all capital should be capitalized in scss, not here.
  buttons: {
    upload: "Upload",
    save: "Gem",
    save_changes: "Gem ændringer",
    cancel: "Annuller",
    apply_theme: "Skift tema", // Theme picker
    remove_avatar: "Fjern avatar", // Profile
    add_friend: "Tilføj ven", // Profile
    unfriend: "Fjern ven", // Profile
    manage_btn: "Rediger",
    follow: "Følg",
    unFollow: "Stop med at følge",
    log_in: "Log ind",
    log_out: "Log ud", // Navigation
    create: "Opret", // Navigation
    see_more: "Se mere", // Home
    go_back: "Gå tilbage", // Home
    join: "Tilslut", // Challenges cards
    get_started: "Kom i gang",
    try_btn: "Prøv nu",
    ready: "Klar", // sign up 2
    connect_accounts: "Forbind konti", // sign up 3
    explore_challenges: "Udforsk udfordringer",
    explore_teams: "Udforsk hold",
    skip_to_home: "Gå til startside", // sign up 3
    remove_btn: "Fjern avatar",
  },
  // Strings used globaly
  strings: {
    step: "Trin", // `Step` x of x
    of: "af", // Step x `of` x

    // Deature names, navigation, carousels etc
    challenges: "Udfordringer",
    challenge: "", // non uppercase
    teams: "Hold",
    team: "", // non uppercase
    news: "Nyheder",
    gathers: "Events",
    gather: "", // non uppercase
    feed: "Feed",
    friends: "Venner",
    friend: "", // non uppercase
    messages: "Beskeder",
    zones: "Zoner",
    participants: "Participants", // Gathers // NEW
    interested: "Interested", // Gathers // NEW
    you_havent: "You haven't", // Feed
    user_hasnt: "This user hasn't", // Feed // NEW
    shared_anything: "shared anything yet.", // Feed // NEW
    share_something_friends: "DA Share something with your friends...", // Placeholder-inputs  // NEW
    // Will have another word like "team, challenge, news" etc at the end
    share_something_about: "DA Share something about this", // Placeholder-inputs  // NEW
    share_about_yourself: "DA Share something about yourself", // profile desc placeholder // NEW
  },
};

export default TRANSLATIONS_EN;
