import i18next from "i18next";
import React, { memo } from "react";

const AuthProcessAgreementText: React.FunctionComponent = () => (
  <div className="auth__terms">
    <p>
      {i18next.t("auth.terms.1")}
      <a href="/terms" className="auth__conditions" title={i18next.t("auth.terms.2")} target="_blank">
        {i18next.t("auth.terms.2")}
      </a>
      {i18next.t("auth.terms.3")}
      <a href="/privacy" className="auth__privacy" title={i18next.t("auth.terms.4")} target="_blank">
        {i18next.t("auth.terms.4")}
      </a>
      {i18next.t("auth.terms.5")} <span>{i18next.t("auth.terms.6")}</span>.
    </p>
  </div>
);

export default memo(AuthProcessAgreementText);
