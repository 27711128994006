import React, { memo } from "react";
import { Query_Imgix } from "../../../../api/imgix/index";
import { Post } from "@/models/database/posts/post.model";
import BaseVideoTagWrapper from "@/components/BaseWrappers/BaseVideoTagWrapper";
import { Image } from "@/components";

const UserPost: React.FunctionComponent<
  Pick<Post, "postPhoto" | "postVideo">
> = ({ postPhoto, postVideo }) => {
  return (
    <>
      {postPhoto || postVideo ? (
        <div className="post__media">
          {postPhoto ? (
            <Image
              alt=""
              imageSrc={
                postPhoto.includes("wog.imgix")
                  ? `${postPhoto}?${Query_Imgix.getImgixUrlProps({
                      w: "836",
                    })}`
                  : postPhoto
              }
            />
          ) : (
            ""
          )}
          {postVideo ? <BaseVideoTagWrapper videoSrc={postVideo} /> : ""}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(UserPost);
