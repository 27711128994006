import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import placeholder from "../../assets/placeholders/placeholder.png";
import { useAuthPersistState } from "@/store/auth/auth.store";
import CommunityActionButtons from "./helperfunction/CommunityActionButton";
import { siteRoutes } from "../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";

export type props = {
  creatorType?: string;
  creatorSlug: string;
  creatorLogo?: string;
  creatorUserName: string;
  creatorAdditionalInfo?: string;
  isFollowedByCurrentUser: boolean;
  creatorFollowersNumber: number;
  creatorId: string;
  isPreview?: boolean;
  isPlaceholder: boolean;
};
const CreatorCard: FC<props> = (props): JSX.Element => {
  const navigate = useNavigate();
  const [creatorFollowers, setCreatorFollowers] = useState(
    props.creatorFollowersNumber
  );
  const user = useAuthPersistState((s) => s.user);
  const currentUserId = user?.uid;
  useEffect(() => {
    setCreatorFollowers(props.creatorFollowersNumber);
  }, [props.creatorFollowersNumber]);

  const handleLinkClick = useCallback(() => {
    if (!currentUserId) {
      navigate(siteRoutes["signUp1"]);
      createToast({
        type: "error",
        message: "You need to be logged to go here",
      });
    }
    navigate(`${siteRoutes["profileAndSlug"]}${props.creatorSlug}`);
  }, [currentUserId, props.creatorSlug]);

  return (
    <div className="creator-card">
      <div aria-hidden onClick={handleLinkClick} className="creator-card--sm">
        <h3>Creator</h3>
        <div className="creator-card__image">
          <BaseLogo
            logoSrc={props.isPlaceholder ? placeholder : props.creatorLogo}
            name={`${props.creatorUserName}`}
            isPlaceHolder={undefined}
          />
        </div>
        <div className="creator-card__user--sm">
          <p>
            {props.isPlaceholder ? "text placeholder" : props.creatorUserName}
          </p>
          <span className=" creator-card__fullname">
            {props.isPlaceholder ? "" : props.creatorAdditionalInfo}
          </span>
        </div>
        <div>
          <p className="creator-card__follower">{creatorFollowers} followers</p>
          <p className="creator-card__follower--sm ">
            followers
            <br />
            {creatorFollowers}
          </p>
        </div>
      </div>
      <div className="creator-card__Button ">
        {currentUserId !== props.creatorId ? (
          <CommunityActionButtons
            isFollowedByCurrentUser={props.isFollowedByCurrentUser}
            isPlaceholder={props.isPlaceholder}
            uid={currentUserId}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CreatorCard;
