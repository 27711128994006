import { Button } from "@/components";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import i18next from "i18next";
import { FC, useState } from "react";
import { ReactComponent as BinIcon } from "../../../../../assets/bin.svg";
import { ReactComponent as PlusIcon } from "../../../../../../../assets/actions/plus.svg";
import { AiOutlineMinus as RemoveIcon } from "@react-icons/all-files/ai/AiOutlineMinus";
import Cropper from "react-easy-crop";
import { checkPermissions } from "@/features/profile/helperFunctions/checkPermission";

export const EditWithInitImage: FC<{
  onCropComplete: any;
  crop: any;
  zoom: any;
  profileCroppedImage: any;
  setCrop: any;
  setZoom: any;
  setCurrentContentOfModal: any;
  handleInputChange: any;
  isMobile: any;
  onClose: any;
  loading: boolean;
  submitCroppedImage: any;
  setOpenEditModal: any;
}> = ({
  onCropComplete,
  zoom,
  crop,
  profileCroppedImage,
  setCrop,
  setZoom,
  setCurrentContentOfModal,
  handleInputChange,
  isMobile,
  onClose,
  loading,
  submitCroppedImage,
  setOpenEditModal,
}) => {
  return (
    <div className="edit-profile__includes-initial">
      <div>
        <h3>{i18next.t("profile.edit.title")}</h3>
        {profileCroppedImage && (
          <div className="edit-profile__includes-initial--wrapper profile__cropper-wrapper">
            <Cropper
              image={profileCroppedImage!}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />

            <div className="profile__cropper-controls">
              <Button
                variant=""
                onClickAction={() => setCurrentContentOfModal("Confirm delete")}
              >
                <BinIcon />
              </Button>

              <Button
                variant=""
                onClickAction={() => {
                  setZoom((prev: any) => {
                    if (prev - 0.1 <= 1) {
                      return prev;
                    }
                    return prev - 0.1;
                  });
                }}
                disabled={zoom <= 1}
              >
                <RemoveIcon />
              </Button>

              <Button
                variant=""
                onClickAction={() => {
                  setZoom((prev: any) => {
                    if (prev + 0.1 >= 3) {
                      return prev;
                    }
                    return prev + 0.1;
                  });
                }}
                disabled={zoom >= 3}
              >
                <PlusIcon />
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="edit-profile__includes-initial--buttons">
        <label htmlFor="modal-profile-image-input">
          <p className="btn btn-secondary">{i18next.t("buttons.upload")}</p>
          <input
            accept="image/*"
            type="file"
            className="opacity-0 d-none"
            id="modal-profile-image-input"
            onChange={handleInputChange}
          />
        </label>
        {loading && (
          <Button variant="primary" type="button">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          </Button>
        )}
        {!loading && (
          <Button
            variant="primary"
            type="button"
            onClickAction={async () => {
              await submitCroppedImage();
              setOpenEditModal(false);
            }}
            disabled={loading}
          >
            {i18next.t("buttons.save")}
          </Button>
        )}
      </div>
    </div>
  );
};
