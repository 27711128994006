import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Button, Row } from "react-bootstrap";
import SharedChallenge from "./SharedChallenge";
import UserPost from "./UserPost";
import SharedTeam from "./SharedTeam";
import SharedGather from "./SharedGather";
import SharedNews from "./SharedNews";
import BaseLogo from "@/components/BaseLogo/BaseLogo";
import { highlightAtMentions } from "../../../profile/helperFunctions/highlightAtMention";
import { SinglePostProps } from "../../types/singlePostsProps";
import { SinglePostContentType } from "../SinglePost/SinglePostContentType";

const SharedPost: React.FunctionComponent<{
  type: string;
  postPhoto: any;
  postVideo: any;
  isFromFeed?: boolean;
  data?: SinglePostProps;
}> = ({ type, postPhoto, postVideo, isFromFeed, data }) => {
  const [showMorePost, setShowMorePost] = useState(false);
  const numberOfLineBreaks = (data?.postText?.match(/\n/g) || []).length;
  const [existingUsers, setExistingUsers] = useState<
    Array<{ userName: string; slug: string; uid: string }>
  >([]);

  useEffect(() => {
    (async () => {
      // const profiles = await getAllAlgoliaProfiles();
      // const users = profiles.map((profileData) => ({
      //   userName: `@${profileData.userName}`,
      //   slug: profileData.slug,
      //   uid: profileData.uid,
      // }));
      // setExistingUsers(users);
    })();
  }, []);

  if (data && data.additionalData) {
    try {
      return (
        <div className="shared-post">
          <div className="vertical-line" />
          <div />
          <div className="shared-post__header share-post--content mx-auto">
            <div className="shared-post__content">
              <p
                className={` ${
                  showMorePost ? "" : "shared-post__content--break"
                }`}
              >
                {highlightAtMentions(data.postText, existingUsers)}
              </p>
              {data.postText &&
                (data.postText.length > 100 || numberOfLineBreaks > 3) && (
                  <Button
                    variant="link"
                    onClick={() => setShowMorePost((prev) => !prev)}
                  >
                    {showMorePost ? "Show less" : "Show more"}
                  </Button>
                )}
            </div>
            <div className="shared-post__author">
              <div className="post__avatar">
                <div className="avatar">
                  <div className="avatar__content">
                    <BaseLogo
                      name={data.additionalData?.authorUsername as string}
                      logoSrc={
                        data.additionalData?.authorLogo ||
                        data.additionalData.authorUsername
                      }
                      isPlaceHolder={undefined}
                    />
                  </div>
                </div>
              </div>
              <div className="shared-post__author--link">
                <Link to={`/profile/${data.additionalData?.authorSlug}`}>
                  <span>{data.additionalData?.authorUsername}</span>
                </Link>
                <p>
                  <time dateTime={data.whenWasPosted?.stringDate}>
                    {data.whenWasPosted?.howLongAgo}
                  </time>
                  {data.wasEdited ? <span>&#9679; Edited</span> : ""}
                </p>
              </div>
            </div>

            <Row>
              {/* <SinglePostContentType
                type={type}
                postPhoto={postPhoto}
                postVideo={postVideo}
                data={data}
              /> */}

              {/* {type === "userPost" && (
                <UserPost
                  postPhoto={postPhoto || ""}
                  postVideo={postVideo || ""}
                />
              )}
              {type === "sharedPost" && (
                <UserPost
                  postPhoto={data.additionalData?.postPhoto || ""}
                  postVideo={data.additionalData?.postVideo || ""}
                />
              )}

              {type === "sharedChallenge" && (
                <>
                  <Col xs={12}>
                    <SharedChallenge data={data} isFromFeed />
                  </Col>
                </>
              )}
              {type === "sharedGather" && (
                <Col xs={12}>
                  <SharedGather data={data} isFromFeed />
                </Col>
              )}
              {type === "sharedNews" && (
                <Col xs={12}>
                  <SharedNews data={data} isFromFeed />
                </Col>
              )}
              {type === "sharedTeam" && (
                <>
                  <Col xs={12}>
                    <SharedTeam data={data} isFromFeed />
                  </Col>
                </>
              )} */}
            </Row>
          </div>
        </div>
      );
    } catch (error) {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export default memo(SharedPost);
